/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import styled from "@emotion/styled";
import moment from "moment/moment";
import url from "../../config/axios";
import Success from "../../assets/Success1.png";
import Cross from "../../assets/cross.png";
import Failure from "../../assets/failure.png";
import Popup from "reactjs-popup";
import TimePicker from "../../atoms/DatePicker/TimePicker";

const MyRow = styled.div`
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  border-bottom: 2px solid #f1f5f9;
  padding-bottom: 10px;
`;
const PopUpDiv = styled.div`
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`;
const SelectDaysStyles = {
  display: "flex",
  justifyContent: "space-evenly",
  borderRadius: "0px",
};
// const CustomeInputTime = styled.input`
// background-color: white;
// width:100%!important;
// height:8vh;
// padding:0px;
// border:1px solid #E2E8F0;
// border-radius: 5px;
// ::-webkit-calendar-picker-indicator{
// filter:invert(83%) sepia(64%) saturate(3544%) hue-rotate(308deg) brightness(107%) contrast(93%);
// }
// `
const NewTimePicker = styled.div`
  .MuiFormControl-root {
    background-color: white;
  }
  input {
    padding: 8px 16px;
  }
`;

const CustomeSelect = styled.input`
  width: 100% !important;
  padding: 0px;
  border: 1px solid #e2e8f0;
  border-radius: 5px;
  height: 8vh;
`;
const Container = styled.div`
  .addLine {
    color: #e2e8f0;
  }
  .main-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100% !important;
    border-radius: 10px;
    margin-bottom: 10px;
    /* background-color:rgba(248, 250, 252, 1); */

    .addSlot-Div {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100% !important;

      margin-top: 5px;
      margin-bottom: 5px;
      .dayText {
        padding-top: 10px;
        color: #192746;
        font-size: 15px;
        text-align: left;
        font-weight: 700;
      }
      .text {
        padding-top: 10px;
        color: #747474;
        font-size: 11px;
        text-align: left;
        font-weight: 500;
        align-self: flex-start;
      }
      .remove-button {
        background-color: rgba(248, 250, 252, 1);
        border: none;
        color: #ff6d6d;
        /* margin-bottom:10px; */
      }
      .my-button {
        background-color: rgba(248, 250, 252, 1);
        border: none;
        color: #ffa382;
        margin-bottom: 10px;
      }
      .inputField {
        margin-bottom: 10px;
      }
      .pointsText {
        color: #747474;
      }
    }
  }
`;
const dayButton = {
  background: "white",
  border: "1px solid #E2E8F0",
  borderRadius: "5px",
  width: "16vh",
  height: "7vh",
};

function SpecializePoint() {
  const ref = useRef();
  const notRef = useRef();
  const history = useHistory();

  const notOpenToolTip = () => notRef.current.open();
  const closenotToolTip = () => notRef.current.close();
  const openTooltip = () => ref.current.open();
  const closeTooltip = () => {
    ref.current.close();
  };
  const [formValidationErrors, setFormvalidationErrors] = useState({});

  const [slots, setSlots] = useState({
    from: "",
    to: "",
    points: 0,
  });
  useEffect(() => {
    getLoyalty();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // Get loyalty api
  const [days, setDays] = useState({
    MON: [],
    TUES: [],
    WED: [],
    THURS: [],
    FRI: [],
    SAT: [],
    SUN: [],
  });
  const getLoyalty = async () => {
    try {
      const getLoyaltyPoint = await url.get("/v1/loyalty", {
        slots: days,
      });
      let data = getLoyaltyPoint.data.data.loyalty.slots;
      setDays({ ...data });
    } catch (e) {
      console.log(e);
    }
  };
  // This useState is to set new selected data for each day
  const [newState, setNewState] = useState({
    MON: {
      selected: false,
      slot: slots,
      name: "Monday",
    },
    TUES: {
      selected: false,
      slot: slots,
      name: "Tuesday",
    },
    WED: {
      selected: false,
      slot: slots,
      name: "Wednesday",
    },
    THURS: {
      selected: false,
      slot: slots,
      name: "Thursday",
    },
    FRI: {
      selected: false,
      slot: slots,
      name: "Friday",
    },
    SAT: {
      selected: false,
      slot: slots,
      name: "Saturday",
    },
    SUN: {
      selected: false,
      slot: slots,
      name: "Sunday",
    },
  });

  // For changing the slots object
  const manageSlots = (value) => {
    let daySlots = { ...slots };
    console.log(daySlots[value.target.name]);
    value.target.name === "points"
      ? (daySlots[value.target.name] = value.target.value)
      : (daySlots[value.target.name] = moment(
          value.target.value,
          "HH:mm"
        ).format("h:mm a"));
    console.log(daySlots);
    setSlots(daySlots);
  };
  const manageSlots2 = (value, name) => {
    const newTime = moment(value, "ddd MMM DD yyyy HH:mm:ss").format("h:mm a");
    console.log(value, name, newTime);
    let daySlots = { ...slots };
    name === "points" ? (daySlots[name] = newTime) : (daySlots[name] = newTime);
    // Set the focus on the input field after the state has been updated
    setSlots(daySlots);
  };
  const currentDate = new Date();
  const timestamp = currentDate.getTime();
  const time = moment(timestamp).format("hh:mm:ss");
  const SetSelected = (data, key) => {
    switch (key) {
      case 0:
        setNewState({
          ...newState,
          MON: {
            selected: !data.MON.selected,
            slot: slots,
            name: "Monday",
          },
        });
        break;
      case 1:
        setNewState({
          ...newState,
          TUES: {
            selected: !data.TUES.selected,
            slot: slots,
            name: "Tuesday",
          },
        });
        break;

      case 2:
        setNewState({
          ...newState,
          WED: {
            selected: !data.WED.selected,
            slot: slots,
            name: "Wednesday",
          },
        });
        break;
      case 3:
        setNewState({
          ...newState,
          THURS: {
            selected: !data.THURS.selected,
            slot: slots,
            name: "Thursday",
          },
        });
        break;
      case 4:
        setNewState({
          ...newState,
          FRI: {
            selected: !data.FRI.selected,
            slot: slots,
            name: "Friday",
          },
        });
        break;
      case 5:
        setNewState({
          ...newState,
          SAT: {
            selected: !data.SAT.selected,
            slot: slots,
            name: "Saturday",
          },
        });
        break;
      case 6:
        setNewState({
          ...newState,
          SUN: {
            selected: !data.SUN.selected,
            slot: slots,
            name: "Sunday",
          },
        });
        break;
      default:
        break;
    }
  };
  // The function to basically add or update the loyalty
  const addUpdateLoyalty = async () => {
    try {
      let betterSlot = {};
      Object.keys(days).map((item) => {
        const values = days[item];
        if (newState[item]?.selected) {
          // let SelectedSLot = Object.keys(newState[item]?.slot);
          // SelectedSLot = slots
          values.push(slots);
        }
        betterSlot[item] = values;
      });
      const addUpdate = await url.put("/v1/loyalty/update-slots", {
        slots: betterSlot,
      });
      let data = addUpdate.data.data.loyalty.slots;
      setDays({ ...data });
      openTooltip();
      setFormvalidationErrors({});
      getLoyalty();
    } catch (e) {
      notOpenToolTip();
      if (e?.response?.status === 422) {
        let data = { ...formValidationErrors };
        // Object.values
        if ("body.slots.MON" in e?.response?.data?.data) {
          data = {
            ...data,
            mon: "Invalid or conflicting time Slots in Monday",
          };
        } else {
          const { mon, ...others } = data;
          data = { ...others };
        }
        if ("body.slots.TUES" in e?.response?.data?.data) {
          data = {
            ...data,
            tues: "Invalid or conflicting time Slots in Tuesday",
          };
        } else {
          const { tues, ...others } = data;
          data = { ...others };
        }
        if ("body.slots.WED" in e?.response?.data?.data) {
          data = {
            ...data,
            wed: "Invalid or conflicting time Slots in Wednesday",
          };
        } else {
          const { wed, ...others } = data;
          data = { ...others };
        }
        if ("body.slots.THURS" in e?.response?.data?.data) {
          data = {
            ...data,
            thurs: "Invalid or conflicting time Slots in Thurday",
          };
        } else {
          const { thurs, ...others } = data;
          data = { ...others };
        }
        if ("body.slots.FRI" in e?.response?.data?.data) {
          data = { ...data, fri: "Invalid or conflictin time Slots in Friday" };
        } else {
          const { fri, ...others } = data;
          data = { ...others };
        }
        if ("body.slots.SAT" in e?.response?.data?.data) {
          data = {
            ...data,
            sat: "Invalid or conflictin time Slots in Saturday",
          };
        } else {
          const { sat, ...others } = data;
          data = { ...others };
        }
        if ("body.slots.SUN" in e?.response?.data?.data) {
          data = { ...data, sun: "Invalid or conflictin time Slots in Sunday" };
        } else {
          const { sun, ...others } = data;
          data = { ...others };
        }
        setFormvalidationErrors({ ...data });
        return data;
      }
      console.log(e?.response?.data?.data, "Error here");
    }
  };
  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="card">
          <MyRow className="row">
            <div className="col-md-12">
              <div className="row">
                <div
                  style={{
                    height: "25px",
                    marginLeft: "-32px",
                    backgroundColor: "#FFA382",
                    marginTop: "2px",
                  }}
                >
                  <span style={{ color: "#FFA382" }}>.</span>
                </div>
                <div className="col-md-4">
                  <div style={{ color: "#FFA382", fontSize: "20px" }}>
                    Specialized Day & Time
                  </div>
                </div>
                <div className="col-md-6 filters-bar">
                  <div className="dropdown"></div>
                  <div className="dropdown">
                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuLink"
                    >
                      <a className="dropdown-item" href="/">
                        Action
                      </a>
                      <a className="dropdown-item" href="/">
                        Another action
                      </a>
                      <a className="dropdown-item" href="/">
                        Something else here
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-md-1">
                  <button
                    className="btn btn-primary border-0 "
                    style={{
                      backgroundColor: "#FFA382",
                      marginBottom: "5px",
                      marginLeft: "90px",
                    }}
                    onClick={() => {
                      history.push("/loyalty-points");
                    }}
                  >
                    <i className="fas fa-arrow-left"></i>
                    Back
                  </button>
                </div>
              </div>
            </div>
          </MyRow>
          {/* Div to select days */}
          <div className="d-flex" style={SelectDaysStyles}>
            {Object.values(newState).map((item, key) => {
              return (
                <button
                  onClick={() => {
                    let data = { ...newState };
                    SetSelected(data, key);
                  }}
                  style={
                    !item.selected
                      ? dayButton
                      : {
                          ...dayButton,
                          background: "#FFA382",
                          color: "white",
                        }
                  }
                >
                  {item.name}
                </button>
              );
            })}
          </div>
          <div
            className="row"
            style={{
              borderTopRightRadius: "0px",
              borderTopLeftRadius: "0px",
            }}
          >
            <div className="col-lg-6 col-md-6">
              <NewTimePicker>
                <TimePicker
                  label="Give loyalty points from"
                  className="inputField"
                  placeholder="Time slot"
                  name="from"
                  type="time"
                  value={moment(slots.from, "h:mm a").format(
                    "ddd MMM DD yyyy HH:mm:ss"
                  )}
                  onChange={(value) => {
                    manageSlots2(value, "from");
                  }}
                />
              </NewTimePicker>
            </div>
            <div className="col-lg-6 col-md-6">
              <NewTimePicker>
                <TimePicker
                  label="Give loyalty points to"
                  className="inputField"
                  placeholder="Time slot"
                  name="to"
                  type="time"
                  value={moment(slots.to, "h:mm a").format(
                    "ddd MMM DD yyyy HH:mm:ss"
                  )}
                  onChange={(value) => {
                    manageSlots2(value, "to");
                  }}
                />
              </NewTimePicker>
            </div>
            <div className="col-lg-12 col-md-12">
              <Container>
                <div className="main-container">
                  <div className="addSlot-Div">
                    <span className="text">Loyalty Points To Be Given</span>
                    <CustomeSelect
                      type="number"
                      name="points"
                      onInput={(e) => {
                        e.target.value = Math.abs(e.target.value);
                        if (e.target.value > 100) {
                          e.target.value = 100;
                        }
                      }}
                      // value={i.points}
                      onChange={(e) => {
                        manageSlots(e);
                      }}
                    />
                  </div>
                </div>
              </Container>
            </div>
          </div>
        </div>
      </div>
      <div
        className="d-flex col-md-12"
        style={{
          justifyContent: "flex-end",
          marginTop: "5px",
          marginLeft: "11px",
        }}
      >
        <div style={{ borderRadius: "5px", padding: "6px" }}>
          <button
            className="btn btn-primary border-0"
            style={{ backgroundColor: "#FFA382" }}
            onClick={() => {
              addUpdateLoyalty();
            }}
          >
            Update
          </button>
        </div>
      </div>
      <Popup ref={ref} id="latestPopup" className="myPopup" position="center">
        <div style={{ height: " 30vh " }}>
          <div style={{ textAlign: "right" }}>
            <img
              onClick={() => {
                closeTooltip();
              }}
              src={Cross}
            />
          </div>
          <PopUpDiv>
            <div>
              <img height="60" src={Success} />
            </div>
            <div className="mt-3 mb-3">
              <span
                style={{
                  marginTop: "20px",
                  color: "#192746",
                  fontSize: "18px",
                }}
              >
                Updated Successfully
              </span>
              <p style={{ color: "#747474" }}>Loyalty points updated </p>
            </div>
            <button
              type="button"
              onClick={() => {
                closeTooltip();
              }}
              className="btn btn-primary border-0"
              style={{
                backgroundColor: "#FFA382",
                marginTop: "0",
                width: "20vh",
                alignSelf: "center",
              }}
            >
              Close
            </button>
          </PopUpDiv>
        </div>
      </Popup>
      <Popup
        ref={notRef}
        id="latestPopup"
        className="myPopup"
        position="center"
      >
        <div style={{ height: " 30vh " }}>
          <div style={{ textAlign: "right" }}>
            <img
              onClick={() => {
                closenotToolTip();
              }}
              src={Cross}
            />
          </div>
          <PopUpDiv>
            <div>
              <img src={Failure} />
            </div>
            <div className="mt-3 mb-3">
              <span
                style={{
                  marginTop: "20px",
                  color: "#192746",
                  fontSize: "18px",
                }}
              >
                Something Went Wrong
              </span>
              <p style={{ color: "#747474" }}>
                Please enter the correct details to proceed
              </p>
            </div>
            <button
              type="button"
              onClick={() => {
                closenotToolTip();
              }}
              className="btn btn-primary border-0"
              style={{
                backgroundColor: "#FFA382",
                marginTop: "0",
                width: "20vh",
                alignSelf: "center",
              }}
            >
              ok
            </button>
          </PopUpDiv>
        </div>
      </Popup>
    </div>
  );
}
export default SpecializePoint;
