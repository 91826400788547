import React, { useState, useEffect, useRef } from "react";
import url from "../../config/axios";
import moment from "moment";
import { useHistory, useLocation } from "react-router-dom";
import Popup from "reactjs-popup";
import Pagination from "@mui/material/Pagination";
import { makeStyles } from "@material-ui/core/styles";
import { serializeQueryStringsFromObject } from "../../helpers/serializeToQueryStrings";

const useStyles = makeStyles({
  root: {
    "& .Mui-selected": {
      backgroundColor: "#FFA382 !important",
    },
    "& .MuiPaginationItem-root": {
      borderRadius: "5px !important",
    },
  },
});
export default function Order() {
  const classes = useStyles();
  const [orders, setOrders] = useState([]);
  const [outlets, setOutlets] = useState([]);
  const history = useHistory();
  const initialPaginationData = {
    page: 1,
    limit: 100,
    latestFirst: 1,
    search: "",
  };
  const [loading, setLoading] = useState(true);
  const [queryData, setQueryData] = useState({
    ...initialPaginationData,
  });
  const [prevSearchTerm, setPrevSearchTerm] = useState("");
  const { search } = useLocation();
  const [searchTerm, setSearchTerm] = useState("");
  const [parsed, setParsed] = useState(false);
  const [paginationData, setPaginationData] = useState({
    currentPage: 1,
    perPage: 30,
    totalPages: 0,
    total: 0,
  });
  const [unfiltered, setUnfiltered] = useState([]);
  const [outletID, setOutletID] = useState("");

  const notFoundRef = useRef();
  const openNotFoundPopUp = () => notFoundRef.current.open();
  const closeNotFoundPopUp = () => {
    notFoundRef.current.close();
    // setSearchTerm("");
  };
  const admin = JSON.parse(localStorage.getItem("Admin"));
  console.log(admin);
  useEffect(() => {
    if (admin[0].isSubOutlet) {
      getAllOrders();
    } else {
      GetAllOutlets();
    }
  }, []);
  // This function is for getting data for solechain
  const getAllOrders = () => {
    try {
      let queryParams = { ...queryData };
      //sanity
      Object.keys(queryParams).forEach((key) => {
        if (!queryParams[key]) {
          delete queryParams[key];
        }
      });
      url
        .get(`v1/orders-paginated/?outletChainID=${admin[0].outletChainID}`, {
          params: {
            ...queryParams,
          },
        })
        .then(async (res) => {
          console.log(res.data, "res:");
          if (res.data.Message === "Success") {
            const sortedArray = res.data.data.orders.sort((a, b) =>
              moment(b.createdAt).diff(a.createdAt)
            );
            setUnfiltered(sortedArray);
            setOrders(sortedArray);
            setPaginationData({
              ...paginationData,
              ...res?.data?.data?.paginationData,
            });
            setLoading(false);
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
  // This funciton is getting outlets data for multi chain
  const GetAllOutlets = () => {
    const adminID = JSON.parse(localStorage.getItem("Admin"));
    url
      .get(`/oc/?consumerId=${adminID[0].consumerId}`)
      .then(async (res) => {
        console.log("res", res);
        if (res.data.Message === "Success") {
          if (res.data.data.length === 0) {
            openNotFoundPopUp();
          } else {
            setOutlets(res.data.data);
          }
        } else {
          openNotFoundPopUp();
        }
      })
      .catch((e) => console.log(e));
  };
  // In this function we get orders by outlet
  const GetOrdersByOutlet = (outletId) => {
    setOutletID(outletId);
    if (outletId !== "select") {
      let queryParams = { ...queryData };
      //sanity
      Object.keys(queryParams).forEach((key) => {
        if (!queryParams[key]) {
          delete queryParams[key];
        }
      });
      url
        .get(`v1/orders-paginated?outletChainID=${outletId}`, {
          params: {
            ...queryParams,
          },
        })
        .then(async (res) => {
          console.log("Order Outlet:", res);
          if (res.data.Message === "Success") {
            if (res.data.data.length === 0) {
              openNotFoundPopUp();
            } else {
              const sortedArray = res.data.data.orders.sort((a, b) =>
                moment(b.createdAt).diff(a.createdAt)
              );

              setUnfiltered(sortedArray);
              setOrders(sortedArray);
              setPaginationData({
                ...paginationData,
                ...res?.data?.data?.paginationData,
              });
              setLoading(false);
            }
          } else {
            openNotFoundPopUp();
          }
        })
        .catch((e) => console.log(e));
    }
  };
  useEffect(
    () => {
      const query = new URLSearchParams(search);

      let page =
        Number.isNaN(query.get("page")) || !query.get("page")
          ? 1
          : +query.get("page");
      let limit =
        Number.isNaN(query.get("limit")) || !query.get("limit")
          ? 100
          : +query.get("limit");
      let latestFirst =
        Number.isNaN(query.get("latestFirst")) || !query.get("latestFirst")
          ? 1
          : +query.get("latestFirst");
      let searchParams = query.get("search");
      let queryParams = {
        ...queryData,
        page,
        limit,
        latestFirst,
        search: searchParams,
      };
      setSearchTerm(searchParams);
      setQueryData({ ...queryParams });
      setParsed(true);
    },
    //eslint-disable-next-line
    []
  );
  //clientSide search
  useEffect(
    () => {
      if (searchTerm?.length !== 0) {
        let filtered = unfiltered.filter((element) =>
          element.orderID
            ?.toLowerCase()
            .includes(searchTerm?.trim().toLowerCase())
        );
        setOrders(filtered);
        // if (filtered.length === 0) {
        //   openNotFoundPopUp();
        // }
      } else {
        setOrders(unfiltered);
      }
    },
    // eslint-disable-next-line
    [searchTerm]
  );
  //call to fetch data after parsing
  useEffect(
    () => {
      if (parsed) {
        getAllOrders({ ...queryData });
      }
    },
    //eslint-disable-next-line
    [parsed, queryData]
  );

  const handleChange = (propName, propValue, flush = false) => {
    let query = {};
    if (flush) {
      query = {
        ...initialPaginationData,
        [propName]: propValue,
      };
    } else {
      query = { ...queryData, [propName]: propValue };
    }
    const queryString = serializeQueryStringsFromObject(query);
    history.push(`/order${queryString}`);
    setQueryData({ ...query });
  };
  const performSearchOnEnter = () => {
    setPrevSearchTerm(searchTerm);
    handleChange("search", searchTerm, true);
  };
  const handleSearchTermChange = (value = "") => {
    setSearchTerm((value + "").trim());

    if (
      (value + "").trim().length === 0 &&
      prevSearchTerm.trim().length !== 0
    ) {
      handleChange("search", "", true);
    }
  };

  function backgroundColor(value) {
    let result;
    switch (value) {
      case "Pending":
        result = "rgb(251, 255, 194)";
        break;
      case "New":
        result = "rgba(108, 149, 255, 0.1)";
        break;
      case "Cancelled":
        result = "rgba(255, 109, 109, 0.08)";
        break;
      case "Delivered":
        result = "rgba(12, 214, 0, 0.1)";
        break;
      case "Completed":
        result = "rgba(12, 214, 0, 0.1)";
        break;
      case "Dispatched":
        result = "rgba(12, 214, 0, 0.1)";
        break;
      default:
        result = "";
        break;
    }
    return result;
  }
  function checkColor(value) {
    let result;
    switch (value) {
      case "Pending":
        result = "#FFC700";
        break;
      case "New":
        result = "blue";
        break;
      case "Cancelled":
        result = "brown";
        break;
      case "Completed":
        result = "#0CD600";
        break;
      case "Delivered":
        result = "#0CD600";
        break;
      case "Dispatched":
        result = "#0CD600";
        break;
      default:
        result = "";
        break;
    }
    return result;
  }
  return (
    <div className="row">
      <div className="col-md-12">
        <div className="card card-pro">
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div
                  style={{
                    height: "25px",
                    marginLeft: "-32px",
                    backgroundColor: "#FFA382",
                    marginTop: "5px",
                  }}
                >
                  <span style={{ color: "#FFA382" }}>.</span>
                </div>
                <div className="col-md-3 d-flex">
                  <div
                    style={{
                      color: "#FFA382",
                      fontSize: "20px",
                      fontWeight: "600",
                    }}
                  >
                    Orders
                  </div>
                </div>
                <div className="col-md-2 filters-bar">
                  <div className="dropdown"></div>
                  <div className="dropdown">
                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuLink"
                    >
                      <a className="dropdown-item" href="/">
                        Action
                      </a>
                      <a className="dropdown-item" href="/">
                        Another action
                      </a>
                      <a className="dropdown-item" href="/">
                        Something else here
                      </a>
                    </div>
                  </div>
                </div>
                {!admin[0].isSubOutlet && (
                  <div className="col-md-4">
                    <div className="input-group w-100">
                      <select
                        className="newInputSelect w-75"
                        onChange={(e) => GetOrdersByOutlet(e.target.value)}
                        id="exampleFormControlSelect1"
                      >
                        <option value="select">Please Select Outlet</option>
                        {outlets.map((outlet, i) => {
                          return (
                            <option value={outlet.outletChainID} key={i}>
                              {outlet.outletName}
                            </option>
                          );
                        })}
                      </select>
                      {outletID === "" ? (
                        <p className="pl-0" style={{ color: "red" }}>
                          Please select outlet
                        </p>
                      ) : null}
                    </div>
                  </div>
                )}
                <div className="col-md-3">
                  <div className="input-group w-100">
                    <input
                      type="text"
                      className="newInput w-75"
                      placeholder="Search order"
                      aria-label="Input group example"
                      aria-describedby="btnGroupAddon"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      // onKeyPress={(e) => {
                      //     if (e.key === "Enter") {
                      //         performSearchOnEnter();
                      //     }
                      // }}
                    />
                    <div className="input-group-prepend">
                      <div
                        className="input-group-text newSearchIcon"
                        id="btnGroupAddon"
                      >
                        <i className="fas fa-search"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <table className="table invtable table-striped-cust">
                <thead>
                  <tr>
                    <th scope="col">Order ID</th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th scope="col">Customer</th>
                    <th scope="col">Price</th>
                    <th scope="col">Purchased On</th>
                    <th scope="col" style={{ textAlign: "center" }}>
                      Status
                    </th>
                    <th scope="col" style={{ textAlign: "center" }}>
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {orders.map((order, i) => {
                    console.log(order.status, "coupon:");
                    return (
                      <tr key={i}>
                        <td colSpan="4">{order.orderID}</td>
                        <td>
                          {order.customer.name}
                          <br />
                          <span>{order.customer.email}</span>
                        </td>
                        <td>$ {order.finalPrice}</td>
                        <td>
                          {moment(order.createdAt).format(
                            "MMM. Do YYYY, h:mm A"
                          )}
                        </td>
                        {/* <td className="status">
                            <span>{deal.name}</span>
                          </td> */}
                        <td className={checkColor(order.status)}>
                          {/* {order.status} */}
                          <div
                            className="newClass"
                            id="coupons"
                            style={{
                              marginLeft: "-10px",
                              backgroundColor: `${backgroundColor(
                                order.status
                              )}`,
                            }}
                          >
                            <p
                              style={{
                                fontWeight: "400",
                                textAlign: "center",
                                fontSize: "13px",
                                paddingTop: "4px",
                                color: checkColor(order.status),
                              }}
                            >
                              {order.status}
                            </p>
                          </div>
                        </td>
                        <td
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <button
                            className="btn  newView"
                            onClick={() => {
                              localStorage.setItem(
                                "singleOrder",
                                JSON.stringify(order)
                              );
                              localStorage.setItem(
                                "orderID",
                                JSON.stringify(order._id)
                              );
                              localStorage.setItem("OrderOutletID", outletID);
                              history.push(`/order-detail`);
                            }}
                          >
                            <i className="fas fa-eye"></i>
                            <u>View Details </u>
                          </button>
                          {/* onClick={() => moveToEdit(product)} */}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {loading ? null : (
          <div
            className="d-flex col-md-12"
            style={{
              justifyContent: "flex-end",
              marginTop: "5px",
              marginLeft: "13px",
            }}
          >
            <div
              style={{
                backgroundColor: "white",
                borderRadius: "5px",
                padding: "6px",
              }}
            >
              <Pagination
                shape="square"
                className={classes.root}
                onChange={(e, value) => {
                  handleChange("page", value);
                }}
                page={paginationData.currentPage}
                count={paginationData.totalPages ?? 0}
              />
            </div>
          </div>
        )}
      </div>
      <Popup ref={notFoundRef} position="center">
        <div style={{ textAlign: "center" }}>
          <i
            style={{
              color: "red",
              fontSize: "70px",
            }}
            // style={{
            //   color: "green",
            //   fontSize: "60px",
            //   border: "10px solid green",
            //   borderRadius: "50px",
            //   padding: "10px",
            // }}
            class="fa fa-ban"
          ></i>
          <h2 className="popup-heading" style={{ marginTop: "20px" }}>
            Failed
          </h2>
          <p>Orders not found</p>
          <button
            className="btn btn-cannaby"
            style={{ marginTop: "0" }}
            onClick={closeNotFoundPopUp}
          >
            OK
          </button>
        </div>
      </Popup>
    </div>
  );
}
