import { useEffect, Fragment, useState } from "react";
import "./ResetPassword.styles.css";
import { useSelector } from "react-redux";
import { selectifMultiChainAdmin } from "../../redux/slices/authSlice";
// import Menu from "@mui/material/Menu";
// import MenuItem from "@mui/material/MenuItem";
// import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import { Alert, CircularProgress } from "@mui/material";
import url from "../../config/axios";
import ForgotPassword from "../LoginPage/ForgotPassword";
export const ResetPasswordComponent = ({ onCancel }) => {
  const [formData, setFormData] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [passwordVisibiltyMap, setPasswordVisibilityMap] = useState({
    currentPassword: true,
    newPassword: true,
    confirmPassword: true,
  });
  const admin = JSON.parse(localStorage.getItem("Admin")) || null;
  console.log(admin[0], "This is the true admin");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const changeValue = (name, value) =>
    setFormData({ ...formData, [name]: value });
  const changePasswordVisibiltyMap = (name) =>
    setPasswordVisibilityMap({
      ...passwordVisibiltyMap,
      [name]: !passwordVisibiltyMap[name],
    });
  const [inProgress, setInProgress] = useState(false);
  const isMultiChainAdmin = useSelector(selectifMultiChainAdmin);
  const [formValidationErrors, setFormvalidationErrors] = useState({});
  const [triedBefore, setTriedBefore] = useState(false);
  const [forgotPassword, setForgotPassword] = useState(false);
  useEffect(
    () => {
      if (triedBefore) {
        validateForm();
      }
    },
    //eslint-disable-next-line
    [formData, triedBefore]
  );
  const updatePassword = async (changePasswordOfMultiChainAdmin) => {
    setTriedBefore(true);
    setErrorMessage("");
    setSuccessMessage("");
    let result = validateForm();
    if (Object.keys(result).length === 0) {
      //TO DO
      setInProgress(true);
      let path = `/v1/security/change-sole-chain-password`;
      if (!admin[0].isSubOutlet) {
        path = !changePasswordOfMultiChainAdmin
          ? `/v1/security/change-sole-chain-password`
          : `/v1/security/change-multi-chain-password`;
      }
      url
        .put(path, {
          oldPassword: formData.currentPassword,
          newPassword: formData.newPassword,
        })
        .then(() => {
          setTriedBefore(false);
          setInProgress(false);
          setSuccessMessage("Successfully changed password.");
        })
        .catch((e) => {
          setInProgress(false);
          setErrorMessage(
            e?.response?.data?.data?.message ?? "Something went wrong"
          );
        });
    }
  };
  const validateForm = () => {
    let data = { ...formValidationErrors };
    const { currentPassword, newPassword, confirmPassword } = formData;
    //currentPassword
    if (currentPassword.trim().length === 0) {
      data = { ...data, currentPassword: "Current Password is required" };
    } else {
      const { currentPassword, ...others } = data;
      data = { ...others };
    }

    //newPassword
    if (newPassword.trim().length === 0) {
      data = { ...data, newPassword: "New Password is required" };
    } else if (newPassword.trim().length < 8) {
      data = { ...data, newPassword: "Must be of at least 8 characters long" };
    } else {
      let value = formData.newPassword;
      // eslint-disable-next-line
      let specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
      let containsSpecialCharacter = specialChars.test(value);
      let containsNumber = /[0-9]/.test(value);
      let containsUppercase = /[A-Z]/.test(value);
      let valid =
        containsNumber && containsUppercase && containsSpecialCharacter;
      if (valid) {
        const { newPassword, ...others } = data;
        data = { ...others };
      } else {
        data = {
          ...data,
          newPassword:
            "Must contain at least 1 number, 1 uppercase letter and one 1 special character",
        };
      }
    }

    //confirmPassword
    if (
      confirmPassword.trim().length === 0 &&
      newPassword.trim().length !== 0
    ) {
      data = { ...data, confirmPassword: "Confirm Password is required" };
    } else if (
      confirmPassword !== newPassword &&
      newPassword.trim().length !== 0
    ) {
      data = { ...data, confirmPassword: "Passwords don't match" };
    } else {
      const { confirmPassword, ...others } = data;
      data = { ...others };
    }
    setFormvalidationErrors({ ...data });
    return data;
  };
  return (
    <div className="newSpace" style={{ borderRadius: "5px" }}>
      {!forgotPassword ? (
        <>
          <div className="d-flex col-md-12 justify-content-between">
            <div
              className="d-flex flex-column justify-content-center"
              style={{
                color: "#FFA382",
              }}
            >
              {" "}
              Reset Password
            </div>
          </div>
          <span
            style={{
              width: "100%",
              height: "2px",
              backgroundColor: "lightgray",
            }}
          ></span>

          <div className="reset-password-group">
            <label className="labelStyle">Current Password</label>
            <div className="reset-password-field">
              <div className="reset-password-icon">
                <i
                  className={
                    passwordVisibiltyMap["currentPassword"]
                      ? "fa fa-eye"
                      : "fa fa-eye-slash"
                  }
                  style={{
                    color: "#bababa",
                    cursor: "pointer",
                  }}
                  onClick={() => changePasswordVisibiltyMap("currentPassword")}
                ></i>
              </div>
              <input
                className="reset-password-input"
                name="currentPassword"
                value={formData.currentPassword}
                // style={{
                //   fontWeight: "bold",
                //   color: "gray",
                // }}
                placeholder="Current Password"
                onChange={(e) => changeValue(e.target.name, e.target.value)}
                type={
                  passwordVisibiltyMap["currentPassword"] ? "password" : "text"
                }
              ></input>
            </div>
            <span
              onClick={() => setForgotPassword(true)}
              style={{
                fontWeight: "bold",
                textAlign: "right",
                textDecoration: "underline",
                color: "#FFA382",
                marginTop: "0.7em",
                cursor: "pointer",
              }}
            >
              Forgot Password?
            </span>
            <span
              style={{
                marginLeft: "0.5em",
                color: "red",
                marginTop: "0.3em",
              }}
            >
              {formValidationErrors?.currentPassword ?? ""}
            </span>
          </div>

          <div className="reset-password-group">
            <label className="labelStyle">New Password</label>
            <div className="reset-password-field">
              <div className="reset-password-icon">
                <i
                  className={
                    passwordVisibiltyMap["newPassword"]
                      ? "fa fa-eye"
                      : "fa fa-eye-slash"
                  }
                  style={{
                    color: "#bababa",
                    cursor: "pointer",
                  }}
                  onClick={() => changePasswordVisibiltyMap("newPassword")}
                ></i>
              </div>
              <input
                className="reset-password-input"
                name="newPassword"
                value={formData.newPassword}
                // style={{
                //   fontWeight: "bold",
                //   color: "gray",
                // }}
                placeholder="New Password"
                onChange={(e) => changeValue(e.target.name, e.target.value)}
                type={passwordVisibiltyMap["newPassword"] ? "password" : "text"}
              ></input>
            </div>
            <span
              style={{
                marginLeft: "0.5em",
                color: "red",
                marginTop: "0.3em",
              }}
            >
              {formValidationErrors?.newPassword ?? ""}
            </span>
          </div>

          <div className="reset-password-group">
            <label className="labelStyle">Confirm Password</label>
            <div className="reset-password-field">
              <div className="reset-password-icon">
                <i
                  className={
                    passwordVisibiltyMap["confirmPassword"]
                      ? "fa fa-eye"
                      : "fa fa-eye-slash"
                  }
                  style={{
                    color: "#bababa",
                    cursor: "pointer",
                  }}
                  onClick={() => changePasswordVisibiltyMap("confirmPassword")}
                ></i>
              </div>
              <input
                className="reset-password-input"
                name="confirmPassword"
                value={formData.confirmPassword}
                // style={{
                //   fontWeight: "bold",
                //   color: "gray",
                // }}
                placeholder="Confirm Password"
                onChange={(e) => changeValue(e.target.name, e.target.value)}
                type={
                  passwordVisibiltyMap["confirmPassword"] ? "password" : "text"
                }
              ></input>
            </div>
            <span
              style={{
                marginLeft: "0.5em",
                color: "red",
                marginTop: "0.3em",
              }}
            >
              {formValidationErrors?.confirmPassword ?? ""}
            </span>
          </div>
          {!!errorMessage && (
            <div className="reset-password-group">
              <Alert severity="error" onClick={() => setErrorMessage("")}>
                {errorMessage}
              </Alert>
            </div>
          )}
          {!!successMessage && (
            <div className="reset-password-group">
              <Alert severity="success" onClick={() => setSuccessMessage("")}>
                {successMessage}
              </Alert>
            </div>
          )}

          <div className="d-flex justify-content-end col-md-12">
            {isMultiChainAdmin ? (
              <button
                onClick={() => updatePassword(false)}
                disabled={inProgress}
                style={{
                  margin: "0",
                  padding: "0.5em 1.5em",
                  borderRadius: "5px",
                  border: "none",
                  cursor: "pointer",
                  color: "white",
                  backgroundColor: "#FFA382",
                  justifyContent: "flex-end",
                }}
              >
                {inProgress && (
                  <CircularProgress style={{ color: "white" }} size={20} />
                )}
                {!inProgress && `Update Sole Chain Admin's Password`}
              </button>
            ) : (
              <button
                onClick={() => updatePassword(true)}
                disabled={inProgress}
                style={{
                  margin: "0",
                  padding: "0.5em 1.5em",
                  borderRadius: "5px",
                  border: "none",
                  cursor: "pointer",
                  color: "white",
                  backgroundColor: "#FFA382",
                  justifyContent: "flex-end",
                }}
              >
                {inProgress && (
                  <CircularProgress style={{ color: "white" }} size={20} />
                )}
                {!inProgress && `Update Password`}
              </button>
            )}
          </div>
        </>
      ) : (
        <div style={{ maxWidth: "750px" }}>
          <ForgotPassword
            onBack={() => setForgotPassword(false)}
            backText="Back to Change Password Page"
          />
        </div>
      )}
    </div>
  );
};

// import { useEffect } from "react";
// import { useState } from "react";
// import "./ResetPassword.styles.css";
// import Box from "@mui/material/Box";
// import Alert from "@mui/material/Alert";
// import IconButton from "@mui/material/IconButton";
// import CloseIcon from "@mui/icons-material/Close";
// import url from "../../config/axios";
// import { useHistory } from "react-router-dom";
// export const ResetPasswordComponent = ({ onCancel }) => {
//   const history = useHistory();
//   const [formData, setFormData] = useState({
//     currentPassword: "",
//     newPassword: "",
//     confirmPassword: "",
//     loading: false,
//     success: false,
//     message: "",
//   });
//   const [passwordVisibiltyMap, setPasswordVisibilityMap] = useState({
//     currentPassword: false,
//     newPassword: false,
//     confirmPassword: false,
//   });
//   const changeValue = (name, value) =>
//     setFormData({ ...formData, [name]: value });
//   const changePasswordVisibiltyMap = (name) =>
//     setPasswordVisibilityMap({
//       ...passwordVisibiltyMap,
//       [name]: !passwordVisibiltyMap[name],
//     });
//   const [formValidationErrors, setFormvalidationErrors] = useState({});
//   const [triedBefore, setTriedBefore] = useState(false);

//   const admin = JSON.parse(localStorage.getItem("Admin")) || null;

//   useEffect(
//     () => {
//       if (triedBefore) {
//         validateForm();
//       }
//     },
//     //eslint-disable-next-line
//     [formData, triedBefore]
//   );
//   const updatePassword = async () => {
//     console.log(
//       "updating",
//       Object.keys(formValidationErrors).length === 0,
//       formValidationErrors
//     );
//     await setTriedBefore(true);
//     if (
//       Object.keys(formValidationErrors).length === 0 &&
//       formData.newPassword !== "" &&
//       formData.confirmPassword !== ""
//     ) {
//       await url
//         .put(`/oc/?outletChainID=${admin[0].outletChainID}`, {
//           adminPassword: formData.newPassword,
//           currentPassword: formData.currentPassword,
//         })
//         .then((res) => {
//           console.log(res.data.Message, "message");
//           if (res.data.Message === "Success") {
//             setFormData({
//               ...formData,
//               loading: false,
//               success: true,
//               message: "Password updated Successfully",
//             });
//             setTimeout(() => {
//               onCancel();
//             }, 1500);
//           }
//           if (res.data.Message === "Failure") {
//             setFormData({
//               ...formData,
//               loading: false,
//               success: false,
//               message: res.data.data,
//             });
//           }
//         })
//         .catch((e) => console.log(e));
//     } else {
//       setFormData({
//         ...formData,
//         loading: false,
//         success: false,
//         message: "Password is required | incorrect format",
//       });
//     }
//   };
//   const validateForm = () => {
//     let data = { ...formValidationErrors };
//     const { currentPassword, newPassword, confirmPassword } = formData;
//     //currentPassword
//     if (currentPassword.trim().length === 0) {
//       data = { ...data, currentPassword: "Current Password is required" };
//     } else {
//       const { currentPassword, ...others } = data;
//       data = { ...others };
//     }

//     //newPassword
//     if (newPassword.trim().length === 0) {
//       data = { ...data, newPassword: "New Password is required" };
//     } else if (newPassword.trim().length < 8) {
//       data = { ...data, newPassword: "Must be of at least 8 characters long" };
//     } else {
//       let value = formData.newPassword;
//       let specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
//       let containsSpecialCharacter = specialChars.test(value);
//       let containsNumber = /[0-9]/.test(value);
//       let containsUppercase = /[A-Z]/.test(value);
//       let valid =
//         containsNumber && containsUppercase && containsSpecialCharacter;
//       if (valid) {
//         const { newPassword, ...others } = data;
//         data = { ...others };
//       } else {
//         data = {
//           ...data,
//           newPassword:
//             "Must contain at least 1 number, 1 uppercase letter and one 1 special character",
//         };
//       }
//     }

//     //confirmPassword
//     if (
//       confirmPassword.trim().length === 0 &&
//       newPassword.trim().length !== 0
//     ) {
//       data = { ...data, confirmPassword: "Confirm Password is required" };
//     } else if (
//       confirmPassword !== newPassword ||
//       newPassword.trim().length !== 0
//     ) {
//       data = { ...data, confirmPassword: "Passwords don't match" };
//     } else {
//       const { confirmPassword, ...others } = data;
//       data = { ...others };
//     }
//     setFormvalidationErrors({ ...data });
//     return data;
//   };
//   return (
//     <div
//       style={{
//         display: "flex",
//         flexDirection: "column",
//         gap: "0.6em",
//       }}
//     >
//       <h2
//         style={{
//           color: "black",
//         }}
//       >
//         {" "}
//         Reset Password
//       </h2>
//       <span
//         style={{
//           width: "100%",
//           height: "2px",
//           backgroundColor: "lightgray",
//         }}
//       ></span>

//       <div className="reset-password-group">
//         <label
//           style={{
//             fontWeight: 500,
//           }}
//         >
//           Current Password
//         </label>
//         <div className="reset-password-field">
//           <div className="reset-password-icon">
//             <i
//               className={
//                 passwordVisibiltyMap["currentPassword"]
//                   ? "fa fa-eye"
//                   : "fa fa-eye-slash"
//               }
//               style={{
//                 color: "#bababa",
//                 cursor: "pointer",
//               }}
//               onClick={() => changePasswordVisibiltyMap("currentPassword")}
//             ></i>
//           </div>
//           <input
//             className="reset-password-input"
//             name="currentPassword"
//             value={formData.currentPassword}
//             style={{
//               fontWeight: "bold",
//               color: "gray",
//             }}
//             placeholder="Current Password"
//             onChange={(e) => changeValue(e.target.name, e.target.value)}
//             type={passwordVisibiltyMap["currentPassword"] ? "password" : "text"}
//           ></input>
//         </div>
//         <span
//           style={{
//             marginLeft: "0.5em",
//             color: "red",
//             marginTop: "0.3em",
//           }}
//         >
//           {formValidationErrors?.currentPassword ?? ""}
//         </span>
//       </div>

//       <div className="reset-password-group">
//         <label
//           style={{
//             fontWeight: 500,
//           }}
//         >
//           New Password
//         </label>
//         <div className="reset-password-field">
//           <div className="reset-password-icon">
//             <i
//               className={
//                 passwordVisibiltyMap["newPassword"]
//                   ? "fa fa-eye"
//                   : "fa fa-eye-slash"
//               }
//               style={{
//                 color: "#bababa",
//                 cursor: "pointer",
//               }}
//               onClick={() => changePasswordVisibiltyMap("newPassword")}
//             ></i>
//           </div>
//           <input
//             className="reset-password-input"
//             name="newPassword"
//             value={formData.newPassword}
//             style={{
//               fontWeight: "bold",
//               color: "gray",
//             }}
//             placeholder="New Password"
//             onChange={(e) => changeValue(e.target.name, e.target.value)}
//             type={passwordVisibiltyMap["newPassword"] ? "password" : "text"}
//           ></input>
//         </div>
//         <span
//           style={{
//             marginLeft: "0.5em",
//             color: "red",
//             marginTop: "0.3em",
//           }}
//         >
//           {formValidationErrors?.newPassword ?? ""}
//         </span>
//       </div>

//       <div className="reset-password-group">
//         <label
//           style={{
//             fontWeight: 500,
//           }}
//         >
//           Confirm Password
//         </label>
//         <div className="reset-password-field">
//           <div className="reset-password-icon">
//             <i
//               className={
//                 passwordVisibiltyMap["confirmPassword"]
//                   ? "fa fa-eye"
//                   : "fa fa-eye-slash"
//               }
//               style={{
//                 color: "#bababa",
//                 cursor: "pointer",
//               }}
//               onClick={() => changePasswordVisibiltyMap("confirmPassword")}
//             ></i>
//           </div>
//           <input
//             className="reset-password-input"
//             name="confirmPassword"
//             value={formData.confirmPassword}
//             style={{
//               fontWeight: "bold",
//               color: "gray",
//             }}
//             placeholder="Confirm Password"
//             onChange={(e) => changeValue(e.target.name, e.target.value)}
//             type={passwordVisibiltyMap["confirmPassword"] ? "password" : "text"}
//           ></input>
//         </div>
//         <span
//           style={{
//             marginLeft: "0.5em",
//             color: "red",
//             marginTop: "0.3em",
//           }}
//         >
//           {formValidationErrors?.confirmPassword ?? ""}
//         </span>
//       </div>
//       {(formData.success || !!formData.message) && (
//         <Box sx={{ width: "100%" }}>
//           <Alert
//             severity={formData.success ? "success" : "error"}
//             action={
//               <IconButton
//                 aria-label="close"
//                 color={"default"}
//                 size="small"
//                 onClick={() => {
//                   setFormData({
//                     ...formData,
//                     success: false,
//                     message: "",
//                   });
//                 }}
//               >
//                 <CloseIcon fontSize="inherit" />
//               </IconButton>
//             }
//             sx={{ mb: 2 }}
//           >
//             {formData?.success
//               ? "Password updated Successfully"
//               : formData.message}
//           </Alert>
//         </Box>
//       )}

//       <div className="reset-password-group">
//         <div
//           style={{
//             display: "flex",
//             flexDirection: "row-reverse",
//             gap: "1em",
//           }}
//         >
//           <button
//             onClick={updatePassword}
//             className="btn btn-cannaby"
//             style={{
//               margin: "0",
//               padding: "0.5em 1.5em",
//               borderRadius: "5px",
//               border: "none",
//               cursor: "pointer",
//               backgroundColor: "#5D5FEF",
//             }}
//           >
//             Update Password
//           </button>
//           <button
//             onClick={() => onCancel()}
//             className="btn"
//             style={{
//               padding: "0.5em 3em",
//               borderRadius: "5px",
//               cursor: "pointer",
//               outline: "none",
//               border: "none",
//               backgroundColor: "#D8D8D8",
//             }}
//           >
//             Cancel
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// };
