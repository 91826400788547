import styled from "@emotion/styled"
import React from "react"
import './style.css'
import { colors } from "../../Style.style"


const ContentContainer = ({ heading, children }) => {
    const Container = styled.div`
        padding-top: 0.05px;
        box-sizing: border-box;
        background-color: white;
        border-radius: 8px;
        .heading-label {
            margin: 20px;
            position: relative;
            font-size: 1.1rem;
            font-weight: bold;
            color: ${colors.accent};
            &::before {
                content: "";
                position: absolute;
                left: -20px;
                top: 0px;
                height: 100%;
                width: 5px;
                background-color: ${colors.accent};
            }
            &::after {
                content: "";
                position: absolute;
            }
        }
        hr {
            width: 100%;
            height: 1px;
            background-color: "#F1F5F9";
        }
        .content {
            padding: 20px;
        }
    `
    return (
        <div className="content-container">
            <h1 className="heading-label">{heading}</h1>
            <hr />
            <div className="content">
                {children}
            </div>            
        </div>
    )
}

export default React.memo(ContentContainer);