/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect, useCallback } from "react";
import "./Header.css";
import { useHistory } from "react-router-dom";
import HeaderLeft from "./HeaderLeft";
import { Route } from "react-router-dom";
import storage from "../config/firebase";
import url from "../config/axios";
import Cropper from "react-easy-crop";
import Slider from "@mui/material/Slider";
import getCroppedImg from "../helpers/cropImage";
import Dialog from "@mui/material/Dialog";
import CA from "../../src/assets/CA.svg";
import US from "../../src/assets/US.svg";
import Back from "../../src/assets/Back.svg"
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl'
import data from '../helpers/countries.json'
import Bell from "../assets/Bell.svg"
import msg from "../assets/msg.svg"
import question from "../assets/question.svg"


function Header() {
  const history = useHistory()
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [showCrop, setShowCrop] = useState(false);
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [showProfile, setShowProfile] = useState(false);
  const [editAdmin, setEditAdmin] = useState(false);
  const [selectCountry, setSelectCountry] = useState([]);
  const [outletnumber, setOutletNumber] = useState("")
  const [image, setImage] = useState("");
  const [editImage, setEditImage] = useState("");
  const [file, setFile] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [phoneCode, setPhoneCode] = useState("");
  const [phoneCountry, setPhoneCountry] = useState("")
  const [phoneData, setPhoneData] = useState([{
    "phoneCode": "+1",
    "flag": US,
    "phoneCountry": "US",
    "selected": false
  }, {

    "phoneCode": "+1",
    "flag": CA,
    "phoneCountry": "CA",
    "selected": false
  }
  ])
  // Error handling useStates
  const [nameError, setNameError] = useState("");
  const [phoneNoError, setPhoneNoError] = useState("")
  const [error, setError] = useState("")
  let orderID = localStorage.getItem("orderID")

  const admin = JSON.parse(localStorage.getItem("Admin")) || null;
  // console.log(admin, "check who he is")
  useEffect(() => {
    getAdminDetails();
    getCountryCode();
    GetAllOutlets()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(
    async (e) => {
      e.preventDefault();
      try {
        const croppedImage = await getCroppedImg(
          editImage,
          croppedAreaPixels,
          0
        );
        localStorage.setItem("ImageSet", croppedImage);
        setEditImage(croppedImage);
        setShowCrop(false);
        setOpen(false);
      } catch (e) {
        console.error(e);
      }
    },
    [croppedAreaPixels, editImage]
  );

  const imageSelector = (event) => {
    if (event.target.files[0]) {
      setEditImage(URL.createObjectURL(event.target.files[0]));
      setFile(event.target.files[0]);
      setOpen(true);
      setShowCrop(true);
    }
  };

  const getCountryCode = () => {
    let x = selectCountry;
    data.map((e) => {
      return x.push({ value: e.dial_code, label: e.flag + e.dial_code, flag: e.flag, code: e.code, dial_code: e.dial_code })
    })
    setSelectCountry(x)
    // console.log(x)
  }

  const updateAdmin = (blob) => {
    let body = {
      adminName: name,
      adminEmail: email,
      phone: phoneNo,
      phoneCountry: phoneCountry,
      countryCode: phoneCode,
    };

    if (blob) {
      body.adminImage = blob
    }

    console.log(admin[0], "ID")
    console.log(admin, "body")
    if (admin[0].isSubOutlet) {
      url
        .put(`/oc/?outletChainID=${admin[0].outletChainID}`, body)
        .then((res) => {
          if (res.data.Message === "Success") {
            setError("")
            setName(res.data.data.adminName);
            setEmail(res.data.data.adminEmail);
            setPhoneNo(res.data.data.phone);
            setPhoneCode(res.data.data.countryCode);
            setImage(res.data.data.adminImage);
            setPhoneCountry(res.data.data?.phoneCountry)
            localStorage.setItem("Admin", JSON.stringify(res.data.data))
            setEditAdmin(false);
            setShowProfile(false);

          }
          if (res.data.Message === "Failure") {
            setError(res.data.data)
          }
        })
        .catch((e) => console.log(e));
    } else {
      url
        .put(`/oca/?adminID=${admin[0].adminID}`, body)
        .then((res) => {
          if (res.data.Message === "Success") {
            setError("")
            setName(res.data?.data[0].adminName);
            setEmail(res.data.data[0].adminEmail);
            setPhoneNo(res.data.data[0].phone);
            setPhoneCode(res.data.data[0].countryCode);
            setImage(res.data.data[0].adminImage);
            setPhoneCountry(res.data.data[0]?.phoneCountry);
            localStorage.setItem("Admin", JSON.stringify(res.data.data))
            setEditAdmin(false);
            setShowProfile(false);

          }
          if (res.data.Message === "Failure") {
            alert(res.data.data)
            setError(res.data.data)
          }
        })
        .catch((e) => console.log(e));
    }
    getAdminDetails();
  };

  const GetAllOutlets = () => {
    // console.log(adminID[0].adminID);
    url
      .get(`/oc/all/?consumerId=${admin[0].consumerId}`)
      .then(async (res) => {
        if (res.data.Message === "Success") {

          setOutletNumber(res.data.data.length)
        } else {
          // alert(res.data.data);
        }
      })
      .catch((e) => console.log(e));
  };
  const fileUpdate = () => {
    // const storageRef = ref(storage, `images/${file.name}`);
    if (file !== "") {
      const uploadTask = storage.ref(`images/${file.name}`).putString(localStorage.getItem("ImageSet"), 'data_url');
      uploadTask.on(
        "state_changed",
        snapshot => {
          // const progress = Math.round(
          //   (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          // );
        },
        error => {
          console.log(error);
        },
        () => {
          storage
            .ref("images")
            .child(file.name)
            .getDownloadURL()
            .then(async (blob) => {
              // console.log(blob);
              await updateAdmin(blob);
            });
        });
      setShowProfile(false)
    }
    else {
      updateAdmin(image);
    }
  }

  const getAdminDetails = () => {
    console.log(admin, 'admin')
    if (admin !== null) {
      setError("")
      setName(admin[0].adminName);
      setEmail(admin[0].adminEmail);
      setPhoneNo(admin[0].phone);
      setPhoneCode(admin[0].countryCode);
      setImage(admin[0].adminImage);
      setEditImage(admin[0].adminImage);
      let newArr = phoneData.map((item, i) => {
        if (admin[0]?.phoneCountry === item.phoneCountry) {

          return { ...item, selected: true };
        } else {
          return { ...item, selected: false };
        }
      });
      setPhoneData(newArr);
      setPhoneCountry(admin[0]?.phoneCountry)
    }

    if (admin[0].isSubOutlet) {
      url
        .get(`/oc/id/?outletChainID=${admin[0].outletChainID}`)
        .then((res) => {
          console.log(res, "Sole admin Details");
          if (res.data.Message === "Success") {
            setError("")
            setName(res.data.data[0].adminName);
            setEmail(res.data.data[0].adminEmail);
            setPhoneNo(res.data.data[0].phone);
            let newArr = phoneData.map((item, i) => {
              if (admin[0]?.phoneCountry === item.phoneCountry) {

                return { ...item, selected: true };
              } else {
                return { ...item, selected: false };
              }
            });
            setPhoneData(newArr);
            setPhoneCountry(admin[0]?.phoneCountry)
            setPhoneCode(res.data.data[0].countryCode);
            setImage(res.data.data[0].adminImage);
            setEditImage(res.data.data[0].adminImage);

          }
        })
        .catch((e) => console.log(e));
    } else {
      url
        .get(`/oca/?adminID=${admin[0].adminID}`)
        .then((res) => {
          console.log(res, "Multi admin Details");
          if (res.data.Message === "Success") {
            setError("")
            setName(res.data.data[0].adminName);
            setEmail(res.data.data[0].adminEmail);
            setPhoneNo(res.data.data[0].phone);
            setPhoneCode(res.data.data[0].countryCode);
            setImage(res.data.data[0].image);
            setEditImage(res.data.data[0].image);
          }
        })
        .catch((e) => console.log(e));
    }
  };

  const PhoneValidation = (inputText) => {
    console.log(phoneNo)
    var re = /^[0-9\b]+$/
    if (re.test(inputText)) {
      setPhoneNo(inputText)
      setPhoneNoError("")

    }
    else {
      console.log("not setting value")
    }
  }
  const color = ["#03b7a5", "#fc3986", "#ab48d5", "#2bb72b", "#bdd72a"];
  var num = 0;

  function assignColor() {
    var random = Math.floor(Math.random() * color.length);
    while (num === random) {
      random = Math.floor(Math.random() * color.length);
    }
    num = random;
    var value = color[random];
    return value;
  }
  const updateFieldChanged = (event) => {

    setPhoneCode(event.target.value.split(",")[0])
    setPhoneCountry(event.target.value.split(",")[1])
    let newArr = phoneData.map((item, i) => {
      if (event.target.value.split(",")[1] === item.phoneCountry) {
        console.log("match found at", i)
        return { ...item, selected: true };
      } else {
        return { ...item, selected: false };
      }
    });
    console.log(newArr)
    setPhoneData(newArr);
  };
  return (
    <>
      <nav className="navbar navbar-expand-lg">
        <div className="collapse navbar-collapse" id="navbarText">
          <div className="row row-header">
            <Route path="/categories">
              {" "}
              <HeaderLeft name="Categories" />
            </Route>
            <Route path="/my-notifications">
              {" "}
              <HeaderLeft name="Notifications" />
            </Route>

            <Route path="/brands">
              {" "}
              <HeaderLeft name="Brands" />
            </Route>
            <Route path="/outlets">
              {" "}
              <HeaderLeft name="Outlets" number={outletnumber} />
            </Route>
            <Route path="/dashboard">
              {" "}
              <HeaderLeft name="Dashboard" />
            </Route>
            <Route path="/add-outlet">
              {" "}
              <HeaderLeft name="Add Outlet" />
            </Route>
            <Route path="/edit-outlet">
              {" "}
              <HeaderLeft name="Edit Outlet" />
            </Route>
            <Route path="/deals-outlet">
              {" "}
              <HeaderLeft name="Deals" />
            </Route>
            <Route path="/banner">
              {" "}
              <HeaderLeft name="Banner" />
            </Route>
            <Route path="/add-banner">
              {" "}
              <HeaderLeft name="Add New Banner" />
            </Route>
            <Route path="/edit-banner">
              {" "}
              <HeaderLeft name="Update Banner" />
            </Route>
            <Route path="/deals">
              {" "}
              <HeaderLeft name="Deals" />
            </Route>
            <Route path="/chat">
              {" "}
              <HeaderLeft name="Messages" />
            </Route>
            <Route path="/add-deal">
              {" "}
              <HeaderLeft name="Add New Deal" />
            </Route>
            <Route path="/add-membership">
              {" "}
              <HeaderLeft name="Add Membership" />
            </Route>
            <Route path="/edit-membership">
              {" "}
              <HeaderLeft name="Edit Membership" />
            </Route>
            <Route path="/membership">
              {" "}
              <HeaderLeft name="Membership" />
            </Route>
            <Route path="/edit-deal">
              {" "}
              <HeaderLeft name="Edit Deal" />
            </Route>
            <Route path="/discounts">
              {" "}
              <HeaderLeft name="Coupons" />
            </Route>
            <Route path="/discounts-outlet">
              {" "}
              <HeaderLeft name="Coupons" />
            </Route>
            <Route path="/add-coupon">
              {" "}
              <HeaderLeft name="Add New Coupon" />
            </Route>
            <Route path="/edit-coupon">
              {" "}
              <HeaderLeft name="Edit Coupon" />
            </Route>
            <Route path="/users">
              {" "}
              <HeaderLeft name="Users" />
            </Route>
            <Route path="/user-detail">
              {" "}
              <HeaderLeft name="User Account" Back={Back} />
            </Route>
            <Route path="/add-missing">
              {" "}
              <HeaderLeft name="Add Missing Product" />
            </Route>
            <Route path="/inventory">
              {" "}
              <HeaderLeft name="Inventory" />
            </Route>
            <Route path="/inventory-outlet">
              {" "}
              <HeaderLeft name="Inventory" />
            </Route>
            <Route path="/catalog-outlet">
              {" "}
              <HeaderLeft name="Catalog" />
            </Route>
            <Route path="/order-outlet">
              {" "}
              <HeaderLeft name="Orders" />
            </Route>
            <Route path="/order">
              {" "}
              <HeaderLeft name="Orders" />
            </Route>
            <Route path="/order-detail">
              {" "}
              <HeaderLeft name="Order" orderID={orderID} Back={Back} />
            </Route>
            <Route path="/addCatalog">
              {" "}
              <HeaderLeft name="Add Catalog" />
            </Route>
            <Route path="/editCatalog">
              {" "}
              <HeaderLeft name="Edit Catalog" />
            </Route>
            <Route path="/page">
              {" "}
              <HeaderLeft name="Pages" />
            </Route>
            <Route path="/add-page">
              {" "}
              <HeaderLeft name="Add Page" />
            </Route>
            <Route path="/Setting">
              {" "}
              <HeaderLeft name="Setting" />
            </Route>
            <Route path="/general-settings">
              {" "}
              <HeaderLeft name="General Settings" />
            </Route>
            <Route path="/loyalty-points">
              {" "}
              <HeaderLeft name="Loyalty Points" />
            </Route>
            <Route path="/loyalty-add-points">
              {" "}
              <HeaderLeft name="Add Loyalty Points" />
            </Route>
            <Route path="/Special-Event">
              {" "}
              <HeaderLeft name="Special Event Coupons" />
            </Route>
            <Route path="/Add-SpecialEvent">
              {" "}
              <HeaderLeft name="Add New Event Coupon" />
            </Route>
            <Route path="/Edit-SpecialEvent">
              {" "}
              <HeaderLeft name="Edit Special Coupon" />
            </Route>

            <div className="col-md-6 user-head">
              <div className="notification-icons">
                <span
                  style={{
                    cursor: "not-allowed",
                  }}>
                  <img src={question} />
                </span>
                <span
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => history.push("/chat")}
                >
                  <img src={msg} />
                </span>
                <span
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => history.push("/my-notifications")}
                >
                  <img src={Bell} />
                </span>
              </div>

              <span className="navbar-text">
                <div className="container d-flex justify-content-center">
                  <div className="">
                    <div className="top-container">

                      {
                        image ?
                          <img
                            alt="yo4"
                            src={image}
                            onClick={() => setShowProfile(!showProfile)}
                            className="img-fluid profile-image"
                            width="53"
                          /> :
                          <div
                            onClick={() => setShowProfile(!showProfile)}
                            className="image-thumbnail-div"
                            style={{
                              backgroundColor: assignColor(),
                              width: "40px",
                              height: "40px",
                              borderRadius: "5px",
                            }}>
                            <span>{name?.substring(0, 1)}</span>
                          </div>
                      }

                      <div className="ml-3">
                        <h5 className="name">{name}</h5>
                        <p className="mail" style={{ color: "black" }}>
                          {admin[0]?.isSubOutlet
                            ? "Sole Chain Admin"
                            : "Multi Chain Admin"}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </span>
            </div>
          </div>
        </div>
      </nav>
      {
        showProfile && (
          <div className="profile-section">
            <div className="profile-parent">
              <label
                onClick={() => {
                  getAdminDetails()
                  setNameError("")
                  setPhoneNoError("")
                  setEditAdmin(false)
                  setShowProfile(false)
                }}
                className="label-close"
                style={{ cursor: "pointer", margin: "15px" }}
              >
                <i class="fas fa-times"></i>
              </label>
              <span className="navbar-text">
                <div className="parent-right-nav container d-flex justify-content-center">
                  <div className="">
                    <div className="top-container-rnav">
                      <form>
                        <div className="rnav-image">
                          <div className="image-container-rnav">
                            <img
                              alt="yo4"
                              src={editImage}
                              className="img-fluid profile-image"
                              width="53"
                            />

                            <label className="custom-file-upload-rnav">
                              <input
                                type="file"
                                id="image1"
                                disabled={!editAdmin}
                                name="Image1"
                                onChange={imageSelector}
                                accept="image/png, image/jpeg"
                              />
                              <i className="fas fa-pen"></i>
                            </label>
                            {showCrop && (
                              <Dialog
                                fullScreen={fullScreen}
                                open={open}
                                fullWidth={true}
                                onClose={() => setOpen(false)}
                                aria-labelledby="responsive-dialog-title"
                              >
                                <div className="App" style={{
                                  padding: "1em",
                                  width: "auto",
                                  height: "auto",
                                }}>
                                  <div className="crop-container">
                                    <Cropper
                                      image={editImage}
                                      crop={crop}
                                      zoom={zoom}
                                      aspect={4 / 3}
                                      onCropChange={setCrop}
                                      onCropComplete={onCropComplete}
                                      onZoomChange={setZoom}
                                    />
                                  </div>
                                  <div className="controls">
                                    <Slider
                                      value={zoom}
                                      min={1}
                                      max={3}
                                      step={0.1}
                                      aria-labelledby="Zoom"
                                      onChange={(e, zoom) => setZoom(zoom)}
                                      classes={{ root: "slider" }}
                                    />
                                  </div>
                                  <div>
                                    <button
                                      style={{
                                        textAlign: "center",
                                        width: "auto",
                                        padding: "10px 30px",
                                      }}
                                      className="btn btn-primary crop-btn"
                                      onClick={(e) => showCroppedImage(e)}
                                    >
                                      Crop
                                    </button>
                                  </div>
                                </div>
                              </Dialog>
                            )}
                          </div>
                        </div>
                        <div
                          className={
                            "rnav-name " + (editAdmin ? "rnav-input-active" : "")
                          }
                        >
                          <label>Name*</label>
                          <input
                            style={{ backgroundColor: "rgb(233, 236, 239)" }}
                            type="text"
                            disabled={!editAdmin}
                            value={name}
                            onChange={(e) => {
                              setName(e.target.value)
                              setNameError("")
                            }}
                            className="form-control"
                            id="exampleFormControlInput1"
                            placeholder="Enter name"
                          />

                        </div>
                        <p style={{ color: "red", fontSize: "12px" }}>
                          {nameError ? nameError : null}
                        </p>
                        <div
                          className={
                            "rnav-phone " + (editAdmin ? "rnav-input-active" : "")
                          }
                        >
                          <div className="row" style={{ height: "60px" }}>
                            <div className="col-md-5">
                              <label className="phone-label">Code</label>
                            </div>
                            <div className="col-md-7">
                              <label className="phone-label">Phone</label>
                            </div>
                            <div className="col-md-5">

                              <Box sx={{ minWidth: 120 }}>
                                <FormControl fullWidth>

                                  <Select
                                    style={{ backgroundColor: "rgb(233, 236, 239)" }}
                                    disabled={!editAdmin}
                                    renderValue={() => phoneData[0].selected === true ?
                                      <div style={{ paddingTop: "5px ", paddingLeft: "5px ", height: "13px" }}>
                                        <img src={phoneData[0].flag} style={{ height: "10px", marginTop: "-3px" }} />
                                        <p style={{ marginTop: "-23px", marginLeft: "16px" }}>
                                          {phoneData[0].phoneCode + " "}{phoneData[0].phoneCountry}
                                        </p>
                                      </div>
                                      :
                                      <div style={{ paddingTop: "5px ", paddingLeft: "5px ", height: "13px" }}>
                                        <img src={phoneData[1].flag} style={{ height: "10px", marginTop: "-3px" }} />
                                        <p style={{ marginTop: "-23px", marginLeft: "16px" }}>
                                          {phoneData[1].phoneCode + " "}{phoneData[1].phoneCountry}
                                        </p>
                                      </div>
                                    }
                                    defaultValue={phoneData[0].selected === true ? phoneData[0].phoneCode + phoneData[0].phoneCountry : phoneData[1].phoneCode + phoneData[1].phoneCountry}
                                    className="myDropDownHeader"
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={phoneData[0].selected === true ? phoneData[0].phoneCode + phoneData[0].phoneCountry : phoneData[1].phoneCode + phoneData[1].phoneCountry}
                                    label="Age"
                                    onChange={updateFieldChanged}

                                  >
                                    {phoneData.map((i, index) => {
                                      return <MenuItem

                                        selected={i.selected}
                                        tabIndex={index} value={i.phoneCode + "," + i.phoneCountry}><div style={{ paddingTop: "5px !important", height: "13px" }}><img src={i.flag} style={{ height: "10px" }} />{i.phoneCode + " "}{i.phoneCountry}</div></MenuItem>
                                    })}

                                  </Select>
                                </FormControl>
                              </Box>

                            </div>
                            <div className="col-md-7 phone-input-rnav">
                              <input
                                style={{ backgroundColor: "rgb(233, 236, 239)", marginLeft: "2px" }}
                                maxLength={10}
                                disabled={!editAdmin}
                                value={phoneNo}
                                pattern="[0-9]*"
                                onChange={(e) => {

                                  if (e.target.validity.valid) {
                                    setPhoneNo(e.target.value)
                                    setPhoneNoError("")
                                  }
                                }}
                                className="form-control"
                                id="exampleFormControlInput1"
                                placeholder="Phone number"
                              />
                            </div>
                          </div>

                        </div>
                        <p style={{ color: "red", fontSize: "12px" }}>
                          {phoneNoError ? phoneNoError : null}
                        </p>
                        <div
                          className={
                            "rnav-name " + (editAdmin ? "rnav-input-active" : "")
                          }
                        >
                          <label>Email</label>
                          <input
                            style={{ backgroundColor: "rgb(233, 236, 239)" }}
                            type="text"
                            disabled={!editAdmin}
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className="form-control"
                            id="exampleFormControlInput1"
                            placeholder="Enter email"
                          />
                        </div>
                        {error ? <p style={{ color: "red", fontSize: "12px" }}>
                          {error}
                        </p> : null}
                      </form>
                      <div className="rnav-btn-grp">
                        {!editAdmin && (
                          <button
                            className="btn btn-rnav"
                            onClick={() => setEditAdmin(true)}
                          >
                            Edit
                          </button>
                        )}
                        {editAdmin && (
                          <div>
                            <button
                              className="btn btn-primary"
                              onClick={() => {

                                if (name === "") {
                                  setNameError("Name is required")
                                }
                                if (!phoneNo) {

                                  setPhoneNoError("Phone no is required")
                                }
                                else if (name !== "" && phoneNo !== "") {
                                  if (phoneNo.length < 7) {
                                    setPhoneNoError("Invalid phone number")
                                  }
                                  else {
                                    fileUpdate()

                                  }
                                }
                              }}
                            >
                              Save
                            </button>
                            <button
                              className="btn btn-rnav"
                              onClick={() => {

                                getAdminDetails()
                                setNameError("")
                                setPhoneNoError("")
                                setEditAdmin(false)
                              }}
                            >
                              Cancel
                            </button>

                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </span>
              {/* <div className="nav-msg">
              <p>This is a super admin account</p>
            </div> */}
            </div >
          </div >
        )
      }
    </>
  );
}

export default Header;
