/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-lone-blocks */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-unused-vars */
// import AddBrand from './AddBrand';
import "./Brands.css";
// import brandImage from '../../images/brandImage.png'
import {
  useMemo,
  useEffect,
  useState,
  useCallback,
  useRef,
  Fragment,
} from "react";
import url from "../../config/axios";
import storage from "../../config/firebase";
import moment from "moment";
import Cropper from "react-easy-crop";
import Slider from "@mui/material/Slider";
import getCroppedImg from "../../helpers/cropImage";
import Dialog from "@mui/material/Dialog";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
// import XLSX from "xlsx";
import Popup from "reactjs-popup";
import sort from "../../assets/sort.svg";
import upload from "../../assets/upload.svg";
import Pagination from "@mui/material/Pagination";
import debounce from "../../helpers/debounce";
import { serializeQueryStringsFromObject } from "../../helpers/serializeToQueryStrings";
import { useHistory, useLocation } from "react-router-dom";
import InvokeLayoutComponent from "../common/InvokeLogout";
import BrandTable from "./components/BrandTable.jsx";
import { margin } from "@mui/system";
import { makeStyles } from "@material-ui/core/styles";
import { colors } from "../../Style.style";
const useStyles = makeStyles({
  root: {
    "& .Mui-selected": {
      backgroundColor: "#FFA382 !important",
    },
    "& .MuiPaginationItem-root": {
      borderRadius: "5px !important",
    },
  },
});
const Brands = () => {
  const classes = useStyles();

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [showCrop, setShowCrop] = useState(false);
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [showComponent, setComponent] = useState(false);
  const [editBoolean, setEditBoolean] = useState(false);
  const [brands, setBrands] = useState([]);
  const [name, setName] = useState("");
  const [slug, setSlug] = useState("");
  const [brandID, setBrandID] = useState("");
  const [file, setFile] = useState("");
  const [image, setImage] = useState("");
  const notFoundRef = useRef();
  const history = useHistory();
  const [showImage, setShowImage] = useState(false);
  const admin = JSON.parse(localStorage.getItem("Admin")) || null;
  const color = ["#03b7a5", "#fc3986", "#ab48d5", "#2bb72b", "#bdd72a"];
  const [outlets, setOutlets] = useState([]);
  const [outletID, setOutletID] = useState("");
  const [unfiltered, setUnfiltered] = useState([]);

  useEffect(() => {
    if (admin[0].isSubOutlet) {
      GetAllBrands();
    } else {
      GetAllOutlets();
    }
  }, []);

  const GetAllOutlets = () => {
    const adminID = JSON.parse(localStorage.getItem("Admin"));
    // console.log(adminID[0].adminID);
    url
      .get(`/oc/?consumerId=${adminID[0].consumerId}`)
      .then(async (res) => {
        // console.log('res', res)
        setOutlets(res.data.data);
      })
      .catch((e) => console.log(e));
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(
    async (e) => {
      e.preventDefault();
      try {
        const croppedImage = await getCroppedImg(image, croppedAreaPixels, 0);
        console.log("donee", { croppedImage });
        localStorage.setItem("ImageSet", croppedImage);
        setImage(croppedImage);
        setShowImage(true);
        setShowCrop(false);
        setOpen(false);
      } catch (e) {
        console.error(e);
      }
    },
    [croppedAreaPixels, image]
  );

  const updateBrand = async (blob) => {
    await url
      .put(`/brand/?brandID=${brandID}`, {
        name: name,
        image: blob,
        slug: slug,
      })
      .then((res) => {
        // console.log("Updated data is: ", res.data);
        if (res.data.Message === "Success") {
          setName("");
          setImage("");
          setSlug("");
          setBrandID("");
          setSlug("");
          setFile("");
          setComponent(false);
          setEditBoolean(false);
          setBrands([
            ...brands.map((obj) =>
              obj?.brandID === res?.data?.data?.brandID ? res?.data?.data : obj
            ),
          ]);
        } else {
          setName("");
          setImage("");
          setSlug("");
          setBrandID("");
          setSlug("");
          setFile("");
          setComponent(false);
          setEditBoolean(false);
          alert("Error: Something went wrong");
        }
        // console.log(res);
        setComponent(false);
        setEditBoolean(false);
      })
      .catch((e) => console.error(e));
  };

  const editBrand = async (brand) => {
    // await localStorage.setItem('brand', JSON.stringify(brand));
    setName(brand.name);
    setImage(brand.image);
    setSlug(brand.slug);
    setBrandID(brand.brandID);
    // console.log('brand', brand);
    setEditBoolean(true);
    setComponent(!showComponent);

    // setCreatedDateE(brand.createdAt);
  };

  const imageSelector = (event) => {
    if (event.target.files[0]) {
      setImage(URL.createObjectURL(event.target.files[0]));
      setFile(event.target.files[0]);
      setOpen(true);
      setShowCrop(true);
    }
  };

  const fileUpdate = () => {
    // const storageRef = ref(storage, `images/${file.name}`);
    if (file !== "") {
      const uploadTask = storage
        .ref(`images/${file.name}`)
        .putString(localStorage.getItem("ImageSet"), "data_url");
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // const progress = Math.round(
          //   (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          // );
        },
        (error) => {
          console.log(error);
        },
        () => {
          storage
            .ref("images")
            .child(file.name)
            .getDownloadURL()
            .then(async (blob) => {
              console.log(blob);
              await updateBrand(blob);
            });
        }
      );
    } else {
      updateBrand(image);
    }
  };

  //   const fileUpload = () => {
  //     // const storageRef = ref(storage, `images/${file.name}`);
  //     if (file !== "") {
  //       const uploadTask = storage
  //         .ref(`images/${file.name}`)
  //         .putString(localStorage.getItem("ImageSet"), "data_url");

  //       uploadTask.on(
  //         "state_changed",
  //         (snapshot) => {
  //           // const progress = Math.round(
  //           //   (snapshot.bytesTransferred / snapshot.totalBytes) * 100
  //           // );
  //         },
  //         (error) => {
  //           console.log(error);
  //         },
  //         () => {
  //           storage
  //             .ref("images")
  //             .child(file.name)
  //             .getDownloadURL()
  //             .then(async (blob) => {
  //               console.log(blob);
  //               await addBrand(blob);
  //             });
  //         }
  //       );
  //     } else {
  //       addBrand("");
  //     }
  //   };

  const closeComp = () => {
    setImage("");
    setShowImage(false);
    setComponent(false);
  };

  const [invokeLogOut, setInvokeLogout] = useState(false);
  const initialPaginationData = {
    page: 1,
    limit: 30,
    latestFirst: -1,
    search: "",
  };
  const [queryData, setQueryData] = useState({
    ...initialPaginationData,
  });
  const [searchTerm, setSearchTerm] = useState("");
  const [previousSearchTerm, setPreviousSearchTerm] = useState("");
  //whole editable object will be place here
  const [currentEditingIndex, setCurrentEditingIndex] = useState(null);
  // const performSearch = debounce(() => {
  //   if ((searchTerm + "").trim() !== (previousSearchTerm + "").trim()) {
  //     setPreviousSearchTerm(searchTerm);
  //     handleChange("search", searchTerm, true);
  //   } else if (searchTerm === "") {
  //     setPreviousSearchTerm("");
  //     handleChange("search", "", true);
  //   }
  // }, 1500);
  // useEffect(
  //   () => {
  //     if (parsed && !loading) {
  //       performSearch();
  //     }
  //   },
  //   //eslint-disable-next-line
  //   [searchTerm]
  // );
  //parse passed queries from the URL first
  const { search } = useLocation();
  const [parsed, setParsed] = useState(false);
  useEffect(
    () => {
      const query = new URLSearchParams(search);

      let page =
        Number.isNaN(query.get("page")) || !query.get("page")
          ? 1
          : +query.get("page");
      let limit =
        Number.isNaN(query.get("limit")) || !query.get("limit")
          ? 30
          : +query.get("limit");
      let latestFirst =
        Number.isNaN(query.get("latestFirst")) || !query.get("latestFirst")
          ? -1
          : +query.get("latestFirst");
      let searchParams = query.get("search");
      let queryParams = {
        ...queryData,
        page,
        limit,
        latestFirst,
        search: searchParams,
      };
      setSearchTerm(searchParams);
      setQueryData({ ...queryParams });
      setParsed(true);
    },
    //eslint-disable-next-line
    []
  );
  //clientSide search
  useEffect(
    () => {
      if (searchTerm?.length !== 0) {
        console.log(unfiltered, "unfiltered");
        let filtered = unfiltered.filter((element) =>
          element.name?.toLowerCase().includes(searchTerm?.trim().toLowerCase())
        );
        setBrands(filtered);
        // if (filtered.length === 0) {
        //   openNotFoundPopUp();
        // }
      } else {
        setBrands(unfiltered);
      }
    },
    // eslint-disable-next-line
    [searchTerm]
  );
  const [paginationData, setPaginationData] = useState({
    currentPage: 1,
    perPage: 30,
    totalPages: 0,
    total: 0,
  });
  const [loading, setLoading] = useState(true);
  //call to fetch data after parsing
  useEffect(
    () => {
      if (parsed) {
        GetAllBrands({ ...queryData });
      }
    },
    //eslint-disable-next-line
    [parsed, queryData]
  );

  /**
   *
   * @param {string} propName property to change
   * @param {*} propValue corresponding value of that property
   * @param {*} flush changes every other values to initial data
   */
  const handleChange = (propName, propValue, flush = false) => {
    if (currentEditingIndex === null) {
      let query = {};
      if (flush) {
        query = {
          ...initialPaginationData,
          [propName]: propValue,
        };
      } else {
        query = { ...queryData, [propName]: propValue };
      }
      const queryString = serializeQueryStringsFromObject(query);
      history.push(`/brands${queryString}`);
      setQueryData({ ...query });
    }
  };

  const GetAllBrands = () => {
    setLoading(true);
    let queryParams = { ...queryData };
    //sanity
    Object.keys(queryParams).forEach((key) => {
      if (!queryParams[key]) {
        delete queryParams[key];
      }
    });
    url
      .get(`/v2/admin/brands/paginated`, {
        params: {
          ...queryParams,
        },
      })
      .then(async (res) => {
        // console.log('res', res)
        if (res.data.Message === "Success") {
          if (!res.data?.data?.brands?.length) {
            notFoundRef.current.open();
          }
          console.log("Brands are: ", res.data?.data?.brands);
          let countInventoryMap = [];
          res.data.data.brands.map((item) => {
            if (item.countInventory) {
              // category should not visible if countInventory is 0
              item.countInventory.map((count) => {
                if (
                  count.count > 0 &&
                  count.outletChainID === admin[0].outletChainID
                ) {
                  countInventoryMap.push(item);
                }
              });
            }
          });
          setBrands(countInventoryMap);
          setUnfiltered(countInventoryMap);
          setPaginationData({
            ...paginationData,
            ...res?.data?.data?.paginationData,
          });
        } else {
          console.error(res);
        }
        setLoading(false);
      })
      .catch((e) => {
        if (+e?.response?.status === 401) {
          setInvokeLogout(true);
        }
        setLoading(false);
        console.error(e?.response);
      });
  };

  const GetBrandById = (outletId) => {
    setOutletID(outletId);
    setLoading(true);
    let queryParams = { ...queryData };
    //sanity
    Object.keys(queryParams).forEach((key) => {
      if (!queryParams[key]) {
        delete queryParams[key];
      }
    });
    url
      .get(`/v2/admin/brands/paginated`, {
        params: {
          ...queryParams,
        },
      })
      .then(async (res) => {
        // console.log('res', res)
        if (res.data.Message === "Success") {
          if (!res.data?.data?.brands?.length) {
            notFoundRef.current.open();
          }
          console.log("Brands are: ", res.data?.data?.brands);
          let countInventoryMap = [];
          res.data.data.brands.map((item) => {
            if (item.countInventory) {
              // category should not visible if countInventory is 0
              item.countInventory.map((count) => {
                if (count.count > 0 && count.outletChainID === outletId) {
                  countInventoryMap.push(item);
                }
              });
            }
          });
          setBrands(countInventoryMap);
          setUnfiltered(countInventoryMap);
          setPaginationData({
            ...paginationData,
            ...res?.data?.data?.paginationData,
          });
        } else {
          console.error(res);
        }
        setLoading(false);
      })
      .catch((e) => {
        if (+e?.response?.status === 401) {
          setInvokeLogout(true);
        }
        setLoading(false);
        console.error(e?.response);
      });
  };

  let num = 0;

  function assignColor() {
    var random = Math.floor(Math.random() * color.length);
    while (num === random) {
      random = Math.floor(Math.random() * color.length);
    }
    num = random;
    var value = color[random];
    console.log(value, "VALUES");
    return value;
  }
  const Tablenew = useMemo(
    () => (
      <BrandTable
        brands={brands}
        assignColor={assignColor}
        editBrand={editBrand}
      />
    ),
    [brands]
  );

  return (
    <div className="row">
      <div className="col-md-8">
        <div className="card card-cat">
          {/* <button className="btn btn-cannaby btn-cat" onClick={() => setAddBrandComponent()}>
            <i className="fas fa-plus-circle"></i>
            Add Manufacturer
          </button> */}
          <div className="row" style={{ borderRadius: "13px" }}>
            <div className="col-md-12">
              <div className="row">
                <div
                  style={{
                    height: "25px",
                    marginLeft: "-32px",
                    backgroundColor: "#FFA382",
                    marginTop: "5px",
                  }}
                >
                  <span style={{ color: "#FFA382" }}>.</span>
                </div>
                <div className="col-md-7 d-flex">
                  <div style={{ color: "#FFA382", fontSize: "20px" }}>
                    Manufacturers
                  </div>
                  {!admin[0].isSubOutlet && (
                    <div
                      className="input-group"
                      style={{
                        width: "100%",
                      }}
                    >
                      <select
                        style={{
                          marginLeft: "10px",
                        }}
                        className="newInputSelect w-75"
                        onChange={(e) => GetBrandById(e.target.value)}
                        id="exampleFormControlSelect1"
                      >
                        <option value="select">Select Outlet</option>
                        {outlets.map((outlet, i) => {
                          return (
                            <option value={outlet.outletChainID} key={i}>
                              {outlet.outletName}
                            </option>
                          );
                        })}
                      </select>
                      {outletID === "" ? (
                        <p className="pl-3" style={{ color: "red" }}>
                          Please select outlet
                        </p>
                      ) : null}
                    </div>
                  )}
                </div>

                <div className="col-md-5">
                  <div className="input-group">
                    <input
                      type="text"
                      style={{ borderRight: "none" }}
                      className="newInput"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      placeholder="Search Manufacturers ..."
                      aria-label="Input group example"
                      aria-describedby="btnGroupAddon"
                    />
                    <div className="input-group-prepend">
                      <div
                        className="input-group-text newSearchIcon"
                        id="btnGroupAddon"
                      >
                        <i
                          style={{ color: colors.searchColor }}
                          className="fas fa-search"
                        ></i>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div className="col-md-1 pagination" style={{ justifyContent: "space-evenly" }}>
                  <img src={sort} />


                </div> */}
              </div>
            </div>
            <div
              className="col-md-12"
              style={{
                padding: "0px",
                marginTop: "-1em",
              }}
            >
              {Tablenew}
              <div
                className="d-flex col-md-12"
                style={{
                  justifyContent: "flex-end",
                  marginTop: "5px",
                  marginLeft: "20px",
                }}
              >
                <div
                  style={{
                    backgroundColor: "white",
                    borderRadius: "5px",
                    padding: "6px",
                  }}
                >
                  <Pagination
                    className={classes.root}
                    onChange={(e, value) => {
                      handleChange("page", value);
                    }}
                    page={paginationData.currentPage}
                    count={paginationData.totalPages ?? 0}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-4">
        {showComponent && (
          <div className="card">
            <div
              style={{
                position: "fixed",
                right: "10px",
                width: "26%",
                height: "74vh",
                borderRadius: "5px",
              }}
            >
              <div
                className="d-flex"
                style={{ justifyContent: "space-between" }}
              >
                <div
                  className="d-flex"
                  style={{ justifyContent: "flex-start" }}
                >
                  <div
                    style={{
                      // position: "absolute",
                      height: "40px",
                      marginLeft: "-32px",
                      backgroundColor: "#FFA382",
                    }}
                  >
                    <span style={{ color: "#FFA382" }}>a</span>
                  </div>
                  <h4 style={{ marginLeft: "5px", marginTop: "5px" }}>
                    {editBoolean ? "Edit Manafacturer" : "Add Manafacturer"}
                  </h4>
                </div>
                <div>
                  <button
                    className="btn close-btn latestCross"
                    onClick={closeComp}
                  >
                    <i style={{ color: "white" }} className="fas fa-times"></i>
                  </button>
                </div>
              </div>

              <br></br>
              <form onSubmit={(e) => e.preventDefault()}>
                <div className="group-comb">
                  <div className="form-group" style={{ marginBottom: "20px" }}>
                    <label>Manufacturer Name</label>
                    <input
                      // disabled
                      type="text"
                      value={name}
                      disabled={true}
                      onChange={(e) => setName(e.target.value)}
                      className="form-control"
                      id="exampleFormControlInput1"
                      placeholder="name"
                      // disabled
                    />
                  </div>
                </div>

                <div className="group-comb">
                  <div
                    className=" img-drop"
                    style={{
                      borderRadius: "3px !important",
                      backgroundColor: "white",
                      border: "1px solid #E2E8F0",
                    }}
                  >
                    {showImage ? (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          height: "100%",
                          justifyContent: "space-evenly",
                        }}
                      >
                        <div style={{ width: "15%" }}></div>
                        <div style={{ marginTop: "20px" }}>
                          <img
                            style={{ alignSelf: "center" }}
                            alt=""
                            width="150"
                            height="120"
                            src={image}
                            className="rounded mx-auto "
                          ></img>
                        </div>
                        <label className="" style={{ border: "none" }}>
                          <input
                            type="file"
                            id="image"
                            name="Image"
                            onChange={imageSelector}
                            accept="image/png, image/jpeg"
                          />
                          <div
                            style={{
                              color: "#FFA382",
                              justifyContent: "flex-end",
                            }}
                          >
                            <i
                              style={{ color: "#FFA382", marginRight: "3px" }}
                              className="fas fa-pen"
                            ></i>
                            <u>Edit</u>
                          </div>
                        </label>
                      </div>
                    ) : (
                      <>
                        <div
                          style={{
                            width: "30%",
                            height: "auto",
                            margin: " 0 auto",
                            padding: "10px",
                            position: "relative",
                          }}
                        >
                          <img
                            style={{
                              width: "100%",
                              height: "40px",
                              margin: " 0 auto",
                              position: "relative",
                            }}
                            src={upload}
                          />
                        </div>

                        <p style={{ textAlign: "center" }}>
                          Upload Category Image
                          <p
                            style={{
                              fontSize: "11px",
                              color: "rgba(116, 116, 116, 0.5)",
                            }}
                          >
                            Files include PNG, JPEG
                          </p>
                        </p>
                        <label
                          className="custom-file-upload"
                          style={{ border: "none" }}
                        >
                          <input
                            type="file"
                            id="image"
                            name="Image"
                            onChange={imageSelector}
                            accept="image/png, image/jpeg"
                          />
                          <p
                            style={{
                              backgroundColor: "#FFA382",
                              border: "1px solid #FFA382",
                              width: "60%",
                              color: "white",
                              borderRadius: "5px",
                              paddingLeft: "2em",
                              paddingRight: "2em",
                              paddingTop: "0.5em",
                              paddingBottom: "0.5em",
                              marginLeft: "auto",
                              marginRight: "auto",
                              textAlign: "center",
                            }}
                          >
                            Choose File
                          </p>
                        </label>
                      </>
                    )}

                    {showCrop && (
                      <Dialog
                        fullScreen={fullScreen}
                        open={open}
                        fullWidth={true}
                        onClose={() => setOpen(false)}
                        aria-labelledby="responsive-dialog-title"
                      >
                        <div
                          className="App"
                          style={{
                            padding: "1em",
                            width: "auto",
                            height: "auto",
                          }}
                        >
                          <div className="crop-container">
                            <Cropper
                              image={image}
                              crop={crop}
                              zoom={zoom}
                              aspect={4 / 3}
                              onCropChange={setCrop}
                              onCropComplete={onCropComplete}
                              onZoomChange={setZoom}
                            />
                          </div>
                          <div className="controls">
                            <Slider
                              value={zoom}
                              min={1}
                              max={3}
                              step={0.1}
                              aria-labelledby="Zoom"
                              onChange={(e, zoom) => setZoom(zoom)}
                              // classes={{ root: "slider" }}
                            />
                          </div>
                          <div>
                            <button
                              className="btn btn-primary crop-btn"
                              onClick={(e) => showCroppedImage(e)}
                              style={{
                                textAlign: "center",
                                width: "auto",
                                padding: "10px 30px",
                              }}
                            >
                              Crop
                            </button>
                          </div>
                        </div>
                      </Dialog>
                    )}
                  </div>
                </div>
                {/* <div className="group-comb" style={{ borderRadius: "3px !important", backgroundColor: "white" }}>
                  <div className=" img-drop" style={{ borderRadius: "3px !important", backgroundColor: "white", border: "1px solid #E2E8F0" }}>
                    {

                      <>
                        <div style={{
                          width: "30%", height: "auto",
                          margin: " 0 auto",
                          padding: "10px",
                          position: "relative",
                        }}>
                          <img
                            style={{
                              width: "100%", height: "40px",
                              margin: " 0 auto",
                              position: "relative",

                            }}
                            src={upload}
                          />
                        </div>

                        <p style={{ textAlign: "center" }}>
                          Upload Product Image
                          <p style={{ fontSize: "11px", color: "rgba(116, 116, 116, 0.5)" }}>
                            Files include PNG, JPEG
                          </p>
                        </p>
                        <label className="custom-file-upload" style={{ border: "none" }}>


                          <p
                            style={{
                              backgroundColor: "#FFA382",
                              border: "1px solid #FFA382",
                              width: "60%",
                              color: "white",
                              borderRadius: "5px",
                              paddingLeft: "2em",
                              paddingRight: "2em",
                              paddingTop: "0.5em",
                              paddingBottom: "0.5em",
                              marginLeft: "auto", marginRight: "auto"
                            }}
                          >
                            Choose File
                          </p>
                        </label>
                      </>
                    }
                    {showCrop && (
                      <Dialog
                        fullScreen={fullScreen}
                        open={open}
                        fullWidth={true}
                        onClose={() => setOpen(false)}
                        aria-labelledby="responsive-dialog-title"
                      >
                        <div
                          className="App"
                          style={{
                            padding: "1em",
                            width: "auto",
                            height: "auto",
                          }}
                        >
                          <div className="crop-container">
                            <Cropper
                              image={image}
                              crop={crop}
                              zoom={zoom}
                              aspect={4 / 3}
                              onCropChange={setCrop}
                              onCropComplete={onCropComplete}
                              onZoomChange={setZoom}
                            />
                          </div>
                          <div className="controls">
                            <Slider
                              value={zoom}
                              min={1}
                              max={3}
                              step={0.1}
                              aria-labelledby="Zoom"
                              onChange={(e, zoom) => setZoom(zoom)}
                            // classes={{ root: "slider" }}
                            />
                          </div>
                          <div>
                            <button
                              className="btn btn-primary crop-btn"
                              onClick={(e) => showCroppedImage(e)}
                              style={{
                                textAlign: "center",
                                width: "auto",
                                padding: "10px 30px",
                              }}
                            >
                              Crop
                            </button>
                          </div>
                        </div>
                      </Dialog>
                    )}
                  </div>
                </div> */}
                <div className="group" style={{ marginTop: "3px" }}>
                  {editBoolean && (
                    <button
                      type="button"
                      onClick={fileUpdate}
                      class="btn btn-primary "
                      style={{
                        marginTop: "20px",
                        backgroundColor: "#FFA382",
                        border: "none",
                        width: "100%",
                      }}
                    >
                      Update
                    </button>
                  )}
                  {/* {editBoolean && <button type="button" onClick={deleteBrand} className="btn btn-danger m-2"><span><i className="fa fa-trash-alt"></i></span></button>} */}
                  {/* {!editBoolean && (
                    <button
                      type="button"
                      onClick={fileUpload}
                      class="btn btn-primary m-2">
                      Publish
                    </button>
                  )} */}
                </div>
                {editBoolean ? (
                  <p className="createdOn">
                    {/* Created on{" "}
                    {moment(createdDAte).format(" MMM. Do YYYY, h:mm A")}{" "} */}
                  </p>
                ) : (
                  ""
                )}

                {/* {!editBoolean && (
                  <div>
                    <form class="md-form">
                      <div className="file-field">
                        <div className="btn btn-primary btn-sm float-left">
                          <label>
                            <input
                              type="file"
                              id="excel"
                              name="excel"
                              onChange={ExcelFileUpload}
                              accept=".xls, .xlsx"
                            />
                            <span className="mr-2">Select Excel File</span>
                            <i className="fas fa-pen"></i>
                          </label>
                        </div>
                        {convertedJson.length !== 0 && (
                          <div
                            className="btn btn-primary"
                            onClick={uploadMultipleData}>
                            Upload Data
                          </div>
                        )}
                      </div>
                    </form>
                  </div>
                )} */}
              </form>
            </div>
          </div>
        )}
      </div>
      {invokeLogOut && <InvokeLayoutComponent />}
      <Popup
        ref={notFoundRef}
        position="center"
        onClose={() => {
          notFoundRef.current.close();
          setSearchTerm("");
        }}
      >
        <div style={{ textAlign: "center" }}>
          <i
            style={{
              color: "red",
              fontSize: "70px",
            }}
            // style={{
            //   color: "green",
            //   fontSize: "60px",
            //   border: "10px solid green",
            //   borderRadius: "50px",
            //   padding: "10px",
            // }}
            class="fa fa-ban"
          ></i>
          <h2 className="popup-heading" style={{ marginTop: "20px" }}>
            Failed
          </h2>
          <p>Brand(s) not found</p>
          <button
            className="btn btn-cannaby"
            style={{ marginTop: "0" }}
            onClick={() => {
              notFoundRef.current.close();
              setSearchTerm("");
            }}
          >
            OK
          </button>
        </div>
      </Popup>
    </div>
  );
};

export default Brands;
{
  /* {paginationData.totalPages !== 0 &&
                    paginationData.currentPage <= paginationData.totalPages && (
                      <Fragment>
                        <span>
                          {`${paginationData.perPage *
                            (paginationData.currentPage - 1) +
                            1
                            } - ${paginationData.perPage *
                            (paginationData.currentPage - 1) +
                            categories.length
                            } of ${paginationData.total ?? 0}`}
                        </span>
                        <div
                          className={`btn ${paginationData.currentPage <= 1
                            ? ""
                            : "cannaby-light"
                            }`}
                          onClick={() => {
                            if (!(paginationData.currentPage <= 1)) {
                              handleChange(
                                "page",
                                paginationData.currentPage - 1
                              );
                            }
                          }}
                          role="button"
                        >
                          <i className="fas fa-chevron-left"></i>
                        </div>
                        <div
                          onClick={() => {
                            if (
                              !(
                                paginationData.currentPage >=
                                paginationData.totalPages
                              )
                            ) {
                              handleChange(
                                "page",
                                paginationData.currentPage + 1
                              );
                            }
                          }}
                          className={`btn ${paginationData.currentPage >=
                            paginationData.totalPages
                            ? ""
                            : "cannaby-light"
                            }`}
                          role="button"
                        >
                          <i className="fas fa-chevron-right"></i>
                        </div>
                      </Fragment>
                    )} */
}
