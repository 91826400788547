// Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
import firebase from 'firebase';

const firebaseConfig = {
  apiKey: "AIzaSyB0F_jG_zTYQhF3-QlngiSOBM_LwJMCPpQ",
  authDomain: "bleaum-admin.firebaseapp.com",
  projectId: "bleaum-admin",
  storageBucket: "bleaum-admin.appspot.com",
  messagingSenderId: "782951941932",
  appId: "1:782951941932:web:04a57d09e4e1b39f954e89",
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
var storage = firebase.storage();

export default storage;