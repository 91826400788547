import { Fragment } from "react";

const getDottedString = (str) => {
  if ((str + "").length < 40) {
    return str;
  }
  return (str + "").substring(0, 40) + "...";
};

const TopicInfo = ({ topic }) => {
  return (
    <div
      style={{
        width: "auto",
        maxWidth: "390px",
        margin: "0.5em",
        display: "flex",
        height: "auto",
        flexDirection: "row",
        flexWrap: "wrap",
        backgroundColor: "#ffede7",
        borderRadius: "5px",
      }}
    >
      {topic?.image && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "0.5em",
          }}
        >
          <img src={topic.image} alt="topic_image" height={50} width={50} />
        </div>
      )}
      {(topic?.title || topic?.highlights) && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "0.1em",
            justifyContent: "center",
            alignItems: "flex-start",
            padding: "1em",
          }}
        >
          {topic?.title && (
            <span style={{ fontWeight: 500 }}>
              {getDottedString(topic?.title + "")}
            </span>
          )}
          {topic?.highlights && (
            <span>{getDottedString(topic?.highlights + "")}</span>
          )}
        </div>
      )}
    </div>
  );
};
const MessageTopic = ({ topic }) => {
  return (
    // <div
    //   style={{
    //     display: "flex",
    //     flexGrow: 1,
    //     justifyContent: "center",
    //     alignItems: "center",
    //   }}>
    //   <div
    //     style={{
    //       width: "auto",
    //       maxWidth: "390px",
    //       margin: "0.5em",
    //     }}>
    //     {topic?.link ? (
    //       <a
    //         href={topic?.link}
    //         target="_blank"
    //         alt=""
    //         rel="noreferrer"
    //         style={{
    //           fontSize: "100%",
    //           color: "#ffa382",
    //         }}>
    //         {getDottedString(topic?.link)}
    //       </a>
    //     ) : topic?.title ||
    //       topic?.highlights ||
    //       topic?.image ?
    //       <TopicInfo topic={topic} />
    //      : null}
    //   </div>
    // </div>
    <Fragment>
      {topic.link?.length ? (
        <div
          style={{
            display: "flex",
            flexGrow: 1,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: "auto",
              maxWidth: "390px",
              margin: "0.5em",
            }}
          >
            <a
              href={topic?.link}
              target="_blank"
              alt=""
              rel="noreferrer"
              style={{
                fontSize: "100%",
                color: "#ffa382",
              }}
            >
              {getDottedString(topic?.link)}
            </a>
          </div>
        </div>
      ) : null}
    </Fragment>
  );
};

export default MessageTopic;
