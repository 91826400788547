import { useState, useRef, useEffect, Fragment } from "react";
import url from "../../../config/axios";
import CircularProgress from "@mui/material/CircularProgress";
import { fileUpload } from "../../../helpers/firebaseFileUpload";
import calculateAspectRatio from "calculate-aspect-ratio";
import FileUpload from "../../../atoms/FileUpload/FileUpload";
import styled from "@emotion/styled";
import { Button, Grid } from "@mui/material";
import ConfirmationPopup from "../../../atoms/ConfirmationPopup/ConfirmationPopup";

const initialFormState = {
  mainLogoFile: null,
  mainLogoFileName: null,
  mainLogoFileSource: null,
  footerLogoFile: null,
  footerLogoFileName: null,
  footerLogoFileSource: null,
  faviconLogoFile: null,
  faviconLogoFileName: null,
  faviconLogoFileSource: null,
};

function MediasPageTajwar({ invokeLogout, setActionButtonFunctions }) {
  const [formData, setFormData] = useState({
    ...initialFormState,
  });
  const [errors, setErrors] = useState({
    faviconLogoError: null,
    mainLogoError: null,
    footerLogoError: null,
  });
  const allowedRatios = ["1:1"];

  /**
   *
   * @param {event} event Actual HTML file event
   * @param {string} target if target = "tom" => set"tom"FileName(filename) and set"tom"File(buffer) and set"tom"FileSource(sourceurl)
   */
  const imageSelector = (event, target) => {
    const allowedExtensions = ["png", "jpg", "jpeg", "gif", "webp"];
    if (event.target.files[0]) {
      let file = event.target.files[0];
      // console.log("***File is:", file);
      const splitted = (event.target.files[0]?.name + "").split(".");
      const extension = splitted[splitted.length - 1] ?? null;
      if (allowedExtensions.includes(extension)) {
        var i = new Image();
        i.onload = () => {
          try {
            const aspectRatio = calculateAspectRatio(i.width, i.height);
            // console.log("ratio is: ", aspectRatio);
            if (allowedRatios.includes(aspectRatio + "")) {
              setErrors({
                ...errors,
                [`${target}Error`]: null,
              });
            } else {
              setErrors({
                ...errors,
                [`${target}Error`]: `Allowed ratio(s) : ${allowedRatios.join(
                  ", "
                )}`,
              });
            }
          } catch (e) {
            setErrors({
              ...errors,
              [`${target}Error`]: "Invalid format",
            });
          }
        };
        const objectUrl = URL.createObjectURL(file);
        setFormData({
          ...formData,
          [target + "FileName"]: file.name,
          [target + "File"]: file,
          [target + "FileSource"]: objectUrl,
        });
        i.src = objectUrl;
      }
    }
  };
  const [inProgress, setInProgress] = useState(true);
  const [previousData, setPreviousData] = useState({});
  const mainLogoRef = useRef(null);
  const footerLogoRef = useRef(null);
  const faviconLogoRef = useRef(null);
  const [confirmationPopupState, setConfirmationPopupState] = useState({
    show: false,
    text: "",
    error: false,
  });
  const [mainLogo, setMainLogo] = useState({ file: null, source: null });
  const [footerLogo, setFooterLogo] = useState({ file: null, source: null });
  const [favicon, setFavicon] = useState({ file: null, source: null });

  const updateMedias = async () => {
    const errorCheck =
      Object.keys(errors).filter((el) => !!errors[el]).length !== 0;
    if (errorCheck) {
      return;
    }
    setInProgress(true);
    let currentMainLogoFileSource = previousData.mainLogoFileSource;
    let currentFooterLogoFileSource = previousData.footerLogoFileSource;
    let currentFaviconLogoFileSource = previousData.faviconLogoFileSource;

    if (formData.footerLogoFile) {
      let url = await fileUpload(formData.footerLogoFile);
      if (!!url) {
        currentFooterLogoFileSource = url;
      }
    } else {
      if (
        !formData.footerLogoFileSource ||
        formData.footerLogoFileSource.length === 0
      )
        currentFooterLogoFileSource = null;
    }
    if (formData.faviconLogoFile) {
      let url = await fileUpload(formData.faviconLogoFile);
      if (!!url) {
        currentFaviconLogoFileSource = url;
      }
    } else {
      if (
        !formData.faviconLogoFileSource ||
        formData.faviconLogoFileSource.length === 0
      )
        currentFaviconLogoFileSource = null;
    }
    if (formData.mainLogoFile) {
      let url = await fileUpload(formData.mainLogoFile);
      if (!!url) {
        currentMainLogoFileSource = url;
      }
    } else {
      if (
        !formData.mainLogoFileSource ||
        formData.mainLogoFileSource.length === 0
      )
        currentMainLogoFileSource = null;
    }
    const body = {
      faviconLogoFileSource: currentFaviconLogoFileSource,
      mainLogoFileSource: currentMainLogoFileSource,
      footerLogoFileSource: currentFooterLogoFileSource,
    };
    //sanity
    Object.keys(body).forEach((key) => {
      if (!body[key]) {
        delete body[key];
      }
    });
    await url
      .patch(`/v1/general-settings/media`, {
        ...body,
      })
      .then((res) => {
        let response = res.data?.data ?? {};
        if (res?.data?.Message === "Success") {
          updateLocalStorage(response);
          setConfirmationPopupState({
            show: true,
            text: "Updates Successfully",
            error: false,
          });
        }
        setFormData({
          ...initialFormState,
          faviconLogoFileSource: response?.faviconLogoFileSource ?? null,
          mainLogoFileSource: response?.mainLogoFileSource ?? null,
          footerLogoFileSource: response?.footerLogoFileSource ?? null,
        });
        setPreviousData({
          faviconLogoFileSource: response?.faviconLogoFileSource ?? "",
          mainLogoFileSource: response?.mainLogoFileSource ?? "",
          footerLogoFileSource: response?.footerLogoFileSource ?? "",
        });
        setInProgress(false);
      })
      .catch((e) => {
        if (+e?.response?.status === 401) {
          invokeLogout();
          setConfirmationPopupState({
            show: true,
            text: "Update Failed",
            error: true,
          });
        }
        setInProgress(false);
      });
  };

  /**
   *
   * @param {object} data media object
   */
  const updateLocalStorage = async (data) => {
    try {
      const prevData = await JSON.parse(localStorage.getItem("Admin"));
      if (Array.isArray(prevData)) {
        prevData[0] = { ...prevData[0], media: { ...data } };
        await localStorage.setItem("Admin", JSON.stringify(prevData));
        // window.location.reload();
      }
    } catch (e) {}
  };
  useEffect(
    () => {
      const adminID = JSON.parse(localStorage.getItem("Admin"));
      const outletChainID = adminID[0]?.outletChainID ?? null;
      url
        .get(`/v1/general-settings/media?outletChainID=${outletChainID}`)
        .then((res) => {
          let response = res.data?.data ?? {};
          setFormData({
            ...formData,
            faviconLogoFileSource: response?.faviconLogoFileSource ?? null,
            mainLogoFileSource: response?.mainLogoFileSource ?? null,
            footerLogoFileSource: response?.footerLogoFileSource ?? null,
          });
          setPreviousData({
            faviconLogoFileSource: response?.faviconLogoFileSource ?? "",
            mainLogoFileSource: response?.mainLogoFileSource ?? "",
            footerLogoFileSource: response?.footerLogoFileSource ?? "",
          });
          setInProgress(false);
        })
        .catch((e) => {
          setInProgress(false);
        });
    },
    //eslint-disable-next-line
    []
  );
  useEffect(() => {
    console.log("Final form data", formData);
  }, [formData]);
  useEffect(() => {
    setFormData({
      ...formData,
      mainLogoFile: mainLogo.file,
      mainLogoFileSource: mainLogo.source,
    });
  }, [mainLogo]);
  useEffect(() => {
    console.log("Footer Logo Updating", footerLogo);
    setFormData({
      ...formData,
      footerLogoFile: footerLogo.file,
      footerLogoFileSource: footerLogo.source,
    });
  }, [footerLogo]);
  useEffect(() => {
    setFormData({
      ...formData,
      faviconLogoFile: favicon.file,
      faviconLogoFileSource: favicon.source,
    });
  }, [favicon]);

  // if (setActionButtonFunctions) {
  //   setActionButtonFunctions({
  //     text: "Update",
  //     onClick: updateMedias
  //   })
  // }
  return (
    <div className="newSpace">
      <Container>
        {/* Main Logo */}
        <FileUpload
          label="Main Logo"
          onUpload={setMainLogo}
          file={formData.mainLogoFileSource}
        />
        {/* Footer Logo */}
        <FileUpload
          label="Footer Logo"
          onUpload={setFooterLogo}
          file={formData.footerLogoFileSource}
        />
        {/* Favicon */}
        <FileUpload
          label="Favicon"
          onUpload={setFavicon}
          file={formData.faviconLogoFileSource}
        />
      </Container>
      <Grid container justifyContent="flex-end">
        <Button
          variant="contained"
          onClick={updateMedias}
          disabled={inProgress}
        >
          Update
        </Button>
      </Grid>

      <ConfirmationPopup data={confirmationPopupState} />
    </div>
  );
}

const Container = styled.div`
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
`;
export default MediasPageTajwar;
