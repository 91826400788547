import { useState, useEffect } from "react";
import url from "../../config/axios";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import blank from "../../assets/defaultImage.jpg";
import { colors } from "../../Style.style";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import VariantPage from "../OutletAdminComponents/variants";
import InputPrimary from "../../atoms/InputPrimary/Index2";
function EditModal({
  closeModal,
  product,
  GetAllInventory,
  searchProduct,
  searchValue,
}) {
  const [name, setName] = useState("");
  const [image, setImage] = useState("");
  const [brand, setBrand] = useState("");
  const [stockQty, setStockQty] = useState(0);
  const [originalPrice, setOriginalPrice] = useState("");
  const [activeStatus, setActiveStatus] = useState(false);
  const [validationMessage, setValidationMessage] = useState({});
  const [track, setTrack] = useState(false);
  const [variantFlow, setVariantFlow] = useState("no-variant");
  const [variants, setVariants] = useState([]);
  const [validation, setValidation] = useState({});

  const validateForm = () => {
    let data = { ...validation };
    if (!stockQty) {
      data = { ...data, StockErr: "Quantity is required" };
    } else {
      let { StockErr, ...others } = data;
      data = { ...others };
    }
    // if (!originalPrice) {
    //   data = { ...data, PriceErr: "Price is required" };
    // } else {
    //   let { PriceErr, ...others } = data;
    //   data = { ...others };
    // }
    setValidation({ ...data });
    return data;
  };

  useEffect(() => {
    console.log(product, "product D:");
    if (product) {
      setStockQty(product.quantity);
      setOriginalPrice(product.originalPrice);
      setName(product.product.name);
      setBrand(product.product.brand);
      setImage(product.product.image);
      setActiveStatus(product.status);
      product.quantity > 0 ? setTrack(true) : setTrack(false);
      if (product?.variants?.length === 0) {
        setVariantFlow("no-variant");
      } else {
        setVariantFlow("variants");
      }
    }
  }, [product]);
  const MyRadio = styled((props) => <Radio color="default" {...props} />)({
    "&.Mui-checked": {
      color: "#FFA382",
    },
  });
  const updateInventory = () => {
    const Error = validateForm();
    console.log(Error, "Error");
    if (Object.keys(Error).length === 0) {
      let body = {
        outletChainID: product.outletChainID,
        consumerId: product.consumerId,
        productID: product.product.productID,
        quantity: stockQty,
        originalPrice: variantFlow === "variants" ? 0 : parseInt(originalPrice),
        status: activeStatus,
        variants: variantFlow === "variants" ? variants : [],
      };
      // console.log(body, "body");
      url
        .put(
          `/inventory/?productID=${product.product.productID}&outletChainID=${product.outletChainID}`,
          body
        )
        .then(async (res) => {
          updateProduct();
          // console.log(res, 'res')
          if (res.data.Message === "Failure") {
            // alert(res.data.data);
          } else {
            // GetAllInventory();
            // searchProduct(searchValue)
            // alert("Inventory Updated");
            closeModal(false);
          }
        })
        .catch((e) => console.log(e));
    }
  };

  const updateProduct = () => {
    url
      .put(`/product/?productID=${product.product.productID}`, {
        ...productDetails,
        variants: variantFlow === "variants" ? variants : [],
        price: variantFlow === "variants" ? 0 : originalPrice,
      })
      .then((res) => {
        console.log("res: ", res);
        console.log("product updated successfully");
      })
      .catch((e) => console.log(e));
  };

  const styles = {
    display: "block",
    // backgroundColor: "rgb(0,0,0)" /* Fallback color */,
    backgroundColor: "rgba(0,0,0,0.4)" /* Black w/ opacity */,
    fontFamily: "Roboto",
    overflowY: "scroll",
  };

  const title = {
    fontSize: "22px",
    fontWeight: "500",
    padding: "1rem 1rem",
  };

  const text1 = {
    fontSize: "16px",
    fontWeight: "500",
    color: "#1E293B",
  };

  const text2 = {
    fontSize: "12px",
    fontWeight: "400",
    color: "#1F6355",
  };

  const mark = {
    color: "#898989",
    background: "none",
  };

  const productDetails = {
    backgroundColor: "#E2E8F0",
    borderRadius: "7px",
  };
  const title2 = {
    fontFamily: "Roboto",
    fontSize: "20px",
    fontWeight: "700",
    color: "#000000",
  };
  const statusStyle = {
    color: "#747474",
    fontWeight: "500",
    fontSize: "14px",
    fontFamily: "Roboto",
    width: "100%",
  };
  const sliderColor = {
    backgroundColor: "#FFA382",
  };
  const modalBody = {
    padding: "1.2em",
  };
  const headingStyle = {
    color: "#1E293B",
    fontWeight: "700",
    fontSize: "20px",
  };
  const handleVariantChange = (e) => {
    setVariantFlow(e.target.value);
    if (e.target.value === "variants") {
      setVariantFlow("variants");
    } else {
      setVariantFlow("no-variants");
    }
  };

  const handleVariants = (e) => {
    console.log("variants data: ", e);
    setVariants(e);
  };

  return (
    <div className="modal" style={styles}>
      <div className="mdl modal-dialog modal-dialog-centered">
        <div
          className="modal-content"
          style={{ height: "33rem", overflowY: "scroll", overflowX: "hidden" }}
        >
          <div className="modal-header" style={title}>
            <div className="d-flex " style={{ justifyContent: "space-evenly" }}>
              <div
                style={{
                  height: "25px",
                  marginLeft: "-16px",
                  backgroundColor: "#FFA382",
                  marginTop: "5px",
                }}
              >
                <span style={{ color: "#FFA382" }}>.</span>
              </div>
              <span
                style={{
                  marginLeft: "5px",
                  marginTop: "5px",
                  color: "#FFA382",
                  fontWeight: "600",
                }}
              >
                Update inventory and product details
              </span>
            </div>
            <HighlightOffRoundedIcon
              style={{ cursor: "pointer" }}
              onClick={() => {
                closeModal(false);
              }}
            />
          </div>
          <div style={modalBody}>
            <div
              className="d-flex justify-content-start p-3"
              style={productDetails}
            >
              <img
                src={image ? image : blank}
                alt=""
                width="40px"
                height="40px"
              />
              <span className="mx-3">
                <p style={headingStyle} className="m-0 p-0">
                  {name}
                </p>
                <p style={text2} className="m-0 p-0">
                  <mark style={mark}>Brand:</mark> {brand?.name}
                </p>
              </span>
            </div>
            <p style={title2} className="my-3">
              Inventory Details
            </p>
            <div className="row">
              <div className="col-md-12">
                <InputPrimary
                  label={"Quantity in Stock"}
                  value={stockQty}
                  pattern="[0-9]*"
                  onInput={(e) => {
                    if (isNaN(e.target.value)) {
                      e.target.value = "";
                    } else {
                      e.target.value = Math.abs(e.target.value);
                    }
                  }}
                  onChange={(e) => {
                    setStockQty(e.target.value);
                    // setPhoneNoError("")
                  }}
                />
              </div>
              <div className="col-md-12">
                <span className="solo-chain">Track Inventory? </span>
              </div>
              <div className="col-md-6 switch-col">
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={track}
                    onChange={() => setTrack(!track)}
                  />
                  <span
                    className="slider round"
                    style={track ? sliderColor : null}
                  ></span>
                </label>
              </div>
            </div>
            <p style={title2} className="my-3">
              Billing Details
            </p>
            <div className="col-md-12">
              <FormControl
                style={{
                  marginTop: "-1em",
                }}
              >
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue={variantFlow}
                  name="radio-buttons-group"
                  onChange={(e) => handleVariantChange(e)}
                >
                  <div
                    style={{
                      display: "flex",
                    }}
                  >
                    <FormControlLabel
                      value="variants"
                      control={<MyRadio />}
                      label="Variants"
                    />
                    <FormControlLabel
                      value="no-variant"
                      control={<MyRadio />}
                      label="No Variant"
                    />
                  </div>
                </RadioGroup>
              </FormControl>
            </div>
            {variantFlow === "variants" ? (
              <VariantPage
                handleVariants={handleVariants}
                variantsData={product?.variants}
              />
            ) : (
              <div
                style={{
                  marginBottom: "1em",
                  width: "100%",
                }}
              >
                <div className="col-md-12">
                  <InputPrimary
                    label={"Product Price"}
                    type="text"
                    name="price"
                    placeholder="$ 25.00"
                    value={originalPrice}
                    pattern="[0-9]*"
                    onInput={(e) => {
                      if (
                        e.target.value?.toString()?.split(".")[1]?.length > 2
                      ) {
                        let value =
                          e.target.value.toString().split(".")[0] +
                          "." +
                          e.target.value
                            .toString()
                            .split(".")[1]
                            .substring(0, 2);

                        e.target.value = value;
                      }
                    }}
                    onChange={(e) => {
                      setOriginalPrice(e.target.value);
                    }}
                  />
                </div>
              </div>
            )}

            <div className="col-md-12">
              <p style={statusStyle} className="mt-5">
                Status
              </p>
              <div className="row" style={{ width: "100%" }}>
                <div className="col-md-8 switch-col">
                  <label className="switch  switch-demo">
                    <input
                      type="checkbox"
                      checked={activeStatus}
                      onChange={() => setActiveStatus(!activeStatus)}
                    />
                    <span
                      className="slider round"
                      style={activeStatus ? sliderColor : null}
                    ></span>
                    <span
                      className="slider-text"
                      style={
                        activeStatus
                          ? { paddingLeft: "81px", color: "#FFA382" }
                          : { paddingLeft: "81px", color: "red" }
                      }
                    >
                      {activeStatus ? "Enabled" : "Disabled"}
                    </span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              className="btn  btn-rnav"
              style={{ width: "15%" }}
              onClick={() => {
                closeModal(false);
              }}
            >
              Cancel
            </button>
            <button
              className="btn btn-cannaby"
              style={{ margin: "0", backgroundColor: colors.accent }}
              onClick={updateInventory}
            >
              Publish Changes
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditModal;
