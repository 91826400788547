import "./catalog.css";
import React from "react";
import { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import url from "../../../config/axios";
import blank from "../../../assets/defaultImage.jpg";
import circle from "../../../assets/circlePlus.svg";
import Pagination from "@mui/material/Pagination";

import AddInventory from "../../../components/OutletAdminComponents/AddInventory-Updated";
import ViewProductNew from "../../../components/OutletAdminComponents/ViewProduct-Updated";
import { makeStyles } from "@material-ui/core/styles";
import { serializeQueryStringsFromObject } from "../../../helpers/serializeToQueryStrings";
const useStyles = makeStyles({
  root: {
    "& .Mui-selected": {
      backgroundColor: "#FFA382 !important",
    },
    "& .MuiPaginationItem-root": {
      borderRadius: "5px !important",
    },
  },
});
export default function IndexUpdated() {
  const classes = useStyles();
  const history = useHistory();
  const [catalog, setCatalog] = useState([]);
  const [addModal, setAddModal] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(true);
  const admin = JSON.parse(localStorage.getItem("Admin"));
  // console.log(admin.isSubOutlet, "admin")
  const { search } = useLocation();

  const [openAddModal, setOpenAddModal] = useState(false);
  const [openViewModal, setOpenViewModal] = useState(false);
  var perPage = 10;
  const initialPaginationData = {
    page: 1,
    limit: 100,
    latestFirst: 1,
    search: "",
  };
  const [queryData, setQueryData] = useState({
    ...initialPaginationData,
  });
  const [parsed, setParsed] = useState(false);
  const [paginationData, setPaginationData] = useState({
    currentPage: 1,
    perPage: 30,
    totalPages: 0,
    total: 0,
  });
  useEffect(
    () => {
      const query = new URLSearchParams(search);

      let page =
        Number.isNaN(query.get("page")) || !query.get("page")
          ? 1
          : +query.get("page");
      let limit =
        Number.isNaN(query.get("limit")) || !query.get("limit")
          ? 100
          : +query.get("limit");
      let latestFirst =
        Number.isNaN(query.get("latestFirst")) || !query.get("latestFirst")
          ? 1
          : +query.get("latestFirst");
      let searchParams = query.get("search");
      let queryParams = {
        ...queryData,
        page,
        limit,
        latestFirst,
        search: searchParams,
      };
      setSearchTerm(searchParams);
      setQueryData({ ...queryParams });
      setParsed(true);
    },
    //eslint-disable-next-line
    []
  );
  useEffect(
    () => {
      if (parsed) {
        getAllPaginatedProducts({ ...queryData });
      }
    },
    //eslint-disable-next-line
    [parsed, queryData]
  );

  useEffect(() => {
    getAllPaginatedProducts();
  }, []);

  const getAllPaginatedProducts = () => {
    try {
      let queryParams = { ...queryData };
      //sanity
      Object.keys(queryParams).forEach((key) => {
        if (!queryParams[key]) {
          delete queryParams[key];
        }
      });
      url
        .get(`/product/paginated/`, {
          params: {
            ...queryParams,
          },
        })
        .then(async (res) => {
          // console.log(res, "paginated");
          setCatalog(res.data.data.products);
          setPaginationData({
            ...paginationData,
            ...res?.data.data?.paginationData,
          });
          setLoading(false);
        })
        .catch((e) => console.log(e));
    } catch (e) {
      console.log(e, "errror of pagination  ");
    }
  };

  const handleChange = (propName, propValue, flush = false) => {
    let query = {};
    console.log(propName);
    console.log(propValue);
    if (flush) {
      query = {
        ...initialPaginationData,
        [propName]: propValue,
      };
    } else {
      query = { ...queryData, [propName]: propValue };
    }
    const queryString = serializeQueryStringsFromObject(query);
    history.push(`/catalog-outlet${queryString}`);
    setQueryData({ ...query });
  };
  const setViewDetails = (product) => {
    setViewModal(!viewModal);
    setSelectedProduct(product);
  };

  const SearchByProduct = (value) => {
    console.log(value, "value search");
    if (value === "") {
      getAllPaginatedProducts();
    } else {
      url
        .get(
          `/product/search/?outletChainID=${admin[0].outletChainID}&name=${value}`
        )
        .then(async (res) => {
          // console.log(res, "response:");
          if (res.data.Message === "Success") {
            setCatalog(res.data.data);
          } else {
            setCatalog([]);
          }
        });
    }
  };

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="card">
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div
                  style={{
                    height: "25px",
                    marginLeft: "-32px",
                    backgroundColor: "#FFA382",
                    marginTop: "5px",
                  }}
                >
                  <span style={{ color: "#FFA382" }}>.</span>
                </div>
                <div className={"col-md-2 d-flex"}>
                  <div
                    style={{
                      color: "#FFA382",
                      fontSize: "20px",
                      fontWeight: "600",
                    }}
                  >
                    Catalog
                  </div>
                </div>
                <div className="col-md-4 filters-bar"></div>
                <div className="col-md-6">
                  <div className="input-group">
                    {" "}
                    <input
                      type="text"
                      style={{ width: "90%" }}
                      className="newInput"
                      placeholder="Search product by name"
                      aria-label="Input group example"
                      aria-describedby="btnGroupAddon"
                      onChange={(e) => SearchByProduct(e.target.value)}
                    />
                    <div className="input-group-prepend">
                      <div
                        className="input-group-text newSearchIcon"
                        id="btnGroupAddon"
                      >
                        <i className="fas fa-search"></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <table className="table invtable table-striped-cust">
                    <thead>
                      <tr>
                        <th scope="col">Name</th>
                        <th scope="col"></th>
                        <th scope="col"></th>
                        <th scope="col"></th>

                        <th scope="col"></th>
                        {/* <th scope="col"></th> */}

                        <th scope="col">Brand</th>
                        <th scope="col"></th>
                        <th scope="col"></th>
                        <th scope="col"></th>

                        <th scope="col">Category</th>
                        <th scope="col"></th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {catalog?.map((cat, i) => {
                        return (
                          <tr key={i}>
                            <td colSpan="5">
                              <div className="d-flex">
                                <img
                                  img
                                  alt="yo1"
                                  src={cat.image ? cat.image : blank}
                                  className="img-fluid "
                                  width="40"
                                  style={{ borderRadius: "5px" }}
                                />
                                {cat.name}
                              </div>
                            </td>
                            <td colSpan="4" className="adminName">
                              {cat?.brandProduct ? cat.brandProduct : "N/A"}{" "}
                              <br /> <span></span>
                            </td>
                            <td>
                              {cat?.categoryProduct
                                ? cat.categoryProduct
                                : "N/A"}
                            </td>
                            <td>
                              {admin[0].isSubOutlet ? (
                                <button
                                  className="btn globalButton"
                                  onClick={() => {
                                    setSelectedProduct(cat);
                                    setOpenAddModal(true);
                                  }}
                                >
                                  <img alt="dashboard" src={circle} /> Add to
                                  inventory
                                </button>
                              ) : (
                                <button
                                  className="btn globalButton"
                                  onClick={() => {
                                    console.log("working");
                                    setSelectedProduct(cat);
                                    setOpenAddModal(true);
                                  }}
                                >
                                  <img alt="dashboard" src={circle} /> Add to
                                  inventory
                                </button>
                              )}
                            </td>
                            <td className="status">
                              <button
                                style={{
                                  borderColor: "#FFA382",
                                  backgroundColor: "white",
                                  color: "#FFA382",
                                }}
                                onClick={() => {
                                  setViewDetails(cat);
                                  setOpenViewModal(true);
                                }}
                                className="btn btn-transparent"
                              >
                                <i className="fa fa-eye"></i> View Product
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loading ? null : (
        <div
          className="d-flex col-md-12"
          style={{
            justifyContent: "flex-end",
            marginTop: "5px",
            marginLeft: "13px",
          }}
        >
          <div
            style={{
              backgroundColor: "white",
              borderRadius: "5px",
              padding: "6px",
            }}
          >
            <Pagination
              shape="square"
              className={classes.root}
              onChange={(e, value) => {
                handleChange("page", value);
              }}
              page={paginationData.currentPage}
              count={paginationData.totalPages ?? 0}
            />
          </div>
        </div>
      )}
      {openViewModal && (
        <ViewProductNew
          selectedProduct={selectedProduct}
          setViewModal={setOpenViewModal}
        />
      )}
      {openAddModal && <AddInventory setAddModal={setOpenAddModal} />}
    </div>
  );
}
