import * as React from "react";

import Stack from "@mui/material/Stack";
import MuiTextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { styled as muiStyled } from "@mui/material/styles";
import styled from "@emotion/styled";
import { DateTimePicker, MobileDateTimePicker } from "@mui/x-date-pickers";

export default function DatePicker({ label, value, onChange, minDate }) {
  const [isPortrait, setIsPortrait] = React.useState(
    window.innerWidth < window.innerHeight ? true : false
  );
  //choose the screen size
  const handleResize = () => {
    if (window.innerWidth < window.innerHeight) {
      setIsPortrait(true);
    } else {
      setIsPortrait(false);
    }
  };

  React.useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Wrapper>
      <label>{label}</label>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        {!isPortrait ? (
          <DateTimePicker
            style={{ width: "100%" }}
            value={value}
            onChange={onChange}
            minDate={minDate}
            renderInput={(params) => <TextField {...params} />}
          />
        ) : (
          <MobileDateTimePicker
            style={{ width: "100%" }}
            value={value}
            onChange={onChange}
            minDate={minDate}
            renderInput={(params) => <TextField {...params} />}
          />
        )}
      </LocalizationProvider>
    </Wrapper>
  );
}

const TextField = muiStyled(MuiTextField)(({ theme }) => ({
  border: "1px solid #E2E8F0",
  borderRadius: "5px",
}));
const Wrapper = styled.div`
  label {
    color: #747474;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1rem;
    display: block;
  }
`;
