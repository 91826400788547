/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import url from "../../config/axios";
import { useHistory } from "react-router-dom";
import storage from "../../config/firebase";
import Popup from "reactjs-popup";
import calculateAspectRatio from "calculate-aspect-ratio";
import { ProductPicker } from "../../components/Product-Picker/index-updated"
import Box from "@mui/material/Box";
import { Fragment } from "react";

export default function AddBannerV1() {
    const history = useHistory();
    const [bannerTitle, setBannerTitle] = useState("");
    const [image, setImage] = useState("");
    const [time, setTime] = useState("");
    const [file, setFile] = useState("");
    // const [link, setLink] = useState("");
    const [status, setStatus] = useState(false);
    const [outlets, setOutlets] = useState([]);
    const [outletId, setOutletId] = useState("");
    const [inProgress, setInProgress] = useState(false);
    const [resolutionError, setResolutionError] = useState(null);
    const [promotions, setPromotions] = useState("")

    const allowedRatios = ["5:2"];
    // const [validationMessages, setvalidationMessages] = React.useState({
    //   title: "",
    //   link: "",
    //   time: 0,
    //   image: "",
    // });
    const [message, setMessage] = useState({
        display: false,
        status: false,
        text: "",
    });

    const ref = useRef();
    const openTooltip = () => ref.current.open();
    const closeTooltip = () => {
        try {
            ref.current.close();
            if (message.text === "Success") {
                history.push("/banner");
            }
        } catch (e) {
            //ignore
        }
    };

    const admin = JSON.parse(localStorage.getItem("Admin"));

    useEffect(() => {
        if (!admin[0].isSubOutlet) {
            GetAllOutlets();
        } else {
            setOutletId(admin[0]?.outletChainID)
        }
    }, []);

    const addBanner = (blob) => {
        let body = {
            consumerId: admin[0].consumerId,
            outletChainID: outletId,
            title: bannerTitle,
            image: blob,
            // link: link,
            time: time,
            status: status,
            targetInfo: { ...pickedProducts[Object.keys(pickedProducts)[0]], type: promotions },
        };
        if (admin[0].isSubOutlet) {
            body.outletChainID = admin[0].outletChainID;
        }
        url
            .post(`/banner/`, body)
            .then(async (res) => {
                setInProgress(false);
                if (res.data.Message === "Success") {
                    // alert("Banner Added");
                    setMessage({
                        text: "Success",
                        status: true,
                        display: true,
                    });
                    openTooltip();
                    // history.push("/banner");
                } else {
                    setMessage({
                        text: "Failed",
                        status: true,
                        display: true,
                    });
                    openTooltip();
                }
            })
            .catch((e) => {
                console.error(e);
                setInProgress(false);
            });
    };
    // &&
    // resolutionError === null &&
    // Object.keys(pickedProducts).length !== 0
    const fileUpload = () => {
        // const storageRef = ref(storage, `images/${file.name}`);
        // console.log(image);
        setTriedBefore(true);
        const gotError = validateForm();
        if (
            Object.keys(gotError).length === 0
        ) {
            setInProgress(true);
            if (file !== "") {
                const uploadTask = storage.ref(`images/${file.name}`).put(file);

                uploadTask.on(
                    "state_changed",
                    (snapshot) => {
                        // const progress = Math.round(
                        //   (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                        // );
                    },
                    (error) => {
                        console.error(error);
                    },
                    () => {
                        storage
                            .ref("images")
                            .child(file.name)
                            .getDownloadURL()
                            .then((blob) => {
                                // console.log("Uploaded blob: ", blob);
                                addBanner(blob);
                            });
                    }
                );
            } else {

                addBanner("")
            }
        }
        else {
            console.log("error")
        }
    };

    const [productPickerOpen, setProductPickerOpen] = useState(false);
    const imageSelector = async (event) => {
        const allowedExtensions = ["png", "jpg", "jpeg", "gif", "webp"];

        if (event.target.files[0]) {
            // console.log(event);
            const splitted = (event.target.files[0]?.name + "").split(".");
            const extension = splitted[splitted.length - 1] ?? null;
            if (allowedExtensions.includes(extension)) {
                var i = new Image();
                i.onload = () => {
                    try {
                        const aspectRatio = calculateAspectRatio(i.width, i.height);
                        console.log("ratio is: ", aspectRatio);
                        if (allowedRatios.includes(aspectRatio + "")) {
                            setResolutionError(null);
                        } else {
                            setResolutionError(
                                `Allowed ratio(s) : ${allowedRatios.join(", ")}`
                            );
                        }
                    } catch (e) {
                        setResolutionError(`Invalid format`);
                    }
                };
                const objectURL = URL.createObjectURL(event.target.files[0]);
                i.src = objectURL;
                setImage(objectURL);
                setFile(event.target.files[0]);
            }
        }
    };

    const GetAllOutlets = () => {
        url
            .get(`/oc/?consumerId=${admin[0].consumerId}`)
            .then(async (res) => {
                console.log('res', res)
                if (res.data.Message === "Success") {
                    setOutlets(res.data.data);
                } else {
                    alert(res.data.data);
                }
            })
            .catch((e) => console.log(e));
    };

    const [formValidationErrors, setFormValidationErrors] = useState({});
    const validateForm = () => {
        let data = { ...formValidationErrors };
        //manipulate banner title
        if (bannerTitle.trim().length === 0) {
            data = { ...data, btError: "Title of the banner is required" };
        } else {
            let { btError, ...others } = data;
            data = { ...others };
        }

        // manipulate image
        if (image === "") {
            data = { ...data, imgError: "Image of the banner is required" };
        } else {
            let { imgError, ...others } = data;
            data = { ...others };
        }

        setFormValidationErrors({ ...data });
        return data;
    };
    const [triedBefore, setTriedBefore] = useState(false);
    useEffect(() => {
        if (triedBefore) {
            validateForm();
        }
    }, [
        bannerTitle,
        image,
        triedBefore,
    ]);
    const [pickedProducts, setPickedProducts] = useState({});

    return (
        <div>
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div>
                            <div className="col-md-12">
                                <h5>Banner Details</h5>
                                <br></br>
                            </div>

                            <div className="col-md-12">
                                {!admin[0].isSubOutlet && (
                                    <div className="group-comb">
                                        <div className="input-group">
                                            <select
                                                className="form-control"
                                                onChange={(e) => setOutletId(e.target.value)}
                                                id="exampleFormControlSelect1">
                                                <option value="select">Select Outlet</option>
                                                {outlets.map((outlet, i) => {
                                                    return (
                                                        <option value={outlet.outletChainID} key={i}>
                                                            {outlet.outletName}
                                                        </option>
                                                    );
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                )}
                            </div>

                            <div className="col-md-12">
                                <div className="group-comb">
                                    <div className="form-group">
                                        <label>Banner Title*</label>
                                        <input
                                            type="text"
                                            value={bannerTitle}
                                            onChange={(e) => setBannerTitle(e.target.value)}
                                            className="form-control"
                                            placeholder="Banner Title"
                                        />
                                    </div>
                                    {formValidationErrors.btError ? (
                                        <span className="validation-help">
                                            {formValidationErrors.btError}
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                            </div>

                            <div className="col-md-12">
                                <div className="group-comb">
                                    <div className="form-group">
                                        <label>Banner Duration*</label>
                                        <input
                                            type="number"
                                            value={time}
                                            onChange={(e) =>
                                                setTime(e.target.value ? Math.abs(e.target.value) : "")
                                            }
                                            className="form-control"
                                            placeholder="Banner Duration(seconds)"
                                        />
                                    </div>
                                    {formValidationErrors.timeError ? (
                                        <span className="validation-help">
                                            {formValidationErrors.timeError}
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="group-comb">
                                    <div className="input-group">
                                        <select
                                            className="form-control"
                                            style={{ width: "100%" }}
                                            name="state"
                                            defaultValue={"Select Promotion"}
                                            onChange={(e) => {
                                                setPromotions(e.target.value)
                                            }}

                                        >
                                            <option value="">
                                                {promotions === "" ?
                                                    "Select Promotion"
                                                    : null}
                                            </option>
                                            <option value={"CATEGORIES"}>
                                                Categories
                                            </option>
                                            <option value={"MANUFACTURERS"}>
                                                Manufacturers
                                            </option>
                                            <option value={"DEALS"}>
                                                Deals
                                            </option>
                                            <option value={"PRODUCTS"}>
                                                Products
                                            </option>
                                        </select>
                                    </div>
                                </div>

                                {formValidationErrors.pmError ? (
                                    <span className="validation-help">
                                        {formValidationErrors.pmError}
                                    </span>
                                ) : (
                                    ""
                                )}
                            </div>

                            <div className="col-md-12">
                                <div className="custom-file">
                                    <input
                                        type="file"
                                        className="custom-file-input"
                                        onChange={imageSelector}
                                        accept="image/*"
                                        id="customFileLang"
                                        lang="en"
                                    />
                                    <label
                                        className="custom-file-label"
                                        htmlFor="customFileLang"
                                        style={{
                                            borderRadius: "15px",
                                            overflow: "hidden",
                                        }}>
                                        Select Banner
                                    </label>
                                </div>
                                {formValidationErrors.imgError ? (
                                    <span className="validation-help">
                                        {formValidationErrors.imgError}
                                    </span>
                                ) : (
                                    ""
                                )}
                                {resolutionError ? (
                                    <span className="validation-help">{resolutionError}</span>
                                ) : (
                                    ""
                                )}
                            </div>

                            {image !== "" && (
                                <div className="col-md-12">
                                    <div className="">
                                        <img
                                            src={image}
                                            style={{ width: "100%", height: "auto" }}
                                            alt="selectedImage"
                                        />
                                    </div>
                                </div>
                            )}
                            {/*  Products */}
                            {promotions === "PRODUCTS" && <div className="col-md-12" style={{ marginLeft: "-10px" }} >
                                <Box
                                >
                                    {Object.keys(pickedProducts).length ? (
                                        <Fragment>
                                            {Object.keys(pickedProducts).map((value, i) => (
                                                <Fragment>
                                                    <div>
                                                        <span>Selected product</span>

                                                        <h6
                                                            class="card-subtitle mb-2 text-muted"
                                                            style={{ paddingTop: "1em" }}>
                                                            {" "}
                                                            {pickedProducts[value].product.name}
                                                        </h6>
                                                        <p>
                                                            <b>Brand: </b>
                                                            {pickedProducts[value].product?.brandName ??
                                                                "Not provided"}
                                                        </p>
                                                    </div>
                                                </Fragment>
                                            ))}
                                        </Fragment>
                                    ) : (
                                        <p>No product(s) selected</p>
                                    )}
                                </Box>
                            </div>}
                            {/* deals */}
                            {promotions === "DEALS" && <div className="col-md-12" style={{ marginLeft: "-10px" }} >
                                <Box
                                >
                                    {Object.keys(pickedProducts).length ? (
                                        <Fragment>
                                            {Object.keys(pickedProducts).map((value, i) => (
                                                <Fragment>
                                                    <div>
                                                        <span>Selected Deal</span>

                                                        <h6
                                                            class="card-subtitle mb-2 text-muted"
                                                            style={{ paddingTop: "1em" }}>
                                                            {" "}
                                                            {pickedProducts[value].name}
                                                        </h6>

                                                    </div>
                                                </Fragment>
                                            ))}
                                        </Fragment>
                                    ) : (
                                        <p>No deal(s) selected</p>
                                    )}
                                </Box>
                            </div>}
                            {/* Brands */}
                            {promotions === "MANUFACTURERS" && <div className="col-md-12" style={{ marginLeft: "-10px" }} >
                                <Box
                                >
                                    {Object.keys(pickedProducts).length ? (
                                        <Fragment>
                                            {Object.keys(pickedProducts).map((value, i) => (
                                                <Fragment>
                                                    <div>
                                                        <span>Selected Manufacturer</span>

                                                        <h6
                                                            class="card-subtitle mb-2 text-muted"
                                                            style={{ paddingTop: "1em" }}>
                                                            {" "}
                                                            {pickedProducts[value].name}
                                                        </h6>

                                                    </div>
                                                </Fragment>
                                            ))}
                                        </Fragment>
                                    ) : (
                                        <p>No Manufacturer(s) selected</p>
                                    )}
                                </Box>
                            </div>}
                            {/* deals */}
                            {promotions === "CATEGORIES" && <div className="col-md-12" style={{ marginLeft: "-10px" }} >
                                <Box
                                >
                                    {Object.keys(pickedProducts).length ? (
                                        <Fragment>
                                            {Object.keys(pickedProducts).map((value, i) => (
                                                <Fragment>
                                                    <div>
                                                        <span>Selected Category</span>

                                                        <h6
                                                            class="card-subtitle mb-2 text-muted"
                                                            style={{ paddingTop: "1em" }}>
                                                            {" "}
                                                            {pickedProducts[value].name}
                                                        </h6>
                                                    </div>
                                                </Fragment>
                                            ))}
                                        </Fragment>
                                    ) : (
                                        <p>No Categorie(s) selected</p>
                                    )}
                                </Box>
                            </div>}


                            <div className="col-lg-12" style={{ marginLeft: "-20px" }}>
                                {promotions === "PRODUCTS" && <button
                                    type="button"
                                    onClick={() => setProductPickerOpen(true)}
                                    className="btn btn-info">

                                    <u>
                                        {!Object.keys(pickedProducts).length ? "Add Products" : "Change Product"}
                                    </u>
                                </button>
                                }
                                {promotions === "DEALS" && <button
                                    type="button"
                                    onClick={() => setProductPickerOpen(true)}
                                    className="btn btn-info">

                                    <u>
                                        {!Object.keys(pickedProducts).length ? "Add deals" : "Change deal"}
                                    </u>
                                </button>
                                }   {promotions === "MANUFACTURERS" && <button
                                    type="button"
                                    onClick={() => setProductPickerOpen(true)}
                                    className="btn btn-info">

                                    <u>

                                        {!Object.keys(pickedProducts).length ? "Add Manufacturers" : "Change Manufacturers"}
                                    </u>
                                </button>
                                }   {promotions === "CATEGORIES" && <button
                                    type="button"
                                    onClick={() => setProductPickerOpen(true)}
                                    className="btn btn-info">

                                    <u>

                                        {!Object.keys(pickedProducts).length ? "Add Categories" : "Change Categories"}
                                    </u>
                                </button>
                                }
                            </div>


                            <div className="col-md-12">
                                <h5 style={{ fontWeight: "normal", fontSize: "100%" }}>
                                    Status
                                </h5>
                            </div>
                            <div className="col-md-6 switch-col">
                                <label className="switch switch-demo">
                                    <input
                                        type="checkbox"
                                        checked={status}
                                        onChange={() => setStatus(!status)}
                                    />
                                    <span className="slider round"></span>
                                    <span className="slider-text" style={{ paddingLeft: "81px" }}>
                                        {status ? "Show" : "Hide"}
                                    </span>
                                </label>
                            </div>
                            <div className="col-lg-12">
                                <button
                                    type="button"
                                    onClick={fileUpload}
                                    disabled={inProgress}
                                    className="btn btn-cannaby"
                                    style={{ align: "center" }}>
                                    {inProgress ? "Creating..." : "Create Banner"}
                                </button>

                                <button
                                    style={{ marginLeft: "20px", marginTop: "30px" }}
                                    type="button"
                                    disabled={inProgress}
                                    onClick={() => history.push("/banner")}
                                    className="btn btn-rnav ">
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Popup ref={ref} position="center" onClose={closeTooltip}>
                {message.status ? (
                    <div style={{ textAlign: "center" }}>
                        <i
                            class="fa fa-check"
                            style={{
                                color: "green",
                                fontSize: "70px",
                            }}></i>
                        <h2 className="popup-heading" style={{ marginTop: "20px" }}>
                            Success
                        </h2>
                        <p>Banner added successfully</p>
                        <button
                            className="btn btn-cannaby"
                            style={{ marginTop: "0" }}
                            onClick={closeTooltip}>
                            OK
                        </button>
                    </div>
                ) : (
                    <div style={{ textAlign: "center" }}>
                        <i
                            style={{
                                color: "red",
                                fontSize: "70px",
                            }}
                            className="fa fa-ban"></i>
                        <h2 style={{ marginTop: "20px" }}>Failed</h2>
                        <button
                            style={{ marginTop: "10px" }}
                            type="button"
                            onClick={closeTooltip}
                            className="btn btn-primary">
                            Try Again
                        </button>
                    </div>
                )}
            </Popup>
            {productPickerOpen && (
                <ProductPicker
                    promotion={promotions}
                    handleClose={() => setProductPickerOpen(false)}
                    single={true}
                    previouslyChoosen={Object.keys(pickedProducts).map(
                        (el) => pickedProducts[el]
                    )}
                    outletId={outletId}
                    handleDone={(map) => {
                        setPickedProducts(map);
                        setProductPickerOpen(false);
                    }}
                />
            )}
        </div>
    )
}
