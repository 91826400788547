import React from 'react'
import styled from '@emotion/styled'
import { muiStyled } from '@mui/material/styles'
import ImageIcon from '../../assets/image_icon.svg'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { Button } from '@mui/material'


/**
 label: <String> Label name on the top of the button
 onUplaod: <(file) => {}> After user upload an image, it returns the actual file on Uplaod function
 file: <File, Blob> If there is an existing file, 
 */
function FileUpload({
    label,
    onUpload,
    file = null,
    allowedExtensions = ["png", "jpg", "jped", "svg", "webp", "gif", "tiff", "ico"]
}) {
    console.log("File", file)
    const fileInput = React.useRef()
    const [resultFile, setResultFile] = React.useState(file);
    const handleFileUplaod = (e) => {
        console.log(e.target.files)
    }
    React.useEffect(() => {
        setResultFile(file)
    }, [file])
    const handleFileDrop = (e) => {
        // console.log('File(s) dropped');
        // Prevent default behavior (Prevent file from being opened)
        e.preventDefault();
        if (e.dataTransfer.items) {
            if (e.dataTransfer.items[0].kind === 'file') {
                uploadFile(e.dataTransfer.items[0].getAsFile())
            }
        }
    }
    const handleFileUploadInput = (e) => {
        uploadFile(e.target.files[0])
    }
    const uploadFile = (file) => {
        const splittedName = file.name.split(".")
        const extension = splittedName[splittedName.length - 1];
        if (allowedExtensions.includes(extension)) {
            const reader = new FileReader();
            reader.readAsDataURL(file)
            reader.addEventListener('load', () => {
                setResultFile(reader.result)
            })
            if (onUpload) {
                onUpload({ file: file, source: reader.result })
            }
        } else {
            console.log(`Only ${allowedExtensions.join(", ")} images are allowd`)
        }
    }
    const handleDelete = () => {
        setResultFile(null)
        if (onUpload) {
            console.log("Deleting the file")
            onUpload({ file: null, source: null })
        }
    }
    return (
        <Container
            onDrop={handleFileDrop}
            onDragOver={e => e.preventDefault()}
        >
            {resultFile ?
                <div className="preview-image-container">
                    <div className="action-icons-container">
                        <button className="action-btn edit" onClick={() => fileInput.current.click()}><EditOutlinedIcon /> Edit</button>
                        <button className="action-btn delete" onClick={handleDelete}>
                            <DeleteOutlineOutlinedIcon />
                        </button>
                    </div>
                    <img src={resultFile} className="preview-image" />
                </div> :
                <>
                    <div className="label-container">
                        <img src={ImageIcon} height="32px" />
                        {label}
                    </div>
                    <Button variant='contained' className="upload-button" onClick={() => fileInput.current.click()}>Choose File</Button>
                </>
            }
            <input type="file" className="hidden-input" ref={fileInput} onChange={handleFileUploadInput} />
        </Container>
    )
}

const Container = styled.div`
    height: 200px;
    width: 225px;
    background-color: #F9FBFF;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 25px;
    position: relative;
    overflow: hidden;
    .label-container {
        display: flex;
        flex-direction: column;
        gap: 15px;
    }
    .preview-image-container {
        position: absolute;
        left: 0px;
        top: 0px;
        height: 100%;
        width: 100%;
        .action-icons-container{
            position: absolute;
            right: 14px;
            top: 14px;
            display: flex;
            flex-direction: row-reverse;
            gap: 8px;
            .action-btn {            
                border: none;
                outline: none;
                font-size: 12px;
                padding: 6px;
                border-radius: 4px;
                display: flex;
                gap: 4px;
                align-items: center;
                transition: box-shadow .3s ease-out;
                cursor: pointer;
                &:hover {
                    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
                }
            }
            .action-btn.edit {
                background-color: #FFA382;
                color: white;
            }
            .action-btn.delete {
                background-color: red;
                color: white;
            }
        }
        
        .preview-image {
            object-fit: cover;
            height: 100%;
            width: 100%;
        }
    }
    .hidden-input {
        opacity: 0;
        height: 0px;
        width: 0px;
        /* display: block !important;  
        position: absolute;
        left: 0;
        top: 0;        
        height: 100%;
        width: 100%;
        z-index: 2; */
    }
    .upload-button{
        background-color: #FFA382;
        z-index: 3;
    }
`


export default FileUpload