import styled from "@emotion/styled"
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const SelectPrimary = ({ label, children, ...props }) => {
    const Container = styled.div`
        label {
            color: #747474;
            font-weight: 500;
            font-size: 0.875rem;
            line-height: 1rem;
            display: block;
        }
        .select-container{
            position: relative;
            span {
                pointer-events: none;
                position: absolute;
                right: 15px;
                top: 60%;
                transform: translateY(-50%);
                svg {
                    font-size: 2.5rem;
                    color: #747474;
                }
            }
            select {
                -moz-appearance:none; /* Firefox */
                -webkit-appearance:none; /* Safari and Chrome */
                appearance:none;
                display: block;
                width: 100%;
                outline: none;
                border: 1px solid #E2E8F0;
                background: transparent;
                font-size: 0.938rem;
                box-sizing: border-box;
                padding: 12px 16px; 
                border-radius: 5px;
                cursor: pointer;
                        
            }
        }
        
    `
    return (
        <Container>
            <label>{label}</label>
            <div className="select-container">
                <span><ArrowDropDownIcon /></span>
                <select {...props}>
                    {children}
                </select>
            </div>

        </Container>
    )
}

export default SelectPrimary