/* eslint-disable jsx-a11y/alt-text */
import { CircularProgress, LinearProgress } from "@mui/material";
import Pagination from "@mui/material/Pagination";
import { makeStyles } from "@material-ui/core/styles";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import AddInventory from "../../../components/OutletAdminComponents/AddInventory";
import AddInventory1 from "../../../components/OutletAdminComponents/AddInventory-Updated";
import EditModal from "../../../components/Inventory/EditModal";
import Popup from "reactjs-popup";
import InvokeLayoutComponent from "../../../components/common/InvokeLogout";
import VariantsComponent, {
  getPriceByVariant,
  getQuantityFromVariant,
} from "./Variants";
import url from "../../../config/axios";
import { serializeQueryStringsFromObject } from "../../../helpers/serializeToQueryStrings";
import { colors } from "../../../Style.style";

import "./inventory.css";
const useStyles = makeStyles({
  root: {
    "& .Mui-selected": {
      backgroundColor: "#FFA382 !important",
    },
    "& .MuiPaginationItem-root": {
      borderRadius: "5px !important",
    },
  },
});

export default function Index() {
  // eslint-disable-next-line no-unused-vars
  const classes = useStyles();
  const history = useHistory();
  const [inventory, setInventory] = useState([]);
  const [productId, setProductId] = useState("");
  const [addModal, setAddModal] = useState(false);
  const [editView, setEditView] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const color = ["#03b7a5", "#fc3986", "#ab48d5", "#2bb72b", "#bdd72a"];
  let num = 0;
  const admin = JSON.parse(localStorage.getItem("Admin"));
  function assignColor() {
    var random = Math.floor(Math.random() * color.length);
    while (num === random) {
      random = Math.floor(Math.random() * color.length);
    }
    num = random;
    var value = color[random];
    return value;
  }
  const [invokeLogOut, setInvokeLogout] = useState(false);
  const initialPaginationData = {
    page: 1,
    limit: 100,
    latestFirst: 1,
    search: "",
  };
  const [queryData, setQueryData] = useState({
    ...initialPaginationData,
  });
  const notFoundRef = useRef();
  const [searchTerm, setSearchTerm] = useState("");
  const [currentEditingIndex, setCurrentEditingIndex] = useState(null);
  const [noRecord, setNoRecord] = useState(false);
  const [prevSearchTerm, setPrevSearchTerm] = useState("");

  /**
   *
   * @param {string} value actual value
   */
  const handleSearchTermChange = (value = "") => {
    setSearchTerm((value + "").trim());
    if (!noRecord) {
      if (
        (value + "").trim().length === 0 &&
        prevSearchTerm.trim().length !== 0
      ) {
        handleChange("search", "", true);
      }
    }
  };
  const performSearchOnEnter = () => {
    setPrevSearchTerm(searchTerm);
    handleChange("search", searchTerm, true);
  };

  const { search } = useLocation();
  const [parsed, setParsed] = useState(false);
  useEffect(
    () => {
      const query = new URLSearchParams(search);

      let page =
        Number.isNaN(query.get("page")) || !query.get("page")
          ? 1
          : +query.get("page");
      let limit =
        Number.isNaN(query.get("limit")) || !query.get("limit")
          ? 100
          : +query.get("limit");
      let latestFirst =
        Number.isNaN(query.get("latestFirst")) || !query.get("latestFirst")
          ? 1
          : +query.get("latestFirst");
      let searchParams = query.get("search");
      let queryParams = {
        ...queryData,
        page,
        limit,
        latestFirst,
        search: searchParams,
      };
      setSearchTerm(searchParams);
      setQueryData({ ...queryParams });
      setParsed(true);
    },
    //eslint-disable-next-line
    []
  );

  const [paginationData, setPaginationData] = useState({
    currentPage: 1,
    perPage: 30,
    totalPages: 0,
    total: 0,
  });
  const [loading, setLoading] = useState(true);

  //call to fetch data after parsing
  useEffect(
    () => {
      if (parsed) {
        GetAllProducts({ ...queryData });
      }
    },
    //eslint-disable-next-line
    [parsed, queryData]
  );

  /**
   *
   * @param {string} propName property to change
   * @param {*} propValue corresponding value of that property
   * @param {*} flush changes every other values to initial data
   */

  const updateLocalData = (invent, i) => {
    // const index = inventory.findIndex((inv) => inv.productId === invent.productId);
    let obj = invent;
    const new_obj = { ...obj, featuredProduct: !obj.feafeaturedProduct };
    // console.log(new_obj, 'boj');
    let newArr = [...inventory]; // copying the old datas array
    newArr[i] = new_obj; // replace e.target.value with whatever you want to change it to
    setInventory(newArr);
  };
  const updateLocalDataStatus = (invent, i) => {
    // const index = inventory.findIndex((inv) => inv.productId === invent.productId);
    let obj = invent;
    const new_obj = { ...obj, status: !obj.status };
    // console.log(new_obj, 'boj');
    let newArr = [...inventory]; // copying the old datas array
    newArr[i] = new_obj; // replace e.target.value with whatever you want to change it to
    setInventory(newArr);
  };
  const updateInventoryStatus = (outletId, productId, status) => {
    console.log(outletId, productId, status);
    url
      .put(`/inventory/?productID=${productId}&outletChainID=${outletId}`, {
        status: status,
      })
      .then(async (res) => {
        // console.log("updated", res.data);
        if (res.data.Message === "Success") {
          GetAllProducts();
        }
      });
  };
  const moveToEdit = (outlet) => {
    setSelectedProduct(outlet);
    setEditView(true);
  };

  const handleChange = (propName, propValue, flush = false) => {
    if (currentEditingIndex === null) {
      let query = {};
      if (flush) {
        query = {
          ...initialPaginationData,
          [propName]: propValue,
        };
      } else {
        query = { ...queryData, [propName]: propValue };
      }
      const queryString = serializeQueryStringsFromObject(query);
      history.push(`/inventory-outlet${queryString}`);
      setQueryData({ ...query });
    }
  };
  const GetAllProducts = () => {
    setLoading(true);
    let queryParams = { ...queryData };
    //sanity
    Object.keys(queryParams).forEach((key) => {
      if (!queryParams[key]) {
        delete queryParams[key];
      }
    });
    url
      .get(
        `/v2/admin/inventories/paginated?outletChainID=${admin[0].outletChainID}`,
        {
          params: {
            ...queryParams,
          },
        }
      )
      .then(async (res) => {
        if (res.data.Message === "Success") {
          console.log(res.data.data);
          if (!res.data?.data?.inventories?.length) {
            notFoundRef.current.open();
          }
          if (
            !prevSearchTerm.trim().length &&
            !res.data?.data?.inventories?.length
          ) {
            setNoRecord(true);
          }
          setInventory(res.data?.data?.inventories);
          setPaginationData({
            ...paginationData,
            ...res?.data?.data?.paginationData,
          });
        } else {
          console.error(res);
        }
        setLoading(false);
      })
      .catch((e) => {
        if (+e?.response?.status === 401) {
          setInvokeLogout(true);
        }
        setLoading(false);
        console.error(e?.response);
      });
  };
  const updateInventory = async (outletId, productId, featuredOne) => {
    let toSet = [
      ...inventory.map((obj, i) =>
        obj.productID === currentEditingIndex?.productID
          ? { ...obj, featuredProduct: !obj.featuredProduct }
          : obj
      ),
    ];
    await url
      .put(`/inventory/?productID=${productId}&outletChainID=${outletId}`, {
        featuredProduct: featuredOne,
      })
      .then(async (res) => {
        if (res.data.Message === "Success") {
          await setInventory(toSet);
          await setCurrentEditingIndex(null);
        } else {
          setCurrentEditingIndex(null);
          alert(res.data.data);
        }
      })
      .catch((e) => console.error(e));
  };

  //update
  useEffect(
    () => {
      if (!!currentEditingIndex) {
        updateInventory(
          currentEditingIndex.outletChainID,
          currentEditingIndex.productID,
          !currentEditingIndex.featuredProduct
        );
      }
    },
    //eslint-disable-next-line
    [currentEditingIndex]
  );

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="card">
          {loading ? (
            <div
              className="row"
              style={{
                padding: "1em",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <Fragment>
              <div className="row" style={{ backgroundColor: "white" }}>
                <div className="col-md-12">
                  <div className="row">
                    <div
                      style={{
                        height: "25px",
                        marginLeft: "-32px",
                        backgroundColor: "#FFA382",
                        marginTop: "5px",
                      }}
                    >
                      <span style={{ color: "#FFA382" }}>.</span>
                    </div>
                    <div className="col-md-6 d-flex">
                      <div
                        style={{
                          color: "#FFA382",
                          fontSize: "20px",
                          fontWeight: "600",
                        }}
                      >
                        Inventory
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="input-group">
                        <input
                          type="text"
                          style={{ borderRight: "none" }}
                          className="form-control newInput"
                          placeholder="Search Product "
                          aria-label="Input group example"
                          aria-describedby="btnGroupAddon"
                          value={searchTerm}
                          onKeyPress={(e) => {
                            if (e.key === "Enter") {
                              performSearchOnEnter();
                            }
                          }}
                          onChange={(e) => setSearchTerm(e.target.value)}
                        />
                        {searchTerm?.length ? (
                          <div className="input-group-prepend">
                            <div
                              className="input-group-text"
                              onClick={() => handleSearchTermChange("")}
                              style={{
                                backgroundColor: "white",
                                borderLeft: "none",
                                paddingLeft: 0,
                                cursor: "pointer",
                              }}
                            >
                              <i className="fa fa-times"></i>
                            </div>
                          </div>
                        ) : null}
                        <div
                          className="input-group-prepend"
                          style={{
                            cursor: "pointer",
                          }}
                        >
                          <div
                            className="input-group-text newSearchIcon"
                            id="btnGroupAddon"
                            onClick={performSearchOnEnter}
                          >
                            <i
                              style={{ color: colors.searchColor }}
                              className="fas fa-search"
                            ></i>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <button
                        style={{ margin: "0" }}
                        className="btn  globalButton"
                        onClick={() => setAddModal(!addModal)}
                      >
                        <i className="fas fa-plus-circle"></i>
                        Add Product
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <table className="table table-striped-cust">
                    <thead>
                      <tr>
                        <th scope="col">Name</th>

                        <th scope="col">Stock</th>
                        <th scope="col">Price / Item</th>
                        <th scope="col">Brand</th>
                        <th scope="col">Category</th>
                        <th scope="col">Featured Product</th>
                        <th scope="col">Publish Status</th>
                        {/* <th scope="col">Status</th> */}
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {inventory.map((inv, i) => {
                        return (
                          <tr
                            height={"2px"}
                            style={{
                              borderBottom: "1px solid rgb(241, 245, 249)",
                            }}
                            key={i}
                          >
                            <td colSpan="" style={{ display: "flex" }}>
                              <div
                                style={{
                                  width: "300px",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {inv.product.image ? (
                                  <img
                                    img
                                    alt="yo1"
                                    src={inv.product.image}
                                    className="img-fluid"
                                    width="40"
                                    style={{
                                      height: "40px",
                                      borderRadius: "5px",
                                    }}
                                  />
                                ) : (
                                  <div
                                    className="image-thumbnail-div"
                                    style={{
                                      backgroundColor: assignColor(),
                                      width: "40px",
                                      height: "40px",
                                      borderRadius: "5px",
                                      display: "flex",
                                      alignItems: "flex-end",
                                    }}
                                  >
                                    <span>
                                      {inv?.product?.name?.substring(0, 1)}
                                    </span>
                                  </div>
                                )}
                                <span
                                  style={{
                                    color: "#1E293B",
                                    width: "60%",
                                    paddingLeft: "10px",
                                    paddingTop: "10px",
                                  }}
                                >
                                  {inv.product.name}
                                </span>
                              </div>
                            </td>

                            <td style={{ color: "#1E293B" }}>
                              {inv.variants?.length > 1
                                ? "---"
                                : inv.variants?.length === 1
                                ? getQuantityFromVariant(inv.variants[0], inv)
                                : inv.quantity}
                            </td>
                            <td style={{ color: "#1E293B" }}>
                              {inv.variants.length ? (
                                inv.variants.length > 1 ? (
                                  <VariantsComponent
                                    variants={inv.variants}
                                    masterProductName={
                                      inv?.product?.name ?? "N/A"
                                    }
                                    rootObject={inv}
                                  />
                                ) : (
                                  getPriceByVariant(inv.variants[0])
                                )
                              ) : (
                                Number(inv.originalPrice).toLocaleString(
                                  "fullwide",
                                  { useGrouping: false }
                                )
                              )}
                            </td>
                            <td
                              style={
                                inv.product.brandName
                                  ? { color: "#1E293B" }
                                  : { paddingLeft: "30px", color: "#1E293B" }
                              }
                            >
                              {inv.product.brandName
                                ? inv.product.brandName
                                : "-"}
                            </td>
                            <td style={{ color: "#1E293B" }} className="status">
                              {inv.product.category.length
                                ? inv.product.category[0].name
                                : ""}
                            </td>
                            <td>
                              {inv?.productID ===
                              currentEditingIndex?.productID ? (
                                <LinearProgress color="success" />
                              ) : (
                                <label className="switch">
                                  <input
                                    type="checkbox"
                                    checked={inv.featuredProduct}
                                    disabled={currentEditingIndex !== null}
                                    onChange={() => {
                                      updateLocalData(inv, i);
                                      updateInventory(
                                        inv.outletChainID,
                                        inv.productID,
                                        !inv.featuredProduct
                                      );
                                    }}
                                  />
                                  <span
                                    className="slider round"
                                    style={
                                      inv.featuredProduct
                                        ? { backgroundColor: "#FFA382" }
                                        : null
                                    }
                                  ></span>
                                </label>
                              )}
                            </td>
                            <td className="status">
                              <label className="switch">
                                <input
                                  type="checkbox"
                                  checked={inv.status}
                                  onChange={() => {
                                    updateLocalDataStatus(inv, i);
                                    updateInventoryStatus(
                                      inv.outletChainID,
                                      inv.productID,
                                      !inv.status
                                    );
                                  }}
                                />
                                <span
                                  className="slider round"
                                  style={
                                    inv.status
                                      ? { backgroundColor: "#FFA382" }
                                      : null
                                  }
                                ></span>
                              </label>
                              {/* {inv.status ? (
                            <span>Published</span>
                          ) : (
                            <span style={{ color: "red" }}>Not Published</span>
                          )} */}
                            </td>
                            <td>
                              <td>
                                <div style={{ display: "flex" }}>
                                  <i
                                    style={{ color: "#FFA382" }}
                                    onClick={() => moveToEdit(inv)}
                                    className="fas fa-pen"
                                  ></i>
                                  {/* <i
                                    onClick={() => {
                                      setProductId(inv.productID);
                                    }}
                                    className="fas fa-trash deleteIcon"
                                  ></i> */}
                                </div>
                              </td>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </Fragment>
          )}
        </div>
        {loading ? null : (
          <div
            className="d-flex col-md-12"
            style={{
              justifyContent: "flex-end",
              marginTop: "5px",
              marginLeft: "13px",
            }}
          >
            <div
              style={{
                backgroundColor: "white",
                borderRadius: "5px",
                padding: "6px",
              }}
            >
              <Pagination
                shape="square"
                className={classes.root}
                onChange={(e, value) => {
                  handleChange("page", value);
                }}
                page={paginationData.currentPage}
                count={paginationData.totalPages ?? 0}
              />
            </div>
          </div>
        )}
      </div>
      {addModal && (
        <AddInventory1
          setAddModal={setAddModal}
          getInventory={GetAllProducts}
          // setInventory={setInventory}
        />
      )}
      {editView && (
        <EditModal
          closeModal={setEditView}
          GetAllInventory={GetAllProducts}
          product={selectedProduct}
        />
      )}
      {invokeLogOut && <InvokeLayoutComponent />}
      <Popup
        ref={notFoundRef}
        position="center"
        onClose={() => {
          notFoundRef.current.close();
          setSearchTerm("");
        }}
      >
        <div style={{ textAlign: "center" }}>
          <i
            style={{
              color: "red",
              fontSize: "70px",
            }}
            class="fa fa-ban"
          ></i>
          <h2 className="popup-heading" style={{ marginTop: "20px" }}>
            Failed
          </h2>
          <p>Product(s) not found</p>
          <button
            className="btn btn-cannaby"
            style={{ marginTop: "0" }}
            onClick={() => {
              notFoundRef.current.close();
              handleSearchTermChange();
            }}
          >
            OK
          </button>
        </div>
      </Popup>
    </div>
  );
}
