import React from "react";
import styled from "@emotion/styled";
import CountryCodes from "../../countryCodes.json";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

export default function PhoneNumberInput({
  label,
  value,
  disabled,
  setValue,
  ...props
}) {
  React.useEffect(() => {
    if (setValue && (!value.number || !value.countryCode)) {
      console.log("Set default value");
      setValue({
        countryCode: CountryCodes[167].dial_code, // Pakistan country code by default
        number: "",
      });
    }
  }, []);
  const handleSelectCountryCode = (e) => {
    if (setValue) {
      setValue({
        ...value,
        countryCode: e.target.value,
      });
    }
  };
  const handleChange = (e) => {
    let paste = e.clipboardData || window.clipboardData;
    if (!paste) {
      if (setValue) {
        setValue({
          ...value,
          number: e.target.value,
        });
      }
    }
  };
  const handlePaste = (e) => {
    let paste = (e.clipboardData || window.clipboardData).getData("text");
    if (setValue) {
      setValue({
        ...value,
        number: paste,
      });
    }
  };
  return (
    <Container>
      <label>{label}</label>
      <div className="input-container">
        <div className="country_code_select">
          <select
            disabled={disabled}
            value={
              value?.countryCode
                ? value.countryCode
                : CountryCodes[167].dial_code
            }
            onChange={handleSelectCountryCode}
          >
            {CountryCodes.map((item) => (
              <option value={item.dial_code}>{item.dial_code}</option>
            ))}
          </select>
          <ArrowDropDownIcon />
        </div>
        <div className="v-divider"></div>
        <input
          type="text"
          value={value.number}
          onChange={handleChange}
          onPaste={handlePaste}
          disabled={disabled}
          {...props}
        />
      </div>
    </Container>
  );
}

const Container = styled.div`
  label {
    color: #747474;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1rem;
    display: block;
  }
  .input-container {
    border: 1px solid #e2e8f0;
    border-radius: 5px;
    display: flex;
    box-sizing: border-box;
    background-color: white;
    /* align-items: center; */
    .country_code_select {
      position: relative;
      width: 110px;
      flex-shrink: 0;
      select {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        border: none;
        outline: none;
        cursor: pointer;
        appearance: none;
        padding-left: 16px;
      }
      svg {
        position: absolute;
        z-index: 2;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        pointer-events: none;
      }
    }
    button {
      border: none;
      outline: none;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 15px;
      background-color: white;
      color: #747474;
      cursor: pointer;
      span {
        color: #ffa382;
      }
    }
    .v-divider {
      width: 1px;
      position: relative;
      &::after {
        content: "";
        position: absolute;
        left: 0px;
        top: 50%;
        height: 70%;
        width: 100%;
        transform: translateY(-50%);
        background-color: #dbdbdb;
      }
    }
    input {
      width: 1px;
      flex: 1;
      border: none;
      outline: none;
      padding: 7px 15px;
      box-sizing: border-box;
    }
  }
`;
