/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import url from "../../config/axios";
import blank from "../../assets/defaultImage.jpg";

export default function SearchbarUpdated({ setSingleProductDetails }) {
    const [text, setText] = useState("");
    const [suggestions, setSuggestions] = useState([]);
    const [icon, setIcon] = useState(false);
    const GetAllProducts = () => {
        url.get(`/product/all`).then(async (res) => {
            // console.log("Products", res.data.data);
            if (res.data.Message === "Success") {
                setSuggestions(res.data.data.slice(0, 5));
            } else {
                setSuggestions([]);
            }
        });
    };

    useEffect(() => {
        GetAllProducts();
    }, []);

    const onChangeHandler = (text) => {
        setText(text);
        url
            .get(`/product/search/?name=${text}`)
            .then(async (res) => {
                console.log("Search Response: ", res);
                if (res.data.Message === "Success") {
                    await setSuggestions(res.data.data);
                }
            })
            .catch((e) => console.log(e));
    };

    const onSuggestionsHandler = (text) => {
        setSingleProductDetails(text);
        setText(text.name);
        // setProduct(products[text]);
        // console.log("tests2", product);
        setSuggestions([]);

    };
    return (
        <>
            <div className="input-group">
                <input
                    type="text"
                    className="form-control "
                    placeholder={
                        suggestions.length ? "" : "Search by product or brand name.."
                    }
                    onChange={(e) => onChangeHandler(e.target.value)}
                    value={text}
                    onFocus={() => setIcon(!icon)}
                />

            </div>

            <ul
                className="dropdown-menu dropdown-search drp-menu ml-4"
                style={{ display: suggestions.length ? "block" : "none" }}
            >
                {suggestions &&
                    suggestions.map((suggestion, i) => {
                        // console.log(suggestion.name)
                        return (
                            <li key={i}>
                                <a
                                    class="dropdown-item"
                                    onClick={() => onSuggestionsHandler(suggestion)}
                                >
                                    <img
                                        class="dropdown-img"
                                        alt=""
                                        src={suggestion.image ? suggestion.image : blank}
                                    />
                                    <span>{suggestion.name}</span>
                                </a>
                            </li>
                        );
                    })}
            </ul>
        </>
    )
}