/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import url from "../../config/axios";
import { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import storage from "../../config/firebase";
import "./setting.css";
import Popup from "reactjs-popup";

export default function Setting() {
  const history = useHistory();
  const [image, setImage] = useState("");
  const [file, setFile] = useState("");
  const [longitude, setLongitude] = useState("");
  const [latitude, setLatitude] = useState("");
  const [fee, setFee] = useState("");
  const [helpEmail, setHelpEmail] = useState("");
  const [helpContact, setHelpContact] = useState("");
  const [message, setMessage] = useState({
    display: false,
    status: false,
    text: "",
  });

  const ref = useRef();
  const openTooltip = () => ref.current.open();
  const closeTooltip = () => ref.current.close();
  // const [outlets, setOutlets] = useState([]);

  const queryParams = new URLSearchParams(window.location.search);
  let outletId = queryParams.get("outletId");

  const admin = JSON.parse(localStorage.getItem("Admin"));

  const getSettings = () => {
    if (admin[0].isSubOutlet) {
      url.get(`/oca?adminID=${admin[0].adminID}`).then(async (res) => {
        console.log(res.data);
        if (res.data.Message === "Success") {
          if (res.data.data.length === 0) {
            setLongitude("36.06151892972753");
            setLatitude("-95.77413626184604");
            setFee(199);
          } else {
            console.log(res.data.data);
            setLongitude(res.data.data[0].location.coordinates[0]);
            setLatitude(res.data.data[0].location.coordinates[1]);
            setImage(res.data.data[0].logo);
            setFee(res.data.data[0].assessmentFee);
          }
        } else {
          setLongitude("36.06151892972753");
          setLatitude("-95.77413626184604");
          setFee(199);
        }
      });
    } else {
      url.get(`/oc?outletChainID=${outletId}`).then(async (res) => {
        console.log(res.data);
        if (res.data.Message === "Success") {
          if (res.data.data.length === 0) {
            setLongitude("36.06151892972753");
            setLatitude("-95.77413626184604");
            setFee(199);
          } else {
            console.log(res.data.data);
            setLongitude(res.data.data[0].location.coordinates[0]);
            setLatitude(res.data.data[0].location.coordinates[1]);
            setImage(res.data.data[0].logo);
            setFee(res.data.data[0].assessmentFee);
          }
        } else {
          setLongitude("36.06151892972753");
          setLatitude("-95.77413626184604");
          setFee(199);
        }
      });
    }
  };

  useEffect(() => {
    getSettings();
  }, []);

  // useEffect(() => {
  //   if (!admin[0].isSubOutlet) {
  //     GetAllOutlets();
  //   }
  // }, []);

  // const GetAllOutlets = () => {
  //   url
  //     .get(`/oc/?consumerId=${admin[0].consumerId}`)
  //     .then(async (res) => {
  //       // console.log('res', res)
  //       if (res.data.Message === "Success") {
  //         setOutlets(res.data.data);
  //       } else {
  //         alert(res.data.data);
  //       }
  //     })
  //     .catch((e) => console.log(e));
  // };

  const addSetting = (blob) => {
    // console.log(admin[0].isSubOutlet);
    let body = {
      logo: blob,
      location: {
        type: "Point",
        coordinates: [longitude, latitude],
      },
      assessmentFee: fee,
      helpEmail: helpEmail,
      helpContact: helpContact,
    };

    if (admin[0].isSubOutlet) {
      url
        .put(`/oca?adminID=${admin[0].adminID}`, body)
        .then(async (res) => {
          if (res.data.Message === "Success") {
            setMessage({
              text: "Success",
              status: true,
              display: true,
            });
            openTooltip();
          } else {
            setMessage({
              text: "Failed",
              status: true,
              display: true,
            });
            openTooltip();
          }
        })
        .catch((e) => console.log(e));
    } else {
      url
        .put(`/oc?outletChainID=${outletId}`, body)
        .then(async (res) => {
          if (res.data.Message === "Success") {
            setMessage({
              text: "Success",
              status: true,
              display: true,
            });
            openTooltip();
          } else {
            setMessage({
              text: "Failed",
              status: true,
              display: true,
            });
            openTooltip();
          }
        })
        .catch((e) => console.log(e));
    }
  };

  const fileUpload = () => {
    // const storageRef = ref(storage, `images/${file.name}`);
    // console.log(image);
    if (file !== "") {
      const uploadTask = storage.ref(`images/${file.name}`).put(file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // const progress = Math.round(
          //   (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          // );
        },
        (error) => {
          console.log(error);
        },
        () => {
          storage
            .ref("images")
            .child(file.name)
            .getDownloadURL()
            .then(async (blob) => {
              // console.log(blob);
              await addSetting(blob);
            });
        }
      );
    } else {
      addSetting("");
    }
  };

  const imageSelector = (event) => {
    if (event.target.files[0]) {
      // console.log(event);
      setImage(URL.createObjectURL(event.target.files[0]));
      setFile(event.target.files[0]);
    }
  };

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="card card-pro">
          <div className="row">
            <div className="col-md-12">
              <h3 className="setting-title">Logo</h3>
              <div className="row">
                <div className="col-md-6">
                  <div className="input-group">
                    <div className="custom-file">
                      <input
                        type="file"
                        className="custom-file-input"
                        onChange={imageSelector}
                        id="customFileLang"
                        lang="en"
                      />
                      <label
                        className="custom-file-label"
                        htmlFor="customFileLang"
                      >
                        Select Logo
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input-group">
                    {image !== "" && (
                      <div className="col-md-12">
                        <div className="">
                          <img
                            src={image}
                            style={{ width: "100%", height: "auto" }}
                            alt="selectedImage"
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-12">
              <h3 className="setting-title">Outlet Location</h3>

              <div className="row">
                <div className="col-md-4">
                  <div className="group-comb">
                    <div className="form-group">
                      <label>Longitude*</label>
                      <input
                        type="text"
                        value={longitude}
                        onChange={(e) => setLongitude(e.target.value)}
                        className="form-control"
                        placeholder="Longitude"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="group-comb">
                    <div className="form-group">
                      <label>Latitude*</label>
                      <input
                        type="text"
                        value={latitude}
                        onChange={(e) => setLatitude(e.target.value)}
                        className="form-control"
                        placeholder="Longitude"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <button type="button" className="location-button">
                    Select Location On Map
                  </button>
                </div>
              </div>
            </div>

            <div className="col-md-12">
              <h3 className="setting-title">Cannabis ID Assessment Fee</h3>

              <div className="row">
                <div className="col-md-12">
                  <div className="group-comb">
                    <div className="form-group">
                      <label>Enter Fee*</label>
                      <input
                        type="text"
                        value={fee}
                        onChange={(e) => setFee(e.target.value)}
                        className="form-control"
                        placeholder="Enter Fee"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <h3 className="setting-title">Help</h3>

              <div className="row">
                <div className="col-md-6">
                  <div className="group-comb">
                    <div className="form-group">
                      <label>Help Email*</label>
                      <input
                        type="text"
                        value={helpEmail}
                        onChange={(e) => setHelpEmail(e.target.value)}
                        className="form-control"
                        placeholder="Enter Help Email"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="group-comb">
                    <div className="form-group">
                      <label>Help Contact*</label>
                      <input
                        type="number"
                        value={helpContact}
                        onChange={(e) => setHelpContact(e.target.value)}
                        className="form-control"
                        placeholder="Enter Help Contact"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-12">
              <button
                type="button"
                onClick={fileUpload}
                className="btn btn-cannaby"
              >
                Update
              </button>
              <button
                style={{ marginLeft: "20px", marginTop: "30px" }}
                type="button"
                onClick={() => history.push("/setting")}
                className="btn btn-rnav"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <Popup ref={ref} position="center">
        {message.status ? (
          <div style={{ textAlign: "center" }}>
            <i
              // style={{
              //   color: "green",
              //   fontSize: "60px",
              //   border: "10px solid green",
              //   borderRadius: "50px",
              //   padding: "10px",
              // }}
              class="fa fa-check"
            ></i>
            <h2 className="popup-heading" style={{ marginTop: "20px" }}>
              Success
            </h2>
            <p>Settings updated successfully</p>
            <button
              className="btn btn-cannaby"
              style={{ marginTop: "0" }}
              onClick={() => {
                closeTooltip();
              }}
            >
              OK
            </button>
          </div>
        ) : (
          <div style={{ textAlign: "center" }}>
            <i
              style={{
                color: "green",
                fontSize: "70px",
              }}
              className="fa fa-ban"
            ></i>
            <h2 style={{ marginTop: "20px" }}>Failed</h2>
            <button
              style={{ marginTop: "10px" }}
              type="button"
              onClick={closeTooltip}
              className="btn btn-primary"
            >
              Try Again
            </button>
          </div>
        )}
      </Popup>
    </div>
  );
}
