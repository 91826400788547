import { createSlice } from "@reduxjs/toolkit";
import url from "../../config/axios";
import * as crypt from "crypto-js";

const initialState = {
  isMultiChainAdmin: false,
  info: null,
  switchingProgress: false,
  myOutlets: [],
  error: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    startSwitchingProgressAction: (state) => {
      state.switchingProgress = true;
      state.error = null;
    },
    resetAuthStateAction: () => initialState,
    finishSwitchingWithErrorAction: (state, action) => {
      state.switchingProgress = false;
      state.error = action?.payload ?? "Something went wrong";
    },
    abortProgressAction: (state) => {
      state.switchingProgress = false;
    },
    changeAuthStateAction: (state, action) => {
      state.isMultiChainAdmin = true;
      state.info = action.payload?.info ?? state.info;
      state.myOutlets = Array.isArray(action.payload?.myOutlets)
        ? action.payload.myOutlets
        : state.myOutlets;
      state.switchingProgress = false;
      state.error = null;
      window.location?.reload();
      window?.location?.replace("/dashboard");
    },
    removeErrorMessageFromAuthStateAction: (state) => {
      state.error = null;
    },
  },
});

export const {
  changeAuthStateAction,
  startSwitchingProgressAction,
  finishSwitchingWithErrorAction,
  resetAuthStateAction,
  removeErrorMessageFromAuthStateAction,
  abortProgressAction,
} = authSlice.actions;

export const loginMultiChainAdminThunk = (params) => (dispatch) => {
  dispatch(startSwitchingProgressAction());
  url
    .post(`v1/mca/login`, {
      email: params?.email,
      password: params?.password,
    })
    .then(async (response) => {
      const resData = response?.data?.data ?? {};
      await localStorage.setItem(
        "Admin",
        JSON.stringify([resData?.myOutlets[0]])
      );
      await localStorage.setItem("token", response?.data?.token);
      try {
        if (params.rememberMeState) {
          await localStorage.setItem(
            "remeberMeState",
            JSON.stringify({
              email: params.email,
              password: crypt.AES.encrypt(
                params.password,
                params.SECRET
              ).toString(),
              role: "mca",
            })
          );
        } else {
          await localStorage.removeItem("remeberMeState");
        }
      } catch (e) {
        //to do
      }
      dispatch(
        changeAuthStateAction({
          switchingProgress: false,
          myOutlets: resData?.myOutlets,
          info: resData?.info,
          isMultiChainAdmin: true,
        })
      );
    })
    .catch((e) => {
      dispatch(
        finishSwitchingWithErrorAction(
          +e?.response?.status === 401
            ? "Session expired.Try to logout and login again"
            : e.response?.data?.data?.message ?? "Something went wrong"
        )
      );
    });
};
export const switchOutletThunk = (outletChainID) => (dispatch) => {
  dispatch(startSwitchingProgressAction());
  url
    .put(
      `v1/mca/switch`,
      {},
      {
        params: {
          outletChainID,
        },
      }
    )
    .then(async (response) => {
      const resData = response?.data?.data ?? {};
      await localStorage.setItem("Admin", JSON.stringify([resData?.myOutlet]));
      await localStorage.setItem("token", response?.data?.token);
      dispatch(changeAuthStateAction());
    })
    .catch((e) => {
      // console.error("Status", e?.response?.status);
      dispatch(
        finishSwitchingWithErrorAction(
          +e?.response?.status === 401
            ? "Session expired.Try to logout and login again"
            : e.response?.data?.data?.message ?? "Something went wrong"
        )
      );
    });
};
export const selectAuthStateErrorMessage = (state) => state.auth.error;
export const selectMyOutlets = (state) => state.auth.myOutlets;
export const selectIfSwitchingProgress = (state) =>
  state.auth.switchingProgress;
export const selectifMultiChainAdmin = (state) => state.auth.isMultiChainAdmin;

export default authSlice.reducer;
