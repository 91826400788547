import "./OutletChainAdmin.css";
import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import url from "../../config/axios";
import { useEffect } from "react";
import moment from "moment";
import Pagination from "@mui/material/Pagination";

import { makeStyles } from "@material-ui/core/styles";
import { serializeQueryStringsFromObject } from "../../helpers/serializeToQueryStrings";
const useStyles = makeStyles({
  root: {
    "& .Mui-selected": {
      backgroundColor: "#FFA382 !important",
    },
    "& .MuiPaginationItem-root": {
      borderRadius: "5px !important",
    },
  },
});
export default function Outlets() {
  const classes = useStyles();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const adminID = JSON.parse(localStorage.getItem("Admin"));
  const [outlet, setOutlets] = useState([]);
  const { search } = useLocation();
  const [searchTerm, setSearchTerm] = useState("");

  const initialPaginationData = {
    page: 1,
    limit: 100,
    latestFirst: 1,
    search: "",
  };
  const [queryData, setQueryData] = useState({
    ...initialPaginationData,
  });
  const [parsed, setParsed] = useState(false);
  const [paginationData, setPaginationData] = useState({
    currentPage: 1,
    perPage: 30,
    totalPages: 0,
    total: 0,
  });
  const moveToEdit = (outlet) => {
    localStorage.setItem("outlet", JSON.stringify(outlet));
    history.push("/edit-outlet");
  };
  const GetAllOutlets = async () => {
    try {
      let queryParams = { ...queryData };
      //sanity
      Object.keys(queryParams).forEach((key) => {
        if (!queryParams[key]) {
          delete queryParams[key];
        }
      });
      const AllOutlets = await url.get(
        `/oc/all/?consumerId=${adminID[0].consumerId}`,
        {
          params: {
            ...queryParams,
          },
        }
      );
      setOutlets(AllOutlets.data.data.data);
      setPaginationData({
        ...paginationData,
        ...AllOutlets?.data.data?.paginationData,
      });
      setLoading(false);
    } catch (e) {}
  };
  const handleChange = (propName, propValue, flush = false) => {
    let query = {};
    console.log(propName);
    console.log(propValue);
    if (flush) {
      query = {
        ...initialPaginationData,
        [propName]: propValue,
      };
    } else {
      query = { ...queryData, [propName]: propValue };
    }
    const queryString = serializeQueryStringsFromObject(query);
    history.push(`/outlets${queryString}`);
    setQueryData({ ...query });
  };
  useEffect(
    () => {
      const query = new URLSearchParams(search);

      let page =
        Number.isNaN(query.get("page")) || !query.get("page")
          ? 1
          : +query.get("page");
      let limit =
        Number.isNaN(query.get("limit")) || !query.get("limit")
          ? 100
          : +query.get("limit");
      let latestFirst =
        Number.isNaN(query.get("latestFirst")) || !query.get("latestFirst")
          ? 1
          : +query.get("latestFirst");
      let searchParams = query.get("search");
      let queryParams = {
        ...queryData,
        page,
        limit,
        latestFirst,
        search: searchParams,
      };
      setSearchTerm(searchParams);
      setQueryData({ ...queryParams });
      setParsed(true);
    },
    //eslint-disable-next-line
    []
  );
  useEffect(
    () => {
      if (parsed) {
        GetAllOutlets({ ...queryData });
      }
    },
    //eslint-disable-next-line
    [parsed, queryData]
  );
  useEffect(() => {
    GetAllOutlets();
  }, []);
  return (
    <div className="row">
      <div className="col-md-12">
        <div className="card card-pro">
          <div className="row">
            <div
              style={{
                height: "25px",
                marginLeft: "-32px",
                backgroundColor: "#FFA382",
                marginTop: "5px",
              }}
            >
              <span style={{ color: "#FFA382" }}>.</span>
            </div>
            <div
              className={
                adminID[0].isSubOutlet ? "col-md-5 d-flex" : "col-md-3 d-flex"
              }
            >
              <div
                style={{
                  color: "#FFA382",
                  fontSize: "20px",
                  fontWeight: "600",
                }}
              >
                Outlets
              </div>
            </div>
            {/* <div className="col-md-2"></div> */}
            <div className="col-md-7">
              <div className="input-group">
                <input
                  type="text"
                  className="newInput "
                  style={{ width: "90%" }}
                  placeholder="Search Outlets"
                  aria-label="Input group example"
                  aria-describedby="btnGroupAddon"
                />
                <div className="input-group-prepend">
                  <div
                    className="input-group-text newSearchIcon"
                    id="btnGroupAddon"
                  >
                    <i className="fas fa-search"></i>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-2 ml-3">
              <button
                className="btn  globalButton"
                onClick={() => {
                  history.push("/add-outlet");
                }}
              >
                <i className="fas fa-plus-circle"></i>
                Add Outlet
              </button>
            </div>
            <div className="col-md-12">
              <table className="MyNewTable">
                <thead>
                  <tr>
                    <th scope="col" style={{ paddingLeft: "50px" }}>
                      Sr.no
                    </th>
                    <th scope="col">Name</th>
                    <th scope="col">Admin</th>
                    <th scope="col" style={{ width: "8%" }}>
                      Inventory
                    </th>
                    <th scope="col">Created On</th>
                    <th
                      scope="col"
                      style={{ textAlign: "right", width: "12%" }}
                    >
                      Status
                    </th>
                    <th scope="col" style={{ textAlign: "right" }}>
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {outlet.length !== 0 ? (
                    outlet.map((outlet, i) => {
                      return (
                        <tr key={i}>
                          <td style={{ width: "8%", textAlign: "center" }}>
                            {i + 1}
                          </td>
                          <td
                            style={{ textAlign: "left", paddingLeft: "13px" }}
                          >
                            {outlet.outletName}
                          </td>
                          <td
                            style={{
                              textAlign: "left",
                              width: "25% !important",
                            }}
                          >
                            {" "}
                            {outlet.adminName} <br />{" "}
                            <span>{outlet.adminEmail}</span>
                          </td>
                          <td style={{ width: "5%", textAlign: "left" }}>
                            {outlet?.inventory ?? "-"}
                          </td>
                          <td style={{ width: "16%", textAlign: "left" }}>
                            {moment(outlet.createdAt).format(
                              "MMM. Do, YYYY h:mm A"
                            )}
                          </td>
                          <td
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            {" "}
                            {outlet.isActive ? (
                              <div
                                style={{
                                  width: "50%",
                                  backgroundColor: "rgba(12, 214, 0, 0.08)",
                                  height: "25px",
                                  textAlign: "center",
                                  borderRadius: "13px",
                                }}
                              >
                                <p
                                  style={{
                                    fontWeight: "400",
                                    textAlign: "center",
                                    fontSize: "13px",
                                    paddingTop: "4px",
                                    color: "#0CD600",
                                  }}
                                >
                                  Active
                                </p>
                              </div>
                            ) : (
                              <div
                                style={{
                                  width: "50%",
                                  backgroundColor: " rgba(255, 109, 109, 0.1)",
                                  height: "25px",
                                  textAlign: "center",
                                  borderRadius: "13px",
                                }}
                              >
                                <p
                                  style={{
                                    fontWeight: "400",
                                    textAlign: "center",
                                    fontSize: "13px",
                                    paddingTop: "4px",
                                    color: "#FF6D6D",
                                  }}
                                >
                                  Inactive
                                </p>
                              </div>
                            )}
                          </td>
                          <td
                            style={{ textAlign: "left", paddingLeft: "20px" }}
                          >
                            {" "}
                            <i
                              style={{ color: "#FFA382" }}
                              onClick={() => moveToEdit(outlet)}
                              className="fas fa-pen"
                            ></i>
                            <i
                              onClick={() => {
                                // setOutletId(outlet.outletChainID);
                                // openTooltip();
                              }}
                              className="fas fa-trash deleteIcon"
                            ></i>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="12" style={{ textAlign: "center" }}>
                        No records found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {loading ? null : (
        <div
          className="d-flex col-md-12"
          style={{
            justifyContent: "flex-end",
            marginTop: "5px",
            marginLeft: "13px",
          }}
        >
          <div
            style={{
              backgroundColor: "white",
              borderRadius: "5px",
              padding: "6px",
            }}
          >
            <Pagination
              shape="square"
              className={classes.root}
              onChange={(e, value) => {
                handleChange("page", value);
              }}
              page={paginationData.currentPage}
              count={paginationData.totalPages ?? 0}
            />
          </div>
        </div>
      )}
    </div>
  );
}
