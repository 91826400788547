import React, { useState } from "react";
import "./outletStyles.css";
import url from "../../config/axios";
import { useHistory } from "react-router-dom";
import { State, City } from "country-state-city";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import InputPrimary from "../../atoms/InputPrimary/Index2";
import CustomSelectPrimary from "../../atoms/CustomSelectPrimary/CustomSelectCitiesStates";
import PhoneNumberInput from "../../atoms/PhoneNumberInput/PhoneNumberInputAnik";
import PasswordInput from "../../atoms/PasswordInput/PasswordInput";
import { Typography } from "@mui/material";
import { countries } from "../../helpers/countriesList";
import { useTheme } from "@mui/system";
export default function Addoutlet() {
  const history = useHistory();
  const theme = useTheme();
  const admin = JSON.parse(localStorage.getItem("Admin")) || null;
  const [phone, setPhone] = useState("");
  const [outletName, setOutletName] = useState("");
  const [adminName, setAdminName] = useState("");
  const [adminEmail, setAdminEmail] = useState("");
  const [longitude, setLongitude] = useState("");
  const [latitude, setLatitude] = useState("");
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [statesList, setStatesList] = useState([]);
  const [citiesList, setCitiesList] = useState([]);
  const [taxesList, setTaxesList] = useState([{ taxType: "", value: "" }]);
  const [password, setPassword] = useState("");
  const [activeStatus, setActiveStatus] = useState(false);
  const [passwordVisibiltyMap, setPasswordVisibilityMap] = useState({
    newPassword: true,
  });
  // Validation UseState
  const [validation, setValidation] = useState({});
  const validateForm = () => {
    let data = { ...validation };
    if (outletName.trim().length === 0) {
      data = { ...data, OutErr: "Outletname is required" };
    } else {
      let { OutErr, ...others } = data;
      data = { ...others };
    }
    if (adminName.trim().length === 0) {
      data = { ...data, AdminErr: "Admin name is required" };
    } else {
      let { AdminErr, ...others } = data;
      data = { ...others };
    }
    if (adminEmail.trim().length === 0) {
      data = { ...data, EmailErr: "Admin email is required" };
    } else {
      let { EmailErr, ...others } = data;
      data = { ...others };
    }
    if (phone.trim().length === 0) {
      data = { ...data, PhoneErr: "Phone is required" };
    } else {
      let { PhoneErr, ...others } = data;
      data = { ...others };
    }
    if (taxesList[0].taxType === "") {
      data = { ...data, TTypeErr: "Tax type  is required" };
    } else {
      let { TTypeErr, ...others } = data;
      data = { ...others };
    }
    if (taxesList[0].value === "") {
      data = { ...data, TValueErr: "Tax value  is required" };
    } else {
      let { TValueErr, ...others } = data;
      data = { ...others };
    }
    if (address.trim().length === 0) {
      data = { ...data, AddressErr: "Address  is required" };
    } else {
      let { AddressErr, ...others } = data;
      data = { ...others };
    }
    if (longitude.trim().length === 0) {
      data = { ...data, LongErr: "Longitude is required" };
    } else {
      let { LongErr, ...others } = data;
      data = { ...others };
    }
    if (latitude.trim().length === 0) {
      data = { ...data, LatErr: "Longitude is required" };
    } else {
      let { LatErr, ...others } = data;
      data = { ...others };
    }
    if (password.trim().length === 0) {
      data = { ...data, PassErr: "Password is required" };
    } else {
      let { PassErr, ...others } = data;
      data = { ...others };
    }
    if (country.trim().length === 0) {
      data = { ...data, CountryErr: "Country is required" };
    } else {
      let { CountryErr, ...others } = data;
      data = { ...others };
    }
    setValidation({ ...data });
    return data;
  };
  const sliderColor = {
    backgroundColor: "#FFA382",
  };
  const handleAddClick = () => {
    setTaxesList([...taxesList, { taxType: "", value: "" }]);
  };
  const handleDeleteClick = () => {
    let taxesDetails = [...taxesList];
    taxesDetails.pop();
    setTaxesList(taxesDetails);
  };

  const setCountryAndGetState = (country) => {
    console.log(country, "country:");
    setCountry(country.split(",")[0]);
    setStatesList([]);
    setCitiesList([]);
    setStatesList(State.getStatesOfCountry(country.split(",")[1]));
  };

  const setStateAndGetCity = (state) => {
    console.log(state, "State");
    setState(state.split(",")[0]);
    setCitiesList(
      City.getCitiesOfState(state.split(",")[2], state.split(",")[1])
    );
  };

  const changePasswordVisibiltyMap = (name) =>
    setPasswordVisibilityMap({
      ...passwordVisibiltyMap,
      [name]: !passwordVisibiltyMap[name],
    });
  const changeValue = (name, value) => {
    setPassword(value);
  };

  const handleInputChange = (e, i) => {
    console.log("selected", e, i);

    let taxesDetails = [...taxesList];
    taxesDetails[i][e.target.name] = e.target.value;
    setTaxesList(taxesDetails);
  };
  const AddOutlet = async () => {
    try {
      const Error = validateForm();
      if (Object.keys(Error).length === 0) {
        let body = {
          outletName: outletName,
          outletSuperAdminID: admin[0].adminID,
          consumerId: admin[0].consumerId,
          adminName: adminName,
          adminEmail: adminEmail,
          phone: phone,
          longitude: longitude,
          latitude: latitude,
          address: address,
          taxes: taxesList,
          country: country,
          city: city,
          state: state,
          isActive: activeStatus,
        };

        const adding = await url.post(`oc`, body);
        if (adding.data.Message === "Success") {
          history.push("/outlets");
        }
        console.log(adding.data.Message, "added new outlet");
      }
    } catch (e) {
      console.log(e, "error on outlet");
    }
  };
  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <div className="cardOutlet">
            <div
              className="row"
              style={{
                background: "#fff",
                borderRadius: "8px",
                height: "100%",
                margin: "0",
              }}
            >
              <div
                style={{
                  height: "25px",
                  marginLeft: "-2px",
                  backgroundColor: "#FFA382",
                  marginTop: "5px",
                }}
              >
                <span style={{ color: "#FFA382" }}>.</span>
              </div>
              <div className="col-md-5">
                {" "}
                <div
                  style={{
                    color: "#FFA382",
                    fontSize: "20px",
                    fontWeight: "600",
                  }}
                >
                  Add Outlet
                </div>
              </div>
              <div
                className="col-md-12"
                style={{
                  borderTop: "1px solid #F1F5F9",
                  marginTop: "3px",
                  padding: "13px",
                }}
              >
                <div className="row">
                  {" "}
                  <div className="col-md-6 col-lg-6 mt-2 ">
                    <InputPrimary
                      label="Outlet Name *"
                      value={outletName}
                      onChange={(e) => {
                        setOutletName(e.target.value);
                      }}
                      text={true}
                    />
                    {validation.OutErr && (
                      <p style={{ color: theme.palette.action.danger }}>
                        {validation.OutErr}
                      </p>
                    )}
                  </div>
                  <div className="col-md-6 col-lg-6  mt-2 ">
                    <InputPrimary
                      label="Admin's Full Name *"
                      value={adminName}
                      onChange={(e) => {
                        setAdminName(e.target.value);
                      }}
                      text={true}
                    />
                    {validation.AdminErr && (
                      <p style={{ color: theme.palette.action.danger }}>
                        {validation.AdminErr}
                      </p>
                    )}
                  </div>
                  <div className="col-md-6 col-lg-6  mt-2  ">
                    <InputPrimary
                      label="Admin's Email Address *"
                      value={adminEmail}
                      onChange={(e) => {
                        setAdminEmail(e.target.value);
                      }}
                      text={true}
                    />
                    {validation.EmailErr && (
                      <p style={{ color: theme.palette.action.danger }}>
                        {validation.EmailErr}
                      </p>
                    )}
                  </div>
                  <div className="col-md-6 col-lg-6  mt-2 ">
                    <PhoneNumberInput
                      maxLength={10}
                      pattern="[0-9]*"
                      label="Phone Number"
                      setPhone={setPhone}
                      phone={phone}
                    />
                    {validation.PhoneErr && (
                      <p style={{ color: theme.palette.action.danger }}>
                        {validation.PhoneErr}
                      </p>
                    )}
                  </div>
                  <div className="col-md-6 col-lg-6  mt-2 ">
                    <InputPrimary
                      label="Address"
                      value={address}
                      onChange={(e) => {
                        setAddress(e.target.value);
                      }}
                      text={true}
                    />
                    {validation.AddressErr && (
                      <p style={{ color: theme.palette.action.danger }}>
                        {validation.AddressErr}
                      </p>
                    )}
                  </div>
                  <div className="col-md-6 col-lg-6  mt-2 ">
                    <CustomSelectPrimary
                      options={countries}
                      value={country}
                      label="Country"
                      setValue={setCountryAndGetState}
                    />
                    {validation.CountryErr && (
                      <p style={{ color: theme.palette.action.danger }}>
                        {validation.CountryErr}
                      </p>
                    )}
                  </div>
                  <div className="col-md-6 col-lg-6  mt-2  ">
                    <CustomSelectPrimary
                      options={statesList}
                      value={state}
                      setValue={setStateAndGetCity}
                      label="State"
                    />
                  </div>
                  <div className="col-md-6 col-lg-6  mt-2 ">
                    <CustomSelectPrimary
                      options={citiesList}
                      value={city}
                      label="City"
                      setValue={setCity}
                    />
                  </div>
                  <div className="col-md-6 col-lg-6  mt-2  ">
                    <InputPrimary
                      label="Longitude"
                      value={longitude}
                      onChange={(e) => setLongitude(e.target.value)}
                      text={true}
                    />
                    {validation.LongErr && (
                      <p style={{ color: theme.palette.action.danger }}>
                        {validation.LongErr}
                      </p>
                    )}
                  </div>
                  <div className="col-md-6 col-lg-6  mt-2  ">
                    <InputPrimary
                      label="Latitude"
                      value={latitude}
                      onChange={(e) => setLatitude(e.target.value)}
                      text={true}
                    />
                    {validation.LatErr && (
                      <p style={{ color: theme.palette.action.danger }}>
                        {validation.LatErr}
                      </p>
                    )}
                  </div>
                </div>
                <div
                  className="col-md-12 ml-auto mr-auto mt-4"
                  style={{ border: "1px solid #F1F5F9" }}
                ></div>
                <Typography className="mt-3" variant="h4">
                  Tax Details
                </Typography>
                {taxesList.map((tax, i) => {
                  return (
                    <div className="row mt-3">
                      <div className="col-md-6">
                        <InputPrimary
                          label="Tax Type"
                          name="taxType"
                          onChange={(e) => {
                            handleInputChange(e, i);
                          }}
                          value={tax.taxType}
                        />
                        {validation.TTypeErr && (
                          <p style={{ color: theme.palette.action.danger }}>
                            {validation.TTypeErr}
                          </p>
                        )}
                      </div>
                      <div className="col-md-6">
                        <InputPrimary
                          type="number"
                          label="Tax Value"
                          name="value"
                          onInput={(e) => {
                            if (
                              e.target.value?.toString()?.split(".")[1]
                                ?.length > 2
                            ) {
                              let value =
                                e.target.value.toString().split(".")[0] +
                                "." +
                                e.target.value
                                  .toString()
                                  .split(".")[1]
                                  .substring(0, 2);

                              e.target.value = value;
                            } else if (e.target.value.includes("-")) {
                              let value = 0;
                              e.target.value = value;
                              // setTaxValueError(
                              //   "Negative tax value not allowed"
                              // );
                            } else if (e.target.value > 99) {
                              // setTaxValueError(
                              //   "Tax value in 3 digits not allowed"
                              // );
                              let value = "";
                              e.target.value = value;
                            } else {
                              e.target.value.replace(/[^0-9]/gi, "");
                              // setTaxValueError("Incorrect tax value ");
                            }
                          }}
                          onChange={(e) => {
                            handleInputChange(e, i);
                          }}
                          text={true}
                        />
                        {validation.TValueErr && (
                          <p style={{ color: theme.palette.action.danger }}>
                            {validation.TValueErr}
                          </p>
                        )}
                      </div>
                    </div>
                  );
                })}
                <div className="d-flex">
                  <button
                    className="mt-2"
                    onClick={handleAddClick}
                    style={{
                      background: "none",
                      border: "none",
                      color: "#FFA382",
                      outline: "none",
                      cursor: "pointer",
                    }}
                  >
                    {" "}
                    <AddCircleIcon
                      style={{
                        position: "relative",
                        top: "0.2em",
                        right: "0.3em",
                        fontSize: "1.7em",
                      }}
                    />{" "}
                    Add Tax
                  </button>
                  {taxesList.length > 1 && (
                    <button
                      className="mt-2 "
                      onClick={handleDeleteClick}
                      style={{
                        background: "none",
                        border: "none",
                        color: "#FFA382",
                        outline: "none",
                        cursor: "pointer",
                        marginLeft: "3px",
                      }}
                    >
                      {" "}
                      <RemoveCircleIcon
                        style={{
                          position: "relative",
                          top: "0.2em",
                          right: "0.3em",
                          fontSize: "1.7em",
                        }}
                      />{" "}
                      Delete Tax
                    </button>
                  )}
                </div>

                <div
                  className="col-md-12 ml-auto mr-auto mt-4"
                  style={{ border: "1px solid #F1F5F9" }}
                ></div>
                <Typography className="mt-3" variant="h4">
                  Password
                </Typography>
                <div className="col-md-6 mt-3">
                  <PasswordInput
                    name="newPassword"
                    label="Password"
                    changeVisibility={changePasswordVisibiltyMap}
                    visibility={passwordVisibiltyMap}
                    value={password}
                    isShow={true}
                    change={changeValue}
                  />
                  {validation.PassErr && (
                    <p style={{ color: theme.palette.action.danger }}>
                      {validation.PassErr}
                    </p>
                  )}
                </div>
                <div
                  className="col-md-12 ml-auto mr-auto mt-4"
                  style={{ border: "1px solid #F1F5F9" }}
                ></div>
                <Typography className="mt-3" variant="h4">
                  Status
                </Typography>
                <div className="col-md-8 switch-col mt-3">
                  <label className="switch  switch-demo">
                    <input
                      type="checkbox"
                      checked={activeStatus}
                      onChange={() => setActiveStatus(!activeStatus)}
                    />
                    <span
                      className="slider round"
                      style={activeStatus ? sliderColor : null}
                    ></span>
                    <span
                      className="slider-text"
                      style={
                        activeStatus
                          ? { paddingLeft: "81px", color: "#FFA382" }
                          : { paddingLeft: "81px", color: "red" }
                      }
                    >
                      {activeStatus ? "Active" : "Inactive"}
                    </span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className=" d-flex col-md-12 mt-3 justify-content-end ">
        <button className="btn  globalButton" onClick={() => AddOutlet()}>
          <i className="fas fa-plus-circle"></i>
          Add Outlet
        </button>
      </div>
    </div>
  );
}
