import React from "react";
import styled from "@emotion/styled";
import { useTheme } from "@mui/material";

const InputPrimary = ({
  label,
  disabled = false,
  changeVisibility,
  name,
  visibility,
  change,
  isShow,
  ...props
}) => {
  const theme = useTheme();
  return (
    <Container theme={theme} disabled={disabled}>
      <label>{label}</label>
      <div className="seconDiv">
        <input
          name={name}
          type={!isShow ? "password" : visibility[name] ? "password" : "text"}
          onChange={(e) => change(e.target.name, e.target.value)}
          disabled={disabled}
          {...props}
        />
        <i
          className={
            !isShow
              ? "fa fa-eye-slash"
              : visibility[name]
              ? "fa fa-eye"
              : "fa fa-eye-slash"
          }
          style={{
            color: "#bababa",
            cursor: "pointer",
          }}
          onClick={() => changeVisibility(name)}
        ></i>
      </div>
    </Container>
  );
};

const Container = styled.div`
  margin-top: 5px;
  label {
    color: #747474;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1rem;
    display: block;
  }
  .seconDiv {
    display: flex;
    width: 100%;
    align-items: center;
    background: ${(props) =>
      props.disabled ? props.theme.palette.gray.disabled : "transparent"};
    border: 1px solid #e2e8f0;
    border-radius: 5px;
    padding: 12px 16px;
  }
  input {
    display: block;
    width: 100%;
    outline: none;
    border: none;
    background: ${(props) =>
      props.disabled ? props.theme.palette.gray.disabled : "transparent"};
    font-size: 0.938rem;
    box-sizing: border-box;
    border-radius: 5px;
  }
`;
export default InputPrimary;
