import "./LoginPageContainer.css";
import "./LoginPage.styles.css";

import brandImage from "../../assets/brandImage.png"
import LoginPageForm from "./LoginPageForm";
const LoginPageContainer = ({ rememberMeState }) => {
    return (
        <div className="login-page-v1-container">
            <div className="login-page-v1-logo-container">
                <div className="login-page-v1-logo-image-container">
                    <img
                        src={brandImage}
                        alt="bleaum logo"
                        style={{
                            height: "8vw",
                        }}
                    />
                </div>
                <div className="login-page-v1-shadow-ball1"></div>
                <div className="login-page-v1-shadow-ball2"></div>
                <div className="login-page-v1-shadow-ball3"></div>
            </div>
            <div className="login-page-v1-form-holder">
                <LoginPageForm rememberMeState={rememberMeState} />
            </div>
        </div>
    )
}
export default LoginPageContainer