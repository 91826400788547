/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect, useRef } from "react";
import AddLoyaltypoints from "../../components/AddLoyalty/Addloyalty";
import { useHistory } from "react-router-dom";
import url from "../../config/axios";
import Success from "../../assets/Success1.png";
import Cross from "../../assets/cross.png";
import Failure from "../../assets/failure.png";
import Popup from "reactjs-popup";
import styled from "@emotion/styled";
const PopUpDiv = styled.div`
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`;
function Loyalty() {
  const ref = useRef();
  const notRef = useRef();
  const history = useHistory();
  const notOpenToolTip = () => notRef.current.open();
  const closenotToolTip = () => notRef.current.close();
  const openTooltip = () => ref.current.open();
  const closeTooltip = () => {
    ref.current.close();
  };
  const [formValidationErrors, setFormvalidationErrors] = useState({});
  const [days, setDays] = useState({
    MON: [],
    TUES: [],
    WED: [],
    THURS: [],
    FRI: [],
    SAT: [],
    SUN: [],
  });
  const Name = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  // Setting the days that are being passed from child to parent
  const setNewDays = (value, key) => {
    let tempDays = { ...days };
    tempDays[key] = value;
    setDays(tempDays);
  };
  // Api calls to be made in this file
  // <----------!!!!!------->
  // Get loyalty api
  useEffect(() => {
    getLoyalty();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getLoyalty = async () => {
    try {
      const getLoyaltyPoint = await url.get("/v1/loyalty", {
        slots: days,
      });

      let data = getLoyaltyPoint.data.data.loyalty.slots;
      console.log(
        getLoyaltyPoint.data.data.loyalty,
        "Data of getting from api"
      );
      setDays({ ...data });
    } catch (e) {
      console.log(e);
    }
  };
  // Update and add loyaltypoint api
  const addUpdateLoyalty = async () => {
    try {
      const addUpdate = await url.put("/v1/loyalty/update-slots", {
        slots: days,
      });

      let data = addUpdate.data.data.loyalty.slots;
      setDays({ ...data });
      openTooltip();
      setFormvalidationErrors({});
      getLoyalty();
    } catch (e) {
      notOpenToolTip();
      if (e?.response?.status === 422) {
        let data = { ...formValidationErrors };
        // Object.values
        if ("body.slots.MON" in e?.response?.data?.data) {
          data = {
            ...data,
            mon: "Invalid or conflicting time Slots in Monday",
          };
        } else {
          const { mon, ...others } = data;
          data = { ...others };
        }
        if ("body.slots.TUES" in e?.response?.data?.data) {
          data = {
            ...data,
            tues: "Invalid or conflicting time Slots in Tuesday",
          };
        } else {
          const { tues, ...others } = data;
          data = { ...others };
        }
        if ("body.slots.WED" in e?.response?.data?.data) {
          data = {
            ...data,
            wed: "Invalid or conflicting time Slots in Wednesday",
          };
        } else {
          const { wed, ...others } = data;
          data = { ...others };
        }
        if ("body.slots.THURS" in e?.response?.data?.data) {
          data = {
            ...data,
            thurs: "Invalid or conflicting time Slots in Thurday",
          };
        } else {
          const { thurs, ...others } = data;
          data = { ...others };
        }
        if ("body.slots.FRI" in e?.response?.data?.data) {
          data = { ...data, fri: "Invalid or conflictin time Slots in Friday" };
        } else {
          const { fri, ...others } = data;
          data = { ...others };
        }
        if ("body.slots.SAT" in e?.response?.data?.data) {
          data = {
            ...data,
            sat: "Invalid or conflictin time Slots in Saturday",
          };
        } else {
          const { sat, ...others } = data;
          data = { ...others };
        }
        if ("body.slots.SUN" in e?.response?.data?.data) {
          data = { ...data, sun: "Invalid or conflictin time Slots in Sunday" };
        } else {
          const { sun, ...others } = data;
          data = { ...others };
        }
        setFormvalidationErrors({ ...data });
        return data;
      }
      console.log(e?.response?.data?.data, "Error here");
    }
  };

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="card ">
          <div
            className="row"
            style={{
              borderBottom: "2px solid #F1F5F9",
              paddingBottom: "10px",
              borderBottomRightRadius: "0px",
              borderBottomLeftRadius: "0px",
            }}
          >
            <div className="col-md-12">
              <div className="row">
                <div
                  style={{
                    height: "25px",
                    marginLeft: "-32px",
                    backgroundColor: "#FFA382",
                    marginTop: "2px",
                  }}
                >
                  <span style={{ color: "#FFA382" }}>.</span>
                </div>
                <div className="col-md-3">
                  <div
                    style={{
                      color: "#FFA382",
                      fontSize: "20px",
                      fontWeight: "600",
                    }}
                  >
                    Loyalty points
                  </div>
                </div>
                <div className="col-md-4 filters-bar">
                  <div className="dropdown"></div>
                  <div className="dropdown">
                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuLink"
                    >
                      <a className="dropdown-item" href="/">
                        Action
                      </a>
                      <a className="dropdown-item" href="/">
                        Another action
                      </a>
                      <a className="dropdown-item" href="/">
                        Something else here
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-md-3"></div>
                <div className="col-md-2 filters-bar">
                  <button
                    onClick={() => history.push("/Specializedloyalty")}
                    className="btn btn-primary border-0"
                    style={{ backgroundColor: "#FFA382" }}
                  >
                    <i className="fas fa-plus-circle"></i>
                    Add Points
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* Next component here */}
          <div
            style={{
              borderBottomRightRadius: "0px",
              borderBottomLeftRadius: "0px",
              borderRadius: "2px",
              borderBottom: "2px solid #F1F5F9",
              paddingBottom: "10px",
              margin: "0",
              padding: "2rem",
              height: "100%",
              background: "#fff",
              backdropFilter: " blur(10px)",
            }}
          >
            {Object.keys(days).map((item, key) => {
              console.log(item);
              return (
                <AddLoyaltypoints
                  daySlots={days[item]}
                  days={item}
                  Name={Name}
                  index={key}
                  onChange={setNewDays}
                  validation={formValidationErrors}
                />
              );
            })}
          </div>
        </div>
      </div>
      <div
        className="d-flex col-md-12"
        style={{
          justifyContent: "flex-end",
          marginTop: "5px",
          marginLeft: "11px",
        }}
      >
        <div style={{ borderRadius: "5px", padding: "6px" }}>
          <button
            className="btn btn-primary border-0"
            style={{ backgroundColor: "#FFA382" }}
            onClick={() => {
              addUpdateLoyalty();
            }}
          >
            Update
          </button>
        </div>
      </div>
      <Popup ref={ref} id="latestPopup" className="myPopup" position="center">
        <div style={{ height: " 30vh " }}>
          <div style={{ textAlign: "right" }}>
            <img
              onClick={() => {
                closeTooltip();
              }}
              src={Cross}
            />
          </div>
          <PopUpDiv>
            <div>
              <img height="60" src={Success} />
            </div>
            <div className="mt-3 mb-3">
              <span
                style={{
                  marginTop: "20px",
                  color: "#192746",
                  fontSize: "18px",
                }}
              >
                Updated Successfully
              </span>
              <p style={{ color: "#747474" }}>Loyaltypoints updated </p>
            </div>
            <button
              type="button"
              onClick={() => {
                closeTooltip();
              }}
              className="btn btn-primary border-0"
              style={{
                backgroundColor: "#FFA382",
                marginTop: "0",
                width: "20vh",
                alignSelf: "center",
              }}
            >
              Close
            </button>
          </PopUpDiv>
        </div>
      </Popup>
      <Popup
        ref={notRef}
        id="latestPopup"
        className="myPopup"
        position="center"
      >
        <div style={{ height: " 30vh " }}>
          <div style={{ textAlign: "right" }}>
            <img
              onClick={() => {
                closenotToolTip();
              }}
              src={Cross}
            />
          </div>
          <PopUpDiv>
            <div>
              <img src={Failure} />
            </div>
            <div className="mt-3 mb-3">
              <span
                style={{
                  marginTop: "20px",
                  color: "#192746",
                  fontSize: "18px",
                }}
              >
                Something Went Wrong
              </span>
              <p style={{ color: "#747474" }}>
                Please enter the correct details to proceed
              </p>
            </div>
            <button
              type="button"
              onClick={() => {
                closenotToolTip();
              }}
              className="btn btn-primary border-0"
              style={{
                backgroundColor: "#FFA382",
                marginTop: "0",
                width: "20vh",
                alignSelf: "center",
              }}
            >
              ok
            </button>
          </PopUpDiv>
        </div>
      </Popup>
    </div>
  );
}

export default Loyalty;
