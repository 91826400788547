import styled from "@emotion/styled"

export const colors = {
    accent: "#FFA382",
    accentHover: '#ff7c4d',
    searchColor: '#747474'
}
export const MultipleFlexibleColumns = styled.div`
    display: grid;
    grid-gap: ${props => props.gap ?? '0px'};
    grid-template-columns: repeat(auto-fit, minmax(${props => props.width ?? '250px'}, 1fr));
    grid-template-rows: auto;
`
export const AutoFlow = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: ${props => props.gap ?? '0px'};    
`
export const DoubleColumn = styled.div`
    width: 100%;
    display: flex;
    gap: ${props => props.gap ?? '0px'};
    div:nth-child(1) {
        flex: ${props => props.left ? props.left : '1'};
    }
    div:nth-child(2) {
        flex: ${props => props.right ? props.right : '1'};
    }
`
export const ButtonPrimary = styled.button`
    border: none;
    outline: none;
    color: white;
    padding: 11px 41px;
    border-radius: 5px;
    background-color: ${colors.accent};
    cursor: pointer;
    transition: background-color .3s ease-out;
    &:hover {
        background-color: ${colors.accentHover};
    }
`