import {
  combineReducers,
  configureStore,
  getDefaultMiddleware,
} from "@reduxjs/toolkit";
import { default as authReducer } from "./slices/authSlice";
import { default as ocSwitchReducer } from "./slices/ocSwitchSlice";
import { default as socketReducer } from "./slices/socketSlice";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import messageSessionReducer from "./slices/messageSessionSlice";
import chatSessionReducer from "./slices/chatSessionSlice";
import sendBoxReducer from "./slices/sendBoxSlice";
import sessionSearchReducer from "./slices/sessionSearchSlice";

const reducers = combineReducers({
  auth: authReducer,
  ocSwitch: ocSwitchReducer,
  socket: socketReducer,
  messageSession: messageSessionReducer,
  chatSession: chatSessionReducer,
  sendBox: sendBoxReducer,
  sessionSearch: sessionSearchReducer,
});

const persistConfig = {
  key: "root",
  storage: storage,
  whitelist: ["auth"],
};
const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV === "development",
  middleware: getDefaultMiddleware({
    // serializableCheck: {
    //   ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    // },
    serializableCheck: false,
  }),
});

export const persistor = persistStore(store);
