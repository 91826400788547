/* eslint-disable no-lone-blocks */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import "./Categories.css";
import { useEffect, useState, useCallback, useRef, useMemo } from "react";
import url from "../../config/axios";
import storage from "../../config/firebase";
// import moment from "moment";
import Cropper from "react-easy-crop";
import Slider from "@mui/material/Slider";
import getCroppedImg from "../../helpers/cropImage";
import Dialog from "@mui/material/Dialog";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Popup from "reactjs-popup";
import upload from "../../assets/upload.svg";
import sort from "../../assets/sort.svg";
import debounce from "../../helpers/debounce";
import Pagination from "@mui/material/Pagination";
import { serializeQueryStringsFromObject } from "../../helpers/serializeToQueryStrings";
import { useHistory, useLocation } from "react-router-dom";
import InvokeLayoutComponent from "../common/InvokeLogout";
import { makeStyles } from "@material-ui/core/styles";
import Table from "./components/Table";
import { colors } from "../../Style.style";

const useStyles = makeStyles({
  root: {
    "& .Mui-selected": {
      backgroundColor: "#FFA382 !important",
    },
    "& .MuiPaginationItem-root": {
      borderRadius: "5px !important",
    },
  },
});
const Categories = () => {
  const classes = useStyles();

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [showCrop, setShowCrop] = useState(false);
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const history = useHistory();
  const [categories, setCategories] = useState([]);
  const [editBoolean, setEditBoolean] = useState(false);
  const [showComponent, setComponent] = useState(false);
  const [parentCategory, setParentCategory] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [categoryID, setCategoryID] = useState("");
  const [status, setStatus] = useState(false);
  const [slug, setSlug] = useState("");
  const [image, setImage] = useState("");
  const [image1, setImage1] = useState("");
  const [file, setFile] = useState("");
  const notFoundRef = useRef();
  const [showImage, setShowImage] = useState(false);
  const [showImage1, setShowImage1] = useState(false);
  const [outlets, setOutlets] = useState([]);
  const [outletID, setOutletID] = useState("");
  const [selectOutlet, setSelectOutlet] = useState("");
  const [outletError, setOutletError] = useState("");
  const admin = JSON.parse(localStorage.getItem("Admin")) || null;
  const [unfiltered, setUnfiltered] = useState([]);

  const color = ["#03b7a5", "#fc3986", "#ab48d5", "#2bb72b", "#bdd72a"];

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(
    async (e) => {
      e.preventDefault();
      try {
        const croppedImage = await getCroppedImg(image, croppedAreaPixels, 0);
        // console.log("donee", { croppedImage });
        localStorage.setItem("ImageSet", croppedImage);
        setImage(croppedImage);
        setShowImage(true);
        setShowCrop(false);
        setOpen(false);
      } catch (e) {
        console.error(e);
      }
    },
    [croppedAreaPixels, image]
  );

  const updateCategory = async (blob) => {
    // console.log("Blob is: ", blob);
    await url
      .put(`/category/?categoryID=${categoryID}`, {
        parentCategory: parentCategory,
        name: categoryName,
        slug: slug,
        image: blob,
        status: status,
      })
      .then((res) => {
        // console.log("Updated data is: ", res.data);
        if (res.data.Message === "Success") {
          setParentCategory("");
          setCategoryName("");
          setSlug("");
          setComponent(false);
          setEditBoolean(false);
          setImage("");
          setFile("");
          setCategories([
            ...categories.map((obj) =>
              obj?.categoryID === res?.data?.data?.categoryID
                ? res?.data?.data
                : obj
            ),
          ]);
        } else {
          setParentCategory("");
          setCategoryName("");
          setSlug("");
          setFile("");
          setComponent(false);
          setEditBoolean(false);
          setImage("");
          alert("Error: Something went wrong");
        }
      })
      .catch((e) => console.log(e));
  };

  const editCategory = (category) => {
    localStorage.setItem("category", JSON.stringify(category));
    console.log(category);
    // console.log('category', category);
    setCategoryID(category.categoryID);
    setParentCategory(
      category.parentCategory === ""
        ? "Select Parent Category"
        : category.parentCategory
    );
    setCategoryName(category?.name ?? "");
    setSlug(category?.slug ?? "");
    setImage(category.image);
    setStatus(category?.status);
    setEditBoolean(true);
    setComponent(!showComponent);
  };

  const imageSelector = (event) => {
    if (event.target.files[0]) {
      setImage(URL.createObjectURL(event.target.files[0]));
      setFile(event.target.files[0]);
      setOpen(true);
      setShowCrop(true);
    }
  };

  const closeComp = () => {
    setImage("");
    setShowImage(false);
    setShowImage1(false);
    setComponent(false);
  };

  const fileUpdate = () => {
    // const storageRef = ref(storage, `images/${file.name}`);
    if (file !== "") {
      const uploadTask = storage
        .ref(`images/${file.name}`)
        .putString(localStorage.getItem("ImageSet"), "data_url");

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // const progress = Math.round(
          //   (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          // );
        },
        async (error) => {
          // console.log(error);
          await updateCategory("");
        },
        () => {
          storage
            .ref("images")
            .child(file.name)
            .getDownloadURL()
            .then(async (blob) => {
              // console.log(blob);
              await updateCategory(blob);
            });
        }
      );
    } else {
      updateCategory(image);
    }
  };

  const [invokeLogOut, setInvokeLogout] = useState(false);
  const initialPaginationData = {
    page: 1,
    limit: 100,
    latestFirst: 1,
    search: "",
  };
  const [queryData, setQueryData] = useState({
    ...initialPaginationData,
  });
  const [searchTerm, setSearchTerm] = useState("");
  const [previousSearchTerm, setPreviousSearchTerm] = useState("");
  //whole editable object will be place here
  const [currentEditingIndex, setCurrentEditingIndex] = useState(null);
  const performSearch = debounce(() => {
    if ((searchTerm + "").trim() !== (previousSearchTerm + "").trim()) {
      setPreviousSearchTerm(searchTerm);
      handleChange("search", searchTerm, true);
    } else if (searchTerm === "") {
      setPreviousSearchTerm("");
      handleChange("search", "", true);
    }
  }, 1500);
  useEffect(() => {
    if (admin[0].isSubOutlet === true) {
      GetAllCategories();
    } else {
      GetAllOutlets();
    }
  }, []);

  useEffect(
    () => {
      if (parsed && !loading) {
        performSearch();
      }
    },
    //eslint-disable-next-line
    [searchTerm]
  );
  //parse passed queries from the URL first
  const { search } = useLocation();
  const [parsed, setParsed] = useState(false);
  useEffect(
    () => {
      const query = new URLSearchParams(search);

      let page =
        Number.isNaN(query.get("page")) || !query.get("page")
          ? 1
          : +query.get("page");
      let limit =
        Number.isNaN(query.get("limit")) || !query.get("limit")
          ? 30
          : +query.get("limit");
      let latestFirst =
        Number.isNaN(query.get("latestFirst")) || !query.get("latestFirst")
          ? 1
          : +query.get("latestFirst");
      let searchParams = query.get("search");
      let queryParams = {
        ...queryData,
        page,
        limit,
        latestFirst,
        search: searchParams,
      };
      setSearchTerm(searchParams);
      setQueryData({ ...queryParams });
      setParsed(true);
    },
    //eslint-disable-next-line
    []
  );

  const [paginationData, setPaginationData] = useState({
    currentPage: 1,
    perPage: 30,
    totalPages: 0,
    total: 0,
  });
  const [loading, setLoading] = useState(true);

  const [formValidationError, setFormValidationErrors] = useState({});
  const validateForm = () => {
    let data = { ...formValidationError };
    if (categoryName.trim().length === 0) {
      data = { ...data, catError: "Name of the category is required" };
    } else {
      let { catError, ...others } = data;
      data = { ...others };
    }
    setFormValidationErrors(data);
  };
  useEffect(() => {
    validateForm();
  }, [categoryName, slug]);

  //call to fetch data after parsing
  // useEffect(
  //   () => {
  //     if (parsed) {
  //       GetAllCategories({ ...queryData });
  //     }
  //   },
  //   //eslint-disable-next-line
  //   [parsed, queryData]
  // );

  /**
   *
   * @param {string} propName property to change
   * @param {*} propValue corresponding value of that property
   * @param {*} flush changes every other values to initial data
   */
  const handleChange = (propName, propValue, flush = false) => {
    if (currentEditingIndex === null) {
      let query = {};
      if (flush) {
        query = {
          ...initialPaginationData,
          [propName]: propValue,
        };
      } else {
        query = { ...queryData, [propName]: propValue };
      }
      const queryString = serializeQueryStringsFromObject(query);
      history.push(`/categories${queryString}`);
      setQueryData({ ...query });
    }
  };

  const GetAllCategories = () => {
    setLoading(true);
    let queryParams = { ...queryData };
    //sanity
    Object.keys(queryParams).forEach((key) => {
      if (!queryParams[key]) {
        delete queryParams[key];
      }
    });
    url
      .get(`/v2/admin/categories/paginated`, {
        params: {
          ...queryParams,
        },
      })
      .then(async (res) => {
        // console.log('res', res)
        if (res.data.Message === "Success") {
          if (!res.data?.data?.categories?.length) {
            notFoundRef.current.open();
          }
          // hash map to filter countInventory from categories
          let countInventoryMap = [];
          res.data.data.categories.map((item) => {
            if (item.countInventory) {
              // category should not visible if countInventory is 0
              item.countInventory.map((count) => {
                if (
                  count.count > 0 &&
                  count.outletChainID === admin[0].outletChainID
                ) {
                  countInventoryMap.push(item);
                }
              });
            }
          });
          console.log("map", countInventoryMap);
          setCategories(countInventoryMap);
          setUnfiltered(countInventoryMap);
          console.log(res?.data?.data?.paginationData);
          setPaginationData({
            ...paginationData,
            ...res?.data?.data?.paginationData,
          });
        } else {
          console.error(res);
        }
        setLoading(false);
      })
      .catch((e) => {
        if (+e?.response?.status === 401) {
          setInvokeLogout(true);
        }
        setLoading(false);
        console.error(e?.response);
      });
  };
  //clientSide search
  useEffect(
    () => {
      if (searchTerm?.length !== 0) {
        console.log(unfiltered, "unfiltered");
        let filtered = unfiltered.filter((element) =>
          element.name?.toLowerCase().includes(searchTerm?.trim().toLowerCase())
        );
        setCategories(filtered);
        // if (filtered.length === 0) {
        //   openNotFoundPopUp();
        // }
      } else {
        setCategories(unfiltered);
      }
    },
    // eslint-disable-next-line
    [searchTerm]
  );
  const GetAllCategoriesById = (outletId) => {
    setOutletID(outletId);
    setLoading(true);
    let queryParams = { ...queryData };
    //sanity
    Object.keys(queryParams).forEach((key) => {
      if (!queryParams[key]) {
        delete queryParams[key];
      }
    });
    url
      .get(`/v2/admin/categories/paginated`, {
        params: {
          ...queryParams,
        },
      })
      .then(async (res) => {
        // console.log('res', res)
        if (res.data.Message === "Success") {
          if (!res.data?.data?.categories?.length) {
            notFoundRef.current.open();
          }
          console.log("res", res.data.data.categories);
          // hash map to filter countInventory from categories
          let countInventoryMap = [];
          res.data.data.categories.map((item) => {
            if (item.countInventory) {
              // category should not visible if countInventory is 0
              item.countInventory.map((count) => {
                if (count.count > 0 && count.outletChainID === outletId) {
                  countInventoryMap.push(item);
                }
              });
            }
          });
          console.log("map", countInventoryMap);
          setCategories(countInventoryMap);
          setUnfiltered(countInventoryMap);
          console.log(res?.data?.data?.paginationData);
          setPaginationData({
            ...paginationData,
            ...res?.data?.data?.paginationData,
          });
        } else {
          console.error(res);
        }
        setLoading(false);
      })
      .catch((e) => {
        if (+e?.response?.status === 401) {
          setInvokeLogout(true);
        }
        setLoading(false);
        console.error(e?.response);
      });
  };

  let num = 0;

  function assignColor() {
    var random = Math.floor(Math.random() * color.length);
    while (num === random) {
      random = Math.floor(Math.random() * color.length);
    }
    num = random;
    var value = color[random];
    console.log(value, "VALUES");
    return value;
  }
  const Tablenew = useMemo(
    () => (
      <Table
        categories={categories}
        assignColor={assignColor}
        editCategory={editCategory}
      />
    ),
    [categories]
  );

  const GetAllOutlets = () => {
    const adminID = JSON.parse(localStorage.getItem("Admin"));
    // console.log(adminID[0].adminID);
    url
      .get(`/oc/?consumerId=${adminID[0].consumerId}`)
      .then(async (res) => {
        // console.log('res', res)
        setOutlets(res.data.data);
      })
      .catch((e) => console.log(e));
  };

  return (
    <div className="row">
      <div className="col-md-8">
        <div className="card card-cat">
          <div className="row" style={{ borderRadius: "13px" }}>
            <div className="col-md-12">
              <div className="row">
                <div
                  style={{
                    height: "25px",
                    marginLeft: "-32px",
                    backgroundColor: "#FFA382",
                    marginTop: "5px",
                  }}
                >
                  <span style={{ color: "#FFA382" }}>.</span>
                </div>
                <div className="col-md-8 d-flex">
                  <div style={{ color: "#FFA382", fontSize: "20px" }}>
                    Categories
                  </div>
                  {!admin[0].isSubOutlet && (
                    <>
                      <div
                        className="input-group"
                        style={{
                          width: "100%",
                        }}
                      >
                        <select
                          style={{
                            marginLeft: "10px",
                          }}
                          className="newInputSelect w-75"
                          onChange={(e) => GetAllCategoriesById(e.target.value)}
                          id="exampleFormControlSelect1"
                        >
                          <option value="select">Select Outlet</option>
                          {outlets.map((outlet, i) => {
                            return (
                              <option value={outlet.outletChainID} key={i}>
                                {outlet.outletName}
                              </option>
                            );
                          })}
                        </select>
                        {outletID === "" ? (
                          <p className="pl-3" style={{ color: "red" }}>
                            Please select outlet
                          </p>
                        ) : null}
                      </div>
                    </>
                  )}
                </div>

                <div
                  className={!admin[0].isSubOutlet ? "col-md-4" : "col-md-4"}
                >
                  <div className="input-group">
                    <input
                      type="text"
                      style={{ borderRight: "none" }}
                      className="form-control"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      placeholder="Search Categories ..."
                      aria-label="Input group example"
                      aria-describedby="btnGroupAddon"
                    />
                    <div className="input-group-prepend">
                      <div
                        className="input-group-text newSearchIcon"
                        id="btnGroupAddon"
                      >
                        <i
                          style={{ color: colors.searchColor }}
                          className="fas fa-search"
                        ></i>
                      </div>
                    </div>
                  </div>
                </div>
                {/* 
                <div className="col-md-1 pagination" style={{ justifyContent: "space-evenly" }}>
                  <img src={sort} />


                </div> */}
              </div>
            </div>
            <div
              className="col-md-12"
              style={{
                padding: "0px",
                marginTop: "-1em",
              }}
            >
              {Tablenew}
              <div
                className="d-flex col-md-12"
                style={{
                  justifyContent: "flex-end",
                  marginTop: "5px",
                  marginLeft: "13px",
                }}
              >
                <div
                  style={{
                    backgroundColor: "white",
                    borderRadius: "5px",
                    padding: "6px",
                  }}
                >
                  <Pagination
                    className={classes.root}
                    onChange={(e, value) => {
                      handleChange("page", value);
                    }}
                    page={paginationData.currentPage}
                    count={paginationData.totalPages ?? 0}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-4">
        {/* {showComponent && <AddCategory editBoolean={editBoolean} setEditBoolean={setEditBoolean} /> } */}
        {showComponent && (
          <div className="card">
            <div
              style={{
                position: "fixed",
                right: "10px",
                width: "26%",
                height: "74vh",
                borderRadius: "5px",
              }}
            >
              <div
                className="d-flex"
                style={{ justifyContent: "space-between" }}
              >
                <div
                  className="d-flex"
                  style={{ justifyContent: "flex-start" }}
                >
                  <div
                    style={{
                      // position: "absolute",
                      height: "40px",
                      marginLeft: "-32px",
                      backgroundColor: "#FFA382",
                    }}
                  >
                    <span style={{ color: "#FFA382" }}>a</span>
                  </div>
                  <h4 style={{ marginLeft: "5px", marginTop: "5px" }}>
                    {editBoolean ? "Edit Categories" : "Add Categories"}
                  </h4>
                </div>
                <div>
                  <button
                    className="btn close-btn latestCross"
                    onClick={closeComp}
                  >
                    <i style={{ color: "white" }} className="fas fa-times"></i>
                  </button>
                </div>
              </div>

              <br></br>
              <form
                className="parent-form"
                onSubmit={(e) => e.preventDefault()}
              >
                {/* <div className="group-comb">
                  <div className="form-group">
                    <label htmlFor="exampleFormControlSelect1">
                      Parent Category
                    </label>
                    <select
                      onChange={(e) => setParentCategory(e.target.value)}
                      className="form-control"
                      id="exampleFormControlSelect1"
                    >
                      <option value="">
                        {editBoolean
                          ? parentCategory
                          : "Select Parent Category"}
                      </option>
                      {categories.map((category, i) => (
                        <option key={i} value={category.name}>
                          {category.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div> */}

                <div className="group-comb">
                  <span style={{ color: "#747474" }}>Category Name*</span>
                  <div style={{ borderRadius: "1px !important" }}>
                    <input
                      type="text"
                      value={categoryName}
                      disabled={true}
                      onChange={(e) => setCategoryName(e.target.value)}
                      className="form-control"
                      id="exampleFormControlInput1"
                      placeholder="Category Name"
                    />
                    {!!formValidationError.catError && (
                      <span
                        className="validation-help"
                        style={{ color: "red", margin: "0" }}
                      >
                        {formValidationError.catError}
                      </span>
                    )}
                  </div>
                </div>

                <div
                  className="group-comb"
                  style={{
                    borderRadius: "3px !important",
                    backgroundColor: "white",
                  }}
                >
                  <div
                    className=" img-drop"
                    style={{
                      borderRadius: "3px !important",
                      backgroundColor: "white",
                      border: "1px solid #E2E8F0",
                    }}
                  >
                    {showImage ? (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          height: "100%",
                          justifyContent: "space-evenly",
                        }}
                      >
                        <div style={{ width: "15%" }}></div>
                        <div style={{ marginTop: "20px" }}>
                          <img
                            style={{ alignSelf: "center" }}
                            alt=""
                            width="150"
                            height="120"
                            src={image}
                            className="rounded mx-auto "
                          ></img>
                        </div>
                        <label className="" style={{ border: "none" }}>
                          <input
                            type="file"
                            id="image"
                            name="Image"
                            onChange={imageSelector}
                            accept="image/png, image/jpeg"
                          />
                          <div
                            style={{
                              color: "#FFA382",
                              justifyContent: "flex-end",
                            }}
                          >
                            <i
                              style={{ color: "#FFA382", marginRight: "3px" }}
                              className="fas fa-pen"
                            ></i>
                            <u>Edit</u>
                          </div>
                        </label>
                      </div>
                    ) : (
                      <>
                        <div
                          style={{
                            width: "30%",
                            height: "auto",
                            margin: " 0 auto",
                            padding: "10px",
                            position: "relative",
                          }}
                        >
                          <img
                            style={{
                              width: "100%",
                              height: "40px",
                              margin: " 0 auto",
                              position: "relative",
                            }}
                            src={upload}
                          />
                        </div>

                        <p style={{ textAlign: "center" }}>
                          Upload Category Image
                          <p
                            style={{
                              fontSize: "11px",
                              color: "rgba(116, 116, 116, 0.5)",
                            }}
                          >
                            Files include PNG, JPEG
                          </p>
                        </p>
                        <label
                          className="custom-file-upload"
                          style={{ border: "none" }}
                        >
                          <input
                            type="file"
                            id="image"
                            name="Image"
                            onChange={imageSelector}
                            accept="image/png, image/jpeg"
                          />

                          <p
                            style={{
                              backgroundColor: "#FFA382",
                              border: "1px solid #FFA382",
                              width: "60%",
                              color: "white",
                              borderRadius: "5px",
                              paddingLeft: "2em",
                              paddingRight: "2em",
                              paddingTop: "0.5em",
                              paddingBottom: "0.5em",
                              marginLeft: "auto",
                              marginRight: "auto",
                            }}
                          >
                            Choose File
                          </p>
                        </label>
                      </>
                    )}

                    {showCrop && (
                      <Dialog
                        fullScreen={fullScreen}
                        open={open}
                        fullWidth={true}
                        onClose={() => setOpen(false)}
                        aria-labelledby="responsive-dialog-title"
                      >
                        <div
                          className="App"
                          style={{
                            padding: "1em",
                            width: "auto",
                            height: "auto",
                          }}
                        >
                          <div className="crop-container">
                            <Cropper
                              image={image}
                              crop={crop}
                              zoom={zoom}
                              aspect={4 / 3}
                              onCropChange={setCrop}
                              onCropComplete={onCropComplete}
                              onZoomChange={setZoom}
                            />
                          </div>
                          <div className="controls">
                            <Slider
                              value={zoom}
                              min={1}
                              max={3}
                              step={0.1}
                              aria-labelledby="Zoom"
                              onChange={(e, zoom) => setZoom(zoom)}
                              // classes={{ root: "slider" }}
                            />
                          </div>
                          <div>
                            <button
                              className="btn btn-primary crop-btn"
                              onClick={(e) => showCroppedImage(e)}
                              style={{
                                textAlign: "center",
                                width: "auto",
                                padding: "10px 30px",
                              }}
                            >
                              Crop
                            </button>
                          </div>
                        </div>
                      </Dialog>
                    )}
                  </div>
                </div>

                {/* <div className="group-comb" style={{ borderRadius: "3px !important", backgroundColor: "white" }}>
                  <div className=" img-drop" style={{ borderRadius: "3px !important", backgroundColor: "white", border: "1px solid #E2E8F0" }}>
                    {
                      showImage1 ?
                        <div style={{
                          display: "flex",
                          height: "100%", width: "100%",
                        }}>

                          <img
                            style={{ alignSelf: "center" }}
                            alt=""
                            width="150"
                            height="120"
                            src={image1}
                            className="rounded mx-auto "
                          ></img>
                        </div>
                        :
                        <>
                          <div style={{
                            width: "30%", height: "auto",
                            margin: " 0 auto",
                            padding: "10px",
                            position: "relative",
                          }}>
                            <img
                              style={{
                                width: "100%", height: "40px",
                                margin: " 0 auto",
                                position: "relative",

                              }}
                              src={upload}
                            />
                          </div>

                          <p style={{ textAlign: "center" }}>
                            Upload Product Image
                            <p style={{ fontSize: "11px", color: "rgba(116, 116, 116, 0.5)" }}>
                              Files include PNG, JPEG
                            </p>
                          </p>
                          <label className="custom-file-upload" style={{ border: "none" }}>
                            {/* <input
                              type="file"
                              id="image"
                              name="Image"
                              // onChange={imageSelector1}
                              accept="image/png, image/jpeg"
                            /> *

                            <p
                              style={{
                                backgroundColor: "#FFA382",
                                border: "1px solid #FFA382",
                                width: "60%",
                                color: "white",
                                borderRadius: "5px",
                                paddingLeft: "2em",
                                paddingRight: "2em",
                                paddingTop: "0.5em",
                                paddingBottom: "0.5em",
                                marginLeft: "auto", marginRight: "auto"
                              }}
                            >
                              Choose File
                            </p>
                          </label>
                        </>
                    }
                    {showCrop && (
                      <Dialog
                        fullScreen={fullScreen}
                        open={open}
                        fullWidth={true}
                        onClose={() => setOpen(false)}
                        aria-labelledby="responsive-dialog-title"
                      >
                        <div
                          className="App"
                          style={{
                            padding: "1em",
                            width: "auto",
                            height: "auto",
                          }}
                        >
                          <div className="crop-container">
                            <Cropper
                              image={image}
                              crop={crop}
                              zoom={zoom}
                              aspect={4 / 3}
                              onCropChange={setCrop}
                              onCropComplete={onCropComplete}
                              onZoomChange={setZoom}
                            />
                          </div>
                          <div className="controls">
                            <Slider
                              value={zoom}
                              min={1}
                              max={3}
                              step={0.1}
                              aria-labelledby="Zoom"
                              onChange={(e, zoom) => setZoom(zoom)}
                            // classes={{ root: "slider" }}
                            />
                          </div>
                          <div>
                            <button
                              className="btn btn-primary crop-btn"
                              onClick={(e) => showCroppedImage(e)}
                              style={{
                                textAlign: "center",
                                width: "auto",
                                padding: "10px 30px",
                              }}
                            >
                              Crop
                            </button>
                          </div>
                        </div>
                      </Dialog>
                    )}
                  </div>
                </div> */}
                <div className="group" style={{ marginTop: "3px" }}>
                  {editBoolean && (
                    <button
                      type="button"
                      onClick={fileUpdate}
                      disabled={Object.keys(formValidationError).length !== 0}
                      className="btn btn-primary"
                      style={{
                        marginTop: "20px",
                        backgroundColor: "#FFA382",
                        border: "none",
                        width: "100%",
                      }}
                    >
                      Update
                    </button>
                  )}
                  {/* {editBoolean && <button type="button" onClick={deleteCategory} className="btn btn-danger m-2"><span><i className="fa fa-trash-alt"></i></span></button>} */}
                  {/* {!editBoolean && (
                    <button
                      type="button"
                      onClick={fileUpload}
                      className="btn btn-primary m-2">
                      Publish
                    </button>
                  )} */}
                </div>
                {editBoolean ? (
                  <p className="createdOn">
                    {/* <br />
                    Created on{" "}
                    {moment(createdDAte).format(" MMM. Do YYYY, h:mm A")}{" "} */}
                  </p>
                ) : (
                  ""
                )}
              </form>
            </div>
          </div>
        )}
      </div>
      {invokeLogOut && <InvokeLayoutComponent />}
      <Popup
        ref={notFoundRef}
        position="center"
        onClose={() => {
          notFoundRef.current.close();
          setSearchTerm("");
        }}
      >
        <div style={{ textAlign: "center" }}>
          <i
            style={{
              color: "red",
              fontSize: "70px",
            }}
            // style={{
            //   color: "green",
            //   fontSize: "60px",
            //   border: "10px solid green",
            //   borderRadius: "50px",
            //   padding: "10px",
            // }}
            class="fa fa-ban"
          ></i>
          <h2 className="popup-heading" style={{ marginTop: "20px" }}>
            Failed
          </h2>
          <p>Category(s) not found</p>
          <button
            className="btn btn-cannaby"
            style={{ marginTop: "0" }}
            onClick={() => {
              notFoundRef.current.close();
              setSearchTerm("");
            }}
          >
            OK
          </button>
        </div>
      </Popup>
    </div>
  );
};

export default Categories;
{
  /* {paginationData.totalPages !== 0 &&
                    paginationData.currentPage <= paginationData.totalPages && (
                      <Fragment>
                        <span>
                          {`${paginationData.perPage *
                            (paginationData.currentPage - 1) +
                            1
                            } - ${paginationData.perPage *
                            (paginationData.currentPage - 1) +
                            categories.length
                            } of ${paginationData.total ?? 0}`}
                        </span>
                        <div
                          className={`btn ${paginationData.currentPage <= 1
                            ? ""
                            : "cannaby-light"
                            }`}
                          onClick={() => {
                            if (!(paginationData.currentPage <= 1)) {
                              handleChange(
                                "page",
                                paginationData.currentPage - 1
                              );
                            }
                          }}
                          role="button"
                        >
                          <i className="fas fa-chevron-left"></i>
                        </div>
                        <div
                          onClick={() => {
                            if (
                              !(
                                paginationData.currentPage >=
                                paginationData.totalPages
                              )
                            ) {
                              handleChange(
                                "page",
                                paginationData.currentPage + 1
                              );
                            }
                          }}
                          className={`btn ${paginationData.currentPage >=
                            paginationData.totalPages
                            ? ""
                            : "cannaby-light"
                            }`}
                          role="button"
                        >
                          <i className="fas fa-chevron-right"></i>
                        </div>
                      </Fragment>
                    )} */
}
