import React from "react";
import { Checkbox, CircularProgress, Dialog } from "@mui/material";
import { useState, useEffect, Fragment } from "react";
import { selectifMultiChainAdmin } from "../../redux/slices/authSlice";
import { useSelector } from "react-redux";
import url from "../../config/axios";
import InputPrimary from "../../atoms/InputPrimary/Index2";
import InputAreaPrimary from "../../atoms/InputAreaPrimary/Index";
export default function EditNotification({
  onClose,
  toEdit = {},
  invokeLogout,
}) {
  const initialFormData = {
    displayName: "",
    viaEmail: true,
    viaSms: true,
    viaMobile: true,
    viaSystem: true,
    particularUserMessage: "",
    adminSiteMessage: "",
  };
  const handleChangeFromEvent = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };
  const handleClose = (isUpdated = false) => {
    onClose({
      type: toEdit.type,
      mediums: {
        email: isUpdated ? formData.viaEmail : toEdit.mediums?.email,
        sms: isUpdated ? formData.viaSms : toEdit.mediums?.sms,
        mobile: isUpdated ? formData.viaMobile : toEdit.mediums?.mobile,
        system: isUpdated ? formData.viaSystem : toEdit.mediums?.system,
      },
      displayName: isUpdated ? formData.displayName : toEdit.displayName,
      messages: {
        particularUser: isUpdated
          ? formData.particularUserMessage
          : toEdit.messages?.particularUser,
        adminSite: isUpdated
          ? formData.adminSiteMessage
          : toEdit.messages?.adminSite,
      },
    });
  };
  const isMultiChainAdmin = useSelector(selectifMultiChainAdmin);
  const [formData, setFormData] = useState({ ...initialFormData });
  const [inProgress, setInProgress] = useState(false);

  useEffect(
    () => {
      setFormData({
        displayName: toEdit.displayName,
        viaEmail: !!toEdit.mediums?.email,
        viaSms: !!toEdit.mediums?.sms,
        viaMobile: !!toEdit.mediums?.mobile,
        viaSystem: !!toEdit.mediums?.system,
        particularUserMessage: toEdit.messages?.particularUser ?? "",
        adminSiteMessage: toEdit.messages?.adminSite ?? "",
      });
    },
    //eslint-disable-next-line
    []
  );
  const handleChange = (propName, value) => {
    setFormData({ ...formData, [propName]: value });
  };
  const updateNotficationModule = () => {
    setTriedBefore(true);
    let errors = validateForm();
    if (Object.keys(errors).length === 0) {
      setInProgress(true);
      const body = {
        preferences: {
          [toEdit.type]: {
            mediums: {
              email: formData.viaEmail,
              sms: formData.viaSms,
              mobile: formData.viaMobile,
              system: formData.viaSystem,
            },
            displayName: formData.displayName,
            messages: {
              particularUser: formData.particularUserMessage,
              adminSite: formData.adminSiteMessage,
            },
          },
        },
      };
      // console.log("Body is : ", body);
      url
        .patch(`/v1/general-settings/notification-settings`, body)
        .then(() => {
          setInProgress(false);
          handleClose(true);
        })
        .catch((e) => {
          if (+e?.response?.status === 401) {
            invokeLogout();
          }
          setInProgress(false);
        });
    }
  };
  const [formValidationErrors, setFormValidationsErrors] = useState({});
  const [triedBefore, setTriedBefore] = useState(false);
  const validateForm = () => {
    let data = { ...formValidationErrors };

    //manipulate title
    if (formData.title?.trim().length === 0) {
      data = {
        ...data,
        titleError: "Title of the notification event is required",
      };
    } else {
      let { titleError, ...others } = data;
      data = { ...others };
    }

    //manipulate highlights
    if (formData.highlights?.trim().length === 0) {
      data = {
        ...data,
        hError: "Highlights of the notification event is required",
      };
    } else {
      let { hError, ...others } = data;
      data = { ...others };
    }
    if (formData.viaMobile === false && formData.viaSystem === false) {
      data = {
        ...data,
        checkError: "Notfication medium is required",
      };
    } else {
      let { checkError, ...others } = data;
      data = { ...others };
    }

    setFormValidationsErrors({ ...data });
    return data;
  };

  useEffect(
    () => {
      if (triedBefore) {
        validateForm();
      }
    },
    //eslint-disable-next-line
    [formData, triedBefore]
  );
  return (
    <Dialog
      open={true}
      fullWidth={true}
      onClose={() => {
        if (!inProgress) {
          handleClose();
        }
      }}
    >
      <div
        style={{
          height: "80vh",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          overflowX: "hidden",
        }}
      >
        <div
          className="row"
          style={{
            padding: "1em 1.5em",
            height: "auto",
            alignItems: "center",
          }}
        >
          <div
            style={{
              height: "25px",
              marginLeft: "-9px",
              backgroundColor: "#FFA382",
              marginTop: "5px",
            }}
          >
            <span style={{ color: "#FFA382" }}>.</span>
          </div>
          <div
            className="ml-2"
            style={{ color: "#FFA382", fontSize: "20px", fontWeight: "600" }}
          >
            {"Edit Notification"}
          </div>
        </div>
        <div
          style={{
            padding: "1em",
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            overflow: "auto",
          }}
        >
          <div>
            <InputPrimary
              label="Event"
              type="text"
              name="title"
              value={formData.displayName}
              onChange={handleChangeFromEvent}
              placeholder="Title"
            />
            {formValidationErrors.titleError ? (
              <span className="validation-help">
                {formValidationErrors.titleError}
              </span>
            ) : (
              ""
            )}
          </div>
          <div className="group-comb" style={{ marginTop: "4px" }}>
            <div style={{ padding: "4px 13px" }}>
              <div className="labelStyle" style={{ marginLeft: "-5px" }}>
                Notification Type
              </div>
              <div
                className="row"
                style={{ alignItems: "center", marginTop: "0.5em" }}
              >
                <Checkbox
                  checked={formData.viaEmail}
                  value={formData.viaEmail}
                  onChange={() => handleChange("viaEmail", !formData.viaEmail)}
                />
                <span>Email</span>
              </div>

              <div
                className="row"
                style={{ alignItems: "center", marginTop: "0.5em" }}
              >
                <Checkbox
                  checked={formData.viaMobile}
                  value={formData.viaMobile}
                  onChange={() =>
                    handleChange("viaMobile", !formData.viaMobile)
                  }
                />
                <span>Mobile</span>
              </div>

              <div
                className="row"
                style={{ alignItems: "center", marginTop: "0.5em" }}
              >
                <Checkbox
                  checked={formData.viaSystem}
                  value={formData.viaSystem}
                  onChange={() =>
                    handleChange("viaSystem", !formData.viaSystem)
                  }
                />
                <span>System</span>
              </div>

              <div
                className="row"
                style={{ alignItems: "center", marginTop: "0.5em" }}
              >
                <Checkbox
                  checked={formData.viaSms}
                  value={formData.viaSms}
                  onChange={() => handleChange("viaSms", !formData.viaSms)}
                />
                <span>SMS</span>
              </div>
            </div>
            {formValidationErrors.checkError ? (
              <span className="validation-help">
                {formValidationErrors.checkError}
              </span>
            ) : (
              ""
            )}
          </div>
          <div>
            <InputPrimary
              label="MESSAGE FOR PARTICULAR USER *"
              type="text"
              name="title"
              value={formData.particularUserMessage}
              onChange={handleChangeFromEvent}
              placeholder="Title"
            />
            {formValidationErrors.titleError ? (
              <span className="validation-help">
                {formValidationErrors.titleError}
              </span>
            ) : (
              ""
            )}
          </div>

          <div style={{ marginTop: "5px" }}>
            <InputAreaPrimary
              label="MESSAGE FOR ADMIN SITE*"
              type="text"
              name="highlights"
              rows={4}
              placeholder="Write here"
              value={formData.adminSiteMessage}
              onChange={handleChangeFromEvent}
            />

            {formValidationErrors.hError ? (
              <span className="validation-help">
                {formValidationErrors.hError}
              </span>
            ) : (
              ""
            )}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            padding: "1em 1.5em",
            height: "auto",
            alignItems: "center",
          }}
        >
          <button
            style={{
              backgroundColor: "#FFA382",
              padding: "1em 4em",
              cursor: "pointer",
              border: "none",
              fontWeight: "bold",
              borderRadius: "10px",
              color: "white",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "1em",
            }}
            onClick={updateNotficationModule}
          >
            {inProgress && (
              <CircularProgress style={{ color: "white" }} size={20} />
            )}
            {inProgress ? "Updating..." : "Update"}
          </button>
        </div>
      </div>
    </Dialog>
  );
}
