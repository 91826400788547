import React from "react";
import { useState, useEffect } from "react";
import url from "../../config/axios";
import moment from "moment";
import { useHistory } from "react-router-dom";

export default function Index() {
  const history = useHistory();
  const [memberships, setMemberships] = useState([]);
  const [totalPage, setTotalPage] = useState("");
  const [minPro, setminPro] = useState("");
  const [page, setPage] = useState(1);

  const incrementPage = () => {
    if (totalPage < 50) {
    } else {
      setPage(page + 1);
    }
  };

  const decrementPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  useEffect(() => {
    GetAllMemberships();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const GetAllMemberships = () => {
    const adminID = JSON.parse(localStorage.getItem("Admin"));
    // console.log(adminID[0].adminID);
    url
      .get(`/membership/?consumerID=${adminID[0].consumerId}`)
      .then(async (res) => {
        console.log("res", res);
        if (res.data.Message === "Success") {
          setMemberships(res.data.data);
          setTotalPage(res.data.data.length);
          setminPro(page * 50 - 50 + 1);
        } else {
          alert(res.data.data);
        }
      })
      .catch((e) => console.log(e));
  };

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="card">
          <button
            style={{ margin: "0" }}
            className="btn btn-cannaby btn-pro mr-5"
            onClick={() => {
              history.push("/add-membership");
            }}
          >
            <i className="fas fa-plus-circle"></i>
            Add Membership
          </button>
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-6">
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control table-search"
                      placeholder="Search Membership"
                      aria-label="Input group example"
                      aria-describedby="btnGroupAddon"
                    />
                    <div className="input-group-prepend">
                      <div
                        className="input-group-text table-search-icon"
                        id="btnGroupAddon"
                      >
                        <i className="fas fa-search"></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-2 filters-bar">
                  <div className="dropdown">
                    {/* <a
                      className="btn cannaby-light"
                      href="/"
                      role="button"
                      id="dropdownMenuLink"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i className="fas fa-filter"></i>
                    </a> */}

                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuLink"
                    >
                      <a className="dropdown-item" href="/">
                        Action
                      </a>
                      <a className="dropdown-item" href="/">
                        Another action
                      </a>
                      <a className="dropdown-item" href="/">
                        Something else here
                      </a>
                    </div>
                  </div>
                  <div className="dropdown">
                    {/* <a
                                        className="btn cannaby-light"
                                        href="/"
                                        role="button"
                                        id="dropdownMenuLink"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                        >
                                        <i className="fas fa-filter"></i>
                                        </a> */}

                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuLink"
                    >
                      <a className="dropdown-item" href="/">
                        Action
                      </a>
                      <a className="dropdown-item" href="/">
                        Another action
                      </a>
                      <a className="dropdown-item" href="/">
                        Something else here
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 pagination">
                  <span>
                    {minPro}-{memberships.length + minPro - 1} of{" "}
                    {totalPage ? totalPage : 0}
                  </span>
                  <div
                    className="btn cannaby-light"
                    onClick={() => decrementPage()}
                    role="button"
                  >
                    <i className="fas fa-chevron-left"></i>
                  </div>
                  <div
                    className="btn cannaby-light"
                    onClick={() => incrementPage()}
                    role="button"
                  >
                    <i className="fas fa-chevron-right"></i>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <table className="table table-striped-cust">
                <thead>
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th scope="col"></th>

                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th scope="col">Access</th>
                    <th scope="col">Created On</th>
                    <th scope="col">Members</th>
                    <th scope="col">Status</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {memberships.map((membership, i) => {
                    return (
                      <tr key={i}>
                        <td colSpan="6">{membership.name}</td>
                        <td className="adminName">
                          {membership.unlocksAtAmountSpent} <br />
                        </td>
                        <td>
                          {moment(membership.accountCreatedOn).format(
                            "MMM. Do YYYY"
                          )}
                        </td>
                        <td>
                          {moment(membership.updatedAt).format("MMM. Do YYYY")}
                        </td>
                        <td className="status">
                          {membership.status ? "Published" : "Not Published"}
                        </td>
                        <td>
                          {/* <i
                            className="fas fa-pen"
                            onClick={() => {
                              history.push('/user-detail')
                            }}
                          ></i> */}
                          <button
                            className="btn btn-primary"
                            onClick={() => {
                              localStorage.setItem(
                                "membership",
                                JSON.stringify(membership)
                              );
                              history.push("/edit-membership");
                            }}
                          >
                            <i className="fas fa-eye"></i>
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
