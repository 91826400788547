import React, { useRef, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import moment from "moment";
import Popup from "reactjs-popup";
import Pagination from "@mui/material/Pagination";
import url from "../../config/axios";
import { makeStyles } from "@material-ui/core/styles";
import { serializeQueryStringsFromObject } from "../../helpers/serializeToQueryStrings";
import { getClockPickerUtilityClass } from "@mui/lab/ClockPicker/ClockPicker";
const useStyles = makeStyles({
  root: {
    "& .Mui-selected": {
      backgroundColor: "#FFA382 !important",
    },
    "& .MuiPaginationItem-root": {
      borderRadius: "5px !important",
    },
  },
});
export default function DiscountUpdated() {
  const classes = useStyles();
  const history = useHistory();
  const [outlets, setOutlets] = useState([]);
  const [coupons, setCoupons] = useState([]);
  const [totalPage, setTotalPage] = useState("");
  const adminID = JSON.parse(localStorage.getItem("Admin"));
  console.log(adminID[0], "Admin page");
  const [searchTerm, setSearchTerm] = useState("");
  const [unfiltered, setUnfiltered] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [couponId, setCouponId] = useState(null);
  const [selectOutlet, setSelectOutletError] = useState("");
  const [loading, setLoading] = useState(true);
  const { search } = useLocation();

  const initialPaginationData = {
    page: 1,
    limit: 100,
    latestFirst: 1,
    search: "",
  };
  const [queryData, setQueryData] = useState({
    ...initialPaginationData,
  });
  const [parsed, setParsed] = useState(false);
  const [paginationData, setPaginationData] = useState({
    currentPage: 1,
    perPage: 30,
    totalPages: 0,
    total: 0,
  });

  const handleChange = (propName, propValue, flush = false) => {
    let query = {};
    if (flush) {
      query = {
        ...initialPaginationData,
        [propName]: propValue,
      };
    } else {
      query = { ...queryData, [propName]: propValue };
    }
    const queryString = serializeQueryStringsFromObject(query);
    history.push(`/deals${queryString}`);
    setQueryData({ ...query });
  };
  const notFoundRef = useRef();
  const openNotFoundPopUp = () => notFoundRef.current.open();
  const closeNotFoundPopUp = () => {
    notFoundRef.current.close();
    setSearchTerm("");
  };
  var perPage = 10;
  useEffect(
    () => {
      const query = new URLSearchParams(search);

      let page =
        Number.isNaN(query.get("page")) || !query.get("page")
          ? 1
          : +query.get("page");
      let limit =
        Number.isNaN(query.get("limit")) || !query.get("limit")
          ? 100
          : +query.get("limit");
      let latestFirst =
        Number.isNaN(query.get("latestFirst")) || !query.get("latestFirst")
          ? 1
          : +query.get("latestFirst");
      let searchParams = query.get("search");
      let queryParams = {
        ...queryData,
        page,
        limit,
        latestFirst,
        search: searchParams,
      };
      setSearchTerm(searchParams);
      setQueryData({ ...queryParams });
      setParsed(true);
    },
    //eslint-disable-next-line
    []
  );
  const ref = useRef();
  const openTooltip = (couponId) => {
    if (!!couponId) {
      setCouponId(couponId);
      ref.current.open();
    }
  };
  const closeTooltip = (success = false) => {
    if (success) {
      ref.current.close();
      GetAllCoupons();
    } else {
      ref.current.close();
    }
  };
  const deleteCoupon = () => {
    url.delete(`/coupon/?couponId=${couponId}`).then(async (res) => {
      if (res.data.Message === "Success") {
        closeTooltip(true);
      } else {
        openNotFoundPopUp();
      }
    });
  };
  const editCoupon = (data) => {
    localStorage.setItem("couponEdit", JSON.stringify(data));
    history.push("/edit-coupon");
  };

  const GetCouponByOutlet = async (outlet) => {
    try {
      const adminID = JSON.parse(localStorage.getItem("Admin"));
      const getCoupon = await url.get(
        `/v1/coupons-paginated?consumerId=${adminID[0].consumerId}&outletChainID=${outlet}`
      );
      console.log(getCoupon, "Coupons bby outlet");
      setCoupons(getCoupon.data.data.coupons);
      setUnfiltered(getCoupon.data.data.coupons);
      setPaginationData({
        ...paginationData,
        ...getCoupon?.data?.data?.paginationData,
      });

      setLoading(false);
    } catch (e) {
      console.log(e, "Error while getting coupon");
    }
  };
  const GetAllOutlets = () => {
    const adminID = JSON.parse(localStorage.getItem("Admin"));
    // console.log(adminID[0].adminID);
    url
      .get(`/oc/?consumerId=${adminID[0].consumerId}`)
      .then(async (res) => {
        // console.log('res', res)
        if (res.data.Message === "Success") {
          setOutlets(res.data.data);
        } else {
          openNotFoundPopUp();
        }
      })
      .catch((e) => console.log(e));
  };
  const GetAllCoupons = async () => {
    if (adminID[0].isSubOutlet) {
      try {
        const adminID = JSON.parse(localStorage.getItem("Admin"));
        const getCoupon = await url.get(
          `/v1/coupons-paginated?consumerId=${adminID[0].consumerId}&outletChainID=${adminID[0].outletChainID}`
        );
        console.log(getCoupon, "Coupons bby outlet");
        setCoupons(getCoupon.data.data.coupons);
        setUnfiltered(getCoupon.data.data.coupons);
        setPaginationData({
          ...paginationData,
          ...getCoupon?.data?.data?.paginationData,
        });

        setLoading(false);
      } catch (e) {
        console.log(e, "Error while getting coupon");
      }
    } else {
      try {
        url
          .get(`/specialEvents/all/?consumerId=${adminID[0].consumerId}`)
          .then(async (res) => {
            console.log(res);
            if (res.data.toSend.Message === "Success") {
              console.log(
                res.data.toSend.data?.paginationData,
                "Response from apis"
              );
              let modified = res.data.toSend.data?.data?.reverse() ?? [];
              // setCount(modified.length);
              setUnfiltered(modified);
              setCoupons(modified);
              setPaginationData({
                ...paginationData,
                ...res.data.toSend.data?.paginationData,
              });
              // setTotalPage(Math.ceil(modified.length / perPage));
              setLoading(false);
            } else {
              console.log("Inelse clause");
              openNotFoundPopUp();
            }
          })
          .catch((e) => console.log(e));
      } catch (e) {
        console.log(e, "error");
      }
    }
  };
  function backgroundColor(value) {
    let result;
    switch (value) {
      case "Pending":
        result = "rgb(251, 255, 194)";
        break;
      case "New":
        result = "rgba(108, 149, 255, 0.1)";
        break;
      case "Disabled":
        result = "rgba(255, 109, 109, 0.08)";
        break;
      case "Delivered":
        result = "rgba(12, 214, 0, 0.1)";
        break;
      case "Enabled":
        result = "rgba(12, 214, 0, 0.1)";
        break;
      case "Dispatched":
        result = "rgba(12, 214, 0, 0.1)";
        break;
      default:
        result = "";
        break;
    }
    return result;
  }
  function checkColor(value) {
    let result;
    switch (value) {
      case "Pending":
        result = "#FFC700";
        break;
      case "New":
        result = "blue";
        break;
      case "Disabled":
        result = "#FF6D6D";
        break;
      case "Enabled":
        result = "#0CD600";
        break;
      case "Delivered":
        result = "#0CD600";
        break;
      case "Dispatched":
        result = "#0CD600";
        break;
      default:
        result = "";
        break;
    }
    return result;
  }
  //clientSide search
  useEffect(
    () => {
      if (searchTerm !== null) {
        if (searchTerm.trim().length !== 0) {
          let filtered = unfiltered.filter((element) =>
            element.couponCode
              .toLowerCase()
              .includes(searchTerm.trim().toLowerCase())
          );
          if (filtered.length === 0) {
            openNotFoundPopUp();
          }
          setCoupons(filtered);
          setCurrentPage(1);
        } else {
          console.log("inside the else");
          setCoupons(unfiltered);
          setCurrentPage(1);
        }
      }
    },
    // eslint-disable-next-line
    [searchTerm]
  );
  useEffect(() => {
    GetAllOutlets();
    GetAllCoupons();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="row">
      <div className="col-md-12">
        <div className="card card-pro">
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="barStyle">
                  <span style={{ color: "#FFA382" }}>.</span>
                </div>
                <div
                  className={
                    adminID[0].isSubOutlet
                      ? "col-md-5 d-flex"
                      : "col-md-3 d-flex"
                  }
                >
                  <div
                    style={{
                      color: "#FFA382",
                      fontSize: "20px",
                      fontWeight: "500",
                    }}
                  >
                    Coupons
                  </div>
                </div>
                {adminID[0].isSubOutlet ? null : (
                  <div className="col-md-3">
                    <div className="input-group">
                      <select
                        className=" newInputSelect w-100"
                        onChange={(e) => {
                          setSelectOutletError(e);

                          GetCouponByOutlet(e.target.value);
                        }}
                        id="exampleFormControlSelect1"
                      >
                        <option value="select">Select outlet</option>
                        {outlets.map((outlet, i) => {
                          return (
                            <option value={outlet.outletChainID} key={i}>
                              {outlet.outletName}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    {selectOutlet === "" ? (
                      <p style={{ color: "red" }}>Please select outlet</p>
                    ) : null}
                  </div>
                )}
                <div
                  className={adminID[0].isSubOutlet ? `col-md-5` : `col-md-4`}
                >
                  <div className="input-group">
                    <input
                      type="text"
                      onChange={(e) => setSearchTerm(e.target.value)}
                      value={searchTerm}
                      className="form-control newInput"
                      placeholder="Search Coupon Code"
                      aria-label="Input group example"
                      aria-describedby="btnGroupAddon"
                    />
                    <div className="input-group-prepend">
                      <div
                        className="input-group-text newSearchIcon"
                        id="btnGroupAddon"
                      >
                        <i className="fas fa-search"></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-2 ">
                  <button
                    className="btn btn-primary border-0"
                    style={{ backgroundColor: "#FFA382" }}
                    onClick={() => history.push("/add-coupon")}
                  >
                    <i className="fas fa-plus-circle"></i>
                    Add Coupon
                  </button>
                  <div className="dropdown">
                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuLink"
                    >
                      <a className="dropdown-item" href="/">
                        Action
                      </a>
                      <a className="dropdown-item" href="/">
                        Another action
                      </a>
                      <a className="dropdown-item" href="/">
                        Something else here
                      </a>
                    </div>
                  </div>
                  <div className="dropdown">
                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuLink"
                    >
                      <a className="dropdown-item" href="/">
                        Action
                      </a>
                      <a className="dropdown-item" href="/">
                        Another action
                      </a>
                      <a className="dropdown-item" href="/">
                        Something else here
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <table className="table table-striped-cust invtable">
                <thead>
                  <tr>
                    <th scope="col">
                      <span
                        style={{
                          fontSize: "100%",
                        }}
                      >
                        Coupon Code
                      </span>
                    </th>
                    <th scope="col"></th>

                    <th scope="col"></th>
                    <th scope="col"></th>
                    {/* <th scope="col">Applicable to</th> */}
                    <th scope="col">
                      <span
                        style={{
                          fontSize: "100%",
                        }}
                      >
                        Value
                      </span>
                    </th>
                    <th scope="col">
                      <span
                        style={{
                          fontSize: "100%",
                        }}
                      >
                        Expires On
                      </span>
                    </th>
                    <th scope="col">
                      <span
                        style={{
                          fontSize: "100%",
                        }}
                      >
                        Usage
                      </span>
                    </th>
                    <th scope="col" style={{ textAlign: "center" }}>
                      <span
                        style={{
                          fontSize: "100%",
                        }}
                      >
                        Status
                      </span>
                    </th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {coupons.length === 0 ? (
                    <tr>
                      <td colSpan="12" style={{ textAlign: "center" }}>
                        No record found
                      </td>
                    </tr>
                  ) : (
                    coupons
                      .slice((currentPage - 1) * perPage, currentPage * perPage)
                      .map((coupon, i) => {
                        //   console.log(coupon, 'coupon:');
                        return (
                          <tr key={i}>
                            <td colSpan="4">{coupon.couponCode}</td>
                            <td>
                              {coupon.couponType === "Free Shiping"
                                ? "Free Shipping"
                                : coupon.couponType === "Percentage"
                                ? `${coupon.discountValue ?? 0} %`
                                : `$ ${coupon.discountValue ?? 0}`}
                            </td>
                            <td>
                              {!!coupon.neverExpires
                                ? "Never Expires"
                                : moment(coupon.endDate).format(
                                    "MMMM Do, YYYY h:mm A"
                                  )}
                            </td>
                            <td className="usage">
                              <span>
                                {coupon.rules?.TotalUsageLimit || "N/A"}
                              </span>
                            </td>

                            <td className={checkColor(coupon.status)}>
                              {/* {coupon.status} */}
                              <div
                                id="coupons"
                                className="newClass"
                                style={{
                                  backgroundColor: `${backgroundColor(
                                    coupon.status ? "Enabled" : "Disabled"
                                  )}`,
                                }}
                              >
                                <p
                                  style={{
                                    fontWeight: "400",
                                    textAlign: "center",
                                    fontSize: "13px",
                                    paddingTop: "4px",
                                    color: checkColor(
                                      coupon.status ? "Enabled" : "Disabled"
                                    ),
                                  }}
                                >
                                  {coupon.status ? "Enabled" : "Disabled"}
                                </p>
                              </div>
                            </td>
                            <td>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  gap: "1em",
                                }}
                              >
                                <i
                                  className="fas fa-pen"
                                  style={{ color: "#FFA382" }}
                                  onClick={() => {
                                    editCoupon(coupon);
                                  }}
                                ></i>
                                {/* onClick={() => moveToEdit(product)} */}
                                <i
                                  className="fa fa-trash-alt"
                                  style={{ color: "red", cursor: "pointer" }}
                                  onClick={() => {
                                    openTooltip(coupon.couponId);
                                  }}
                                ></i>
                              </div>
                            </td>
                          </tr>
                        );
                      })
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {loading ? null : (
          <div
            className="d-flex col-md-12"
            style={{
              justifyContent: "flex-end",
              marginTop: "5px",
              marginLeft: "13px",
            }}
          >
            <div
              style={{
                backgroundColor: "white",
                borderRadius: "5px",
                padding: "6px",
              }}
            >
              <Pagination
                shape="square"
                className={classes.root}
                onChange={(e, value) => {
                  handleChange("page", value);
                }}
                page={paginationData.currentPage}
                count={paginationData.totalPages ?? 0}
              />
            </div>
          </div>
        )}
      </div>
      <Popup ref={ref} position="center">
        <div style={{ textAlign: "center" }}>
          <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
          <p className="popup-heading">Do you want to delete this coupon ?</p>
          <button
            className="btn btn-cannaby"
            onClick={() => {
              deleteCoupon();
            }}
          >
            Yes
          </button>
          <button
            style={{ marginLeft: "20px", marginTop: "30px" }}
            type="button"
            className="btn popup-cancel-button"
            onClick={closeTooltip}
          >
            No
          </button>
        </div>
      </Popup>
      <Popup ref={notFoundRef} position="center">
        <div style={{ textAlign: "center" }}>
          <i
            style={{
              color: "red",
              fontSize: "70px",
            }}
            // style={{
            //   color: "green",
            //   fontSize: "60px",
            //   border: "10px solid green",
            //   borderRadius: "50px",
            //   padding: "10px",
            // }}
            class="fa fa-ban"
          ></i>
          <h2 className="popup-heading" style={{ marginTop: "20px" }}>
            Failed
          </h2>
          <p>Coupons not found</p>
          <button
            className="btn btn-cannaby"
            style={{ marginTop: "0" }}
            onClick={closeNotFoundPopUp}
          >
            OK
          </button>
        </div>
      </Popup>
    </div>
  );
}
