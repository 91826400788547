import InventoryList from "../../components/Inventory/InventoryList-updated";

function Inventory() {
  return (
    <>
      <InventoryList />
    </>
  );
}

export default Inventory;
