import axios from "axios";

const customInstance = axios.create({
  // baseURL: "http://localhost:8080/",
  baseURL: "https://standalone-consumer.bleaum.dev/",
  // baseURL: "https://standalone-consumer.staging.bleaum.dev/",
  // baseURL: "https://pos.staging.bleaum.dev/",
  headers: {
    Accept: "application/json",
    "my-token": (function () {
      try {
        return localStorage.getItem("myToken");
      } catch (e) {
        //TO DO
        return null;
      }
    })(),
    outletchainid: (function () {
      try {
        let admin = JSON.parse(localStorage.getItem("Admin"));
        if (admin.length) {
          return admin[0].outletChainID ?? null;
        }
        return null;
      } catch (e) {
        //TO DO
        return null;
      }
    })(),
    consumerid: (function () {
      try {
        let admin = JSON.parse(localStorage.getItem("Admin"));
        if (admin.length) {
          return admin[0].consumerId ?? null;
        }
        return null;
      } catch (e) {
        //TO DO
        return null;
      }
    })(),
  },

  withCredentials: false,
});

export default customInstance;
