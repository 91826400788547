import React from "react";
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Routes from "./pages/Routes/Routes.js";
import Loginpage from "./pages/Authentication/Index.jsx";
// import Sidenav from "./components/SideNav";
import SidenavNew from "./components/SidenavNew/Sidenav";

import Header from "./components/Header";
import { useDispatch, useSelector } from "react-redux";
import {
  abortProgressAction,
  removeErrorMessageFromAuthStateAction,
  selectAuthStateErrorMessage,
  selectifMultiChainAdmin,
  selectIfSwitchingProgress,
} from "./redux/slices/authSlice";
import { CustomBackdrop } from "./components/common/backdrop";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { OutletChooser } from "./components/common/outletChooser";
import { selectIfChooserOpen } from "./redux/slices/ocSwitchSlice";
import {
  initializeSocketStateThunk,
  resetSocketStateAction,
  selectSocketState,
  SOCKET_EVENTS,
  // setSocketAsReconnectAction,
  setSocketAsConnetedAction,
  setSocketAsDisconnectedAction,
} from "./redux/slices/socketSlice";
import MainLogoWithoutText from "./assets/Logo/without-text.png";
import DashboardIcon from "@mui/icons-material/Dashboard";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import PersonIcon from "@mui/icons-material/Person";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import LoyaltyIcon from "@mui/icons-material/Loyalty";
import WebIcon from "@mui/icons-material/Web";
import NotificationsIcon from "@mui/icons-material/Notifications";
import ForumIcon from "@mui/icons-material/Forum";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";

function App() {
  const [admin, setAdmin] = React.useState(false);
  const isMultiChainAdmin = useSelector(selectifMultiChainAdmin);
  const isSwitching = useSelector(selectIfSwitchingProgress);
  const authError = useSelector(selectAuthStateErrorMessage);
  const chooserOpen = useSelector(selectIfChooserOpen);
  const dispatch = useDispatch();
  const clearError = () => {
    dispatch(removeErrorMessageFromAuthStateAction());
  };

  const socket = useSelector(selectSocketState);
  React.useEffect(
    () => {
      const isAdmin = JSON.parse(localStorage.getItem("Admin"));
      console.log(isAdmin, "admin");
      if (isAdmin && isAdmin[0].isSubOutlet) {
        setAdmin(true);
      } else {
        setAdmin(false);
      }
      if (!socket) {
        dispatch(initializeSocketStateThunk());
      } else {
        socket.on(SOCKET_EVENTS.CONNECT, () =>
          dispatch(setSocketAsConnetedAction())
        );
        socket.on(SOCKET_EVENTS.DISCONNECT, () =>
          dispatch(setSocketAsDisconnectedAction())
        );
        // socket.on(SOCKET_EVENTS.RECONNECT, () =>
        //   dispatch(setSocketAsReconnectAction())
        // );
      }
      return () => {
        if (socket) {
          dispatch(resetSocketStateAction());
        }
      };
    },
    //eslint-disable-next-line
    [socket]
  );
  React.useEffect(
    () => {
      try {
        if (isSwitching) {
          dispatch(abortProgressAction());
        }
        const adminData = JSON.parse(localStorage.getItem("Admin"));
        if (Array.isArray(adminData) && !!adminData?.length) {
          const faviconLogoFileSource =
            adminData[0]?.media?.faviconLogoFileSource ?? null;
          if (faviconLogoFileSource) {
            // console.log("Favicon logo source is: ", faviconLogoFileSource);
            let link = document.querySelector("link[rel~='icon']");
            if (!link) {
              link = document.createElement("link");
              link.rel = "icon";
              document.getElementsByTagName("head")[0].appendChild(link);
            }
            link.href = faviconLogoFileSource;
          }
        }
      } catch (e) {}
    },
    //eslint-disable-next-line
    []
  );
  return (
    <Router>
      <Switch>
        {isMultiChainAdmin && isSwitching && <CustomBackdrop />}
        {isMultiChainAdmin && chooserOpen && !isSwitching && <OutletChooser />}
        {isMultiChainAdmin && authError && (
          <Snackbar open={true} autoHideDuration={6000} onClose={clearError}>
            <Alert onClose={clearError} severity="error" sx={{ width: "100%" }}>
              {authError ?? "Something went wrong"}
            </Alert>
          </Snackbar>
        )}
        <Route exact path="/" component={LoginContainer} />
        {!admin ? (
          <Route component={DefaultContainer} />
        ) : (
          <Route component={DefaultContainer2} />
        )}
      </Switch>
    </Router>
  );
}

const LoginContainer = () => (
  <div className="container">
    <Route exact path="/" component={Loginpage} />
  </div>
);

const DefaultContainer = () => (
  <div>
    <div className="App">
      <div className="backdrop"></div>
      <div className="row row-main">
        {/* <Sidenav /> */}
        <SidenavNew items={NavItems} logoSource={MainLogoWithoutText}>
          <Route component={Routes} />
        </SidenavNew>

        {/* <div className="col-lg-10 col-md-8 col-sm-8 right-column right-column">
          <Header />
          <Route component={Routes} />
        </div> */}
      </div>
    </div>
  </div>
);

const DefaultContainer2 = () => (
  <div>
    <div className="App">
      <div className="backdrop"></div>
      <div className="row row-main">
        {/* <Sidenav /> */}
        <SidenavNew items={NavItems2} logoSource={MainLogoWithoutText}>
          <Route component={Routes} />
        </SidenavNew>

        {/* <div className="col-lg-10 col-md-8 col-sm-8 right-column right-column">
          <Header />
          <Route component={Routes} />
        </div> */}
      </div>
    </div>
  </div>
);

const NavItems = {
  firstItems: [
    {
      name: "dashboard",
      label: "Dashboard",
      icon: <DashboardIcon />,
      url: "/dashboard",
    },
    {
      name: "outlets",
      label: "Outlets",
      icon: <LocalOfferIcon />,
      url: "/outlets",
    },
    {
      name: "catalog",
      label: "Catalog",
      icon: <LocalOfferIcon />,
      url: "/catalog-outlet",
    },
    {
      name: "inventory",
      label: "Inventory",
      icon: <LocalOfferIcon />,
      subItems: [
        {
          name: "products",
          label: "Products",
          url: "/inventory",
          headingLabel: "Inventory",
        },
        {
          name: "categories",
          label: "Categories",
          url: "/categories",
          headingLabel: "Inventory",
        },
        {
          name: "manufacturers",
          label: "Manufacturers",
          url: "/brands",
          headingLabel: "Inventory",
        },
        // { name: "album", label: "Album", url: "/album", headingLabel: "Album" }
      ],
    },
    {
      name: "orders",
      label: "Orders",
      icon: <LocalShippingIcon />,
      url: "/order",
    },
    {
      name: "users",
      label: "Users",
      icon: <PersonIcon />,
      url: "/users",
    },
    {
      name: "promotions",
      label: "Promotions",
      icon: <AddBusinessIcon />,
      subItems: [
        {
          name: "banners",
          label: "Banners",
          url: "/banner",
          headingLabel: "Promotions",
        },
        {
          name: "deals",
          label: "Deals",
          url: "/deals",
          headingLabel: "Promotions",
        },
        {
          name: "Promotions",
          label: "Coupons",
          url: "/discounts",
          headingLabel: "Promotions",
        },
        {
          name: "special-event",
          label: "Special Events",
          url: "/Special-Event",
        },
      ],
    },
    {
      name: "pages",
      label: "Pages",
      icon: <WebIcon />,
      url: "/page",
    },
    // {
    //   name: "loyalty-points",
    //   label: "Loyalty Points",
    //   icon: <LoyaltyIcon />,
    //   url: "/loyalty-points",
    // },
  ],
  secondItems: [
    {
      name: "logout",
      label: "Logout",
      color: "red",
      icon: <LogoutIcon color="error" />,
      url: "/logout",
    },
  ],
};
const NavItems2 = {
  firstItems: [
    {
      name: "dashboard",
      label: "Dashboard",
      icon: <DashboardIcon />,
      url: "/dashboard",
    },
    {
      name: "catalog",
      label: "Catalog",
      icon: <LocalOfferIcon />,
      url: "/catalog-outlet",
    },
    {
      name: "inventory",
      label: "Inventory",
      icon: <LocalOfferIcon />,
      subItems: [
        {
          name: "products",
          label: "Products",
          url: "/inventory-outlet",
          headingLabel: "Inventory",
        },
        {
          name: "categories",
          label: "Categories",
          url: "/categories",
          headingLabel: "Inventory",
        },
        {
          name: "manufacturers",
          label: "Manufacturers",
          url: "/brands",
          headingLabel: "Inventory",
        },
        // { name: "album", label: "Album", url: "/album", headingLabel: "Album" }
      ],
    },
    {
      name: "orders",
      label: "Orders",
      icon: <LocalShippingIcon />,
      url: "/order",
    },
    {
      name: "users",
      label: "Users",
      icon: <PersonIcon />,
      url: "/users",
    },
    {
      name: "promotions",
      label: "Promotions",
      icon: <AddBusinessIcon />,
      subItems: [
        {
          name: "banners",
          label: "Banners",
          url: "/banner",
          headingLabel: "Promotions",
        },
        {
          name: "deals",
          label: "Deals",
          url: "/deals",
          headingLabel: "Promotions",
        },
        {
          name: "Promotions",
          label: "Coupons",
          url: "/discounts-outlet",
          headingLabel: "Promotions",
        },
        {
          name: "special-event",
          label: "Special Events",
          url: "/Special-Event",
          headingLabel: "Promotions",
        },
      ],
    },
    {
      name: "loyalty-points",
      label: "Loyalty Points",
      icon: <LoyaltyIcon />,
      url: "/loyalty-points",
    },
    {
      name: "pages",
      label: "Pages",
      icon: <WebIcon />,
      url: "/page",
    },
    {
      name: "chat",
      label: "Chat",
      icon: <ForumIcon />,
      url: "/chat",
    },
    {
      name: "settings",
      label: "Settings",
      icon: <SettingsIcon />,
      url: "/general-settings",
    },
  ],
  secondItems: [
    {
      name: "logout",
      label: "Logout",
      color: "red",
      icon: <LogoutIcon color="error" />,
      url: "/logout",
    },
  ],
};

// const OutletAdminContainer = () => (
//   <div>
//     <div className="App">
//       <div className="backdrop"></div>
//       <div className="row row-main">
//         {/* <Sidenav /> */}
//         <div className="col-lg-10 col-md-8 col-sm-8">
//           <Header />
//           <Route component={OutletAdminRoutes} />
//         </div>
//       </div>
//     </div>
//   </div>
// )

export default App;
