import { Popover } from "@mui/material";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import React, { useState } from "react";
import { Fragment } from "react";
import LinkDropDownComponent from "../../../components/SocialLinksPage/LinksDropDown.component";
import CloseIcon from "@mui/icons-material/Close";
import { socialLinkIcons } from "../../../components/SocialLinksPage/options";
import { useEffect } from "react";
import url from "../../../config/axios";
import CircularProgress from "@mui/material/CircularProgress";
import styled from "@emotion/styled";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import SocialInput from "../../../atoms/SocialInput/SocialInput";
import { Grid, Button } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import ConfirmationPopup from "../../../atoms/ConfirmationPopup/ConfirmationPopup";

function SocialLinkPageTajwar({ invokeLogout }) {
  // {
  //   medium: "facebook",
  //   fontAwesomeRef: "fa-brands fa-facebook-f",
  //   themeColor: "#4267B2",
  //   dropdownColor: "white",
  //   starterLink: "https://www.facebook.com"
  // }
  const [addedLinks, setAddedLinks] = useState([
    {
      medium: "facebook",
      fontAwesomeRef: "fa-brands fa-facebook-f",
      themeColor: "#4267B2",
      dropdownColor: "white",
      starterLink: "https://www.facebook.com",
    },
    {
      medium: "twitch",
      fontAwesomeRef: "fa-brands fa-twitch",
      themeColor: "#6441a5",
      dropdownColor: "white",
      starterLink: "https://www.twitch.com",
    },
  ]);
  /**
   *
   * @param {index} index Mapped index of the links
   * @param {value} value Value parsed from HTML event
   */
  const handleLinkChange = (index, value) => {
    setAddedLinks([
      ...addedLinks.map((obj, i) => {
        if (i === index) {
          return {
            ...obj,
            starterLink: value,
          };
        }
        return obj;
      }),
    ]);
  };

  const handleRemove = (index) => {
    let toRemove = addedLinks[index]?.medium;
    let updatedErrorData = { ...linkErrors };
    delete updatedErrorData[toRemove];
    setLinkErrors({ ...updatedErrorData });
    setAddedLinks(addedLinks.filter((_, i) => i !== index));
  };

  /**
   *
   * @param {any} previousObject previous object
   * @param {any} toChange to be changed object
   */
  const handleChange = (previousObject, toChange) => {
    console.log("Social select", previousObject, toChange);
    let toRemove = previousObject.medium;
    let updatedErrorData = { ...linkErrors };
    delete updatedErrorData[toRemove];
    setLinkErrors({ ...updatedErrorData });
    setAddedLinks(
      addedLinks.map((obj) => {
        if (obj?.medium === previousObject.medium) {
          return toChange;
        }
        return obj;
      })
    );
  };

  const addNewSlide = () => {
    let currentKeys = addedLinks.map((obj) => obj.medium);
    let filteredKeys = Object.keys(socialLinkIcons).filter(
      (el) => !currentKeys.includes(el)
    );
    if (filteredKeys.length !== 0 && addedLinks.length < 8) {
      setAddedLinks([
        ...addedLinks,
        {
          ...socialLinkIcons[filteredKeys[0]],
          medium: filteredKeys[0],
        },
      ]);
    }
  };
  const removeExistingLink = (obj) => {
    setAddedLinks(addedLinks.filter((link) => link.medium !== obj.medium));
    setSocialInputs(
      socialInputs.filter((input) => input.medium !== obj.medium)
    );
  };
  const [linkErrors, setLinkErrors] = useState({});
  const validateLinks = () => {
    let data = { ...linkErrors };
    // console.log("Added: ", addedLinks);
    for (let i = 0; i < addedLinks.length; i++) {
      let key = addedLinks[i].medium;

      let el = socialLinkIcons[key] ?? {};
      if (!!el.validate) {
        let isValid = el.validate(addedLinks[i].starterLink);
        if (!isValid) {
          data = { ...data, [key]: `Enter a valid ${key} URL` };
        } else {
          delete data[key];
        }
      }
    }
    setLinkErrors({ ...data });
    // console.log("Data is", data);
    return data;
  };
  const [inProgress, setInProgress] = useState(true);
  const [triedBefore, setTriedBefore] = useState(false);
  const [socialInputs, setSocialInputs] = useState([]);
  const [confirmationPopupState, setConfirmationPopupState] = useState({
    show: false,
    text: "",
    error: false,
  });
  const updateSocialLinks = () => {
    setTriedBefore(true);
    let errors = validateLinks();
    setInProgress(true);
    const body = {
      links: socialInputs.map((obj) => ({ ...obj, link: obj.url })),
    };
    console.log("Body", body);
    url
      .patch(`/v1/general-settings/social-links`, body)
      .then((res) => {
        setInProgress(false);
        setConfirmationPopupState({
          show: true,
          text: "Socials updates successfully",
          error: false,
        });
      })
      .catch((e) => {
        if (+e?.response?.status === 401) {
          invokeLogout();
        }
        setInProgress(false);
        setConfirmationPopupState({
          show: true,
          text: "Failed to update socials",
          error: true,
        });
        console.log("Failed to update socials", e);
      });
  };
  const getAllSocialLinks = () => {
    const adminID = JSON.parse(localStorage.getItem("Admin"));
    const outletChainID = adminID[0]?.outletChainID ?? null;
    url
      .get(`/v1/general-settings/social-links?outletChainID=${outletChainID}`)
      .then((response) => {
        const links = response?.data?.data?.links;
        if (Array.isArray(links)) {
          setAddedLinks(
            links.map((obj) => ({ ...obj, starterLink: obj.link }))
          );
          setSocialInputs(
            links.map((obj) => ({
              ...obj,
              url: obj.link,
            }))
          );
        }

        setInProgress(false);
      })
      .catch((e) => {
        setConfirmationPopupState({
          show: true,
          text: "Something went wrong while server trying to fetch socials",
          error: true,
        });
        console.log("Failed to fetch socials: ", e);
        setInProgress(false);
      });
  };

  //fetch data
  useEffect(() => {
    getAllSocialLinks();
  }, []);
  useEffect(() => {
    console.log("Added links", addedLinks);
  }, [addedLinks]);
  useEffect(
    () => {
      if (triedBefore) {
        validateLinks();
      }
    },
    //eslint-disable-next-line
    [addedLinks, triedBefore]
  );

  const onUserInput = (obj) => {
    console.log("On Social Input Change", obj);
    let _exists = socialInputs.find((item) => item.medium == obj.medium);
    if (_exists) {
      setSocialInputs(
        socialInputs.map((item) => {
          if (item.medium === obj.medium) {
            return obj;
          }
          return item;
        })
      );
    } else {
      setSocialInputs([...socialInputs, obj]);
    }
  };

  /** Debug */
  React.useEffect(() => {
    console.log("Social Inputs", socialInputs);
  }, [socialInputs]);

  return (
    <div className="newSpace">
      <Wrapper>
        <Grid container spacing={2}>
          <Grid container spacing={2} item xs={12}>
            {addedLinks.map((obj, i) => (
              <Grid item md={6} sm={12}>
                <div className="social-input-container">
                  <SocialInput
                    addedLinks={addedLinks}
                    selected={obj}
                    onSocialSelect={handleChange}
                    onChange={onUserInput}
                  />
                  <button
                    className="icon-btn"
                    onClick={() => removeExistingLink(obj)}
                  >
                    <CancelIcon />
                  </button>
                </div>
              </Grid>
            ))}
          </Grid>

          <Grid container justifyContent="flex-end" spacing={1} item xs={12}>
            <Grid item>
              <Button variant="outlined" onClick={addNewSlide}>
                Add New
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                onClick={updateSocialLinks}
                disabled={inProgress}
              >
                Update
              </Button>
            </Grid>
          </Grid>

          {/* <LinkDropDownComponent
              taken={addedLinks.map((obj) => obj.medium)}
              handleChange={handleChange}
              previousObject={obj}
          /> */}
        </Grid>
      </Wrapper>
      <ConfirmationPopup data={confirmationPopupState} />
    </div>
  );
}
const Wrapper = styled.div`
  .social-input-container {
    position: relative;
    .icon-btn {
      border: none;
      outline: none;
      background-color: transparent;
      position: absolute;
      right: 5px;
      top: -10px;
      font-size: 0.8rem;
      height: 10px;
      width: 10px;
      cursor: pointer;
      border-radius: 50%;
      color: red;
    }
  }
`;
export default SocialLinkPageTajwar;
