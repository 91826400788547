import React from 'react'
import moment from 'moment/moment';
export default function BrandTable({ brands, assignColor, editBrand }) {
    return (
        <div><table className="table table-striped-cust">
            <thead>
                <tr>
                    <th scope="col">Name</th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th scope="col"></th>

                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th scope="col">Products</th>
                    <th scope="col">Last Modified</th>
                    <th scope="col" className="hidden">
                        Status
                    </th>
                    <th scope="col">Action</th>
                </tr>
            </thead>
            <tbody>
                {brands.map((brand, i) => {
                    return (
                        <tr style={{ borderBottom: "1px solid #F1F5F9" }} key={i}>
                            <td colSpan="6">
                                {brand.image ? (
                                    <div>
                                        <img
                                            alt="yo1"
                                            src={brand.image}
                                            className="img-fluid "
                                            width="40"
                                        />
                                        {brand.name}
                                    </div>
                                ) : (
                                    <div style={{ display: "flex" }}>
                                        <div
                                            className="image-thumbnail-div"
                                            style={{
                                                backgroundColor: assignColor(),
                                                width: "40px",
                                                height: "40px",
                                            }}
                                        >
                                            <span>{brand?.name?.substring(0, 1)}</span>
                                        </div>
                                        <span style={{ paddingTop: "10px", color: "#1E293B" }}>
                                            {brand.name}
                                        </span>
                                    </div>
                                )}
                            </td>
                            <td style={{ color: "#1E293B" }}>
                                {brand.countInventory?.length
                                    ? brand.countInventory[0].count
                                    : 0}
                            </td>
                            <td style={{ color: "#1E293B" }}>
                                {moment(brand.updatedAt).format(
                                    "MMMM Do YYYY, h:mm A"
                                )}
                            </td>
                            <td className="status hidden">
                                {brand.status ? (
                                    <span>Published</span>
                                ) : (
                                    <span>Not Published</span>
                                )}
                            </td>

                            <td>
                                <div
                                    onClick={() => editBrand(brand)}
                                    style={{ color: "#FFA382" }}>
                                    <i
                                        style={{ color: "#FFA382", marginRight: "3px" }}
                                        className="fas fa-pen"
                                    ></i>
                                    <u>Edit
                                    </u>
                                </div>

                            </td>
                        </tr>
                    );
                })}
            </tbody>
        </table></div>
    )
}
