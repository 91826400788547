import React from "react";
import "./AddInventory.css";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import blank from "../../assets/defaultImage.jpg";
import { useTheme } from "@emotion/react";
import Up from "../../assets/Up.svg";
import Down from "../../assets/Down.svg";
import InputPrimary from "../../atoms/InputPrimary/Index2";
export default function ViewProductNew({ setViewModal, selectedProduct }) {
  const styles = {
    display: "block",
    backgroundColor: "rgba(0,0,0,0.4)" /* Black w/ opacity */,
    fontFamily: "Roboto",
    marginTop: "4rem",
    paddingTop: "4px",
    overflowY: "scroll",
  };
  const detailStyles = {
    display: "flex",
    justifyContent: "space-between",
    paddingTop: "10px",
  };
  const effectStyle = {
    display: "flex",
    justifyContent: "flex-start",
    gap: "0.8rem",
    flexWrap: "wrap",
  };

  console.log(selectedProduct, "Selecteed product");
  const theme = useTheme();
  return (
    <div className="modal" style={styles}>
      <div className="mdl modal-dialog-centered">
        <div className="modal-content">
          <div className=" row">
            <div className="col-md-12">
              {" "}
              <div
                className="row"
                // style={{ borderBottom: "1px solid #F1F5F9" }}
              >
                <div
                  style={{
                    height: "25px",
                    marginLeft: "15px",
                    backgroundColor: theme.palette.primary.main,
                    marginTop: "10px",
                  }}
                >
                  <span style={{ color: theme.palette.primary.main }}>.</span>
                </div>
                <div className={"col-md-3 d-flex"}>
                  <div
                    style={{
                      color: theme.palette.primary.main,
                      fontSize: "20px",
                      fontWeight: "600",
                      marginTop: "7px",
                    }}
                  >
                    Product Details
                  </div>
                </div>
                <div className="col-md-7"></div>
                <div className="col-md-1">
                  <HighlightOffRoundedIcon
                    style={{ cursor: "pointer", marginTop: "7px" }}
                    onClick={() => setViewModal(false)}
                  />
                </div>
              </div>
              <hr />
            </div>
            <div className="modal-body">
              {/* Styling the product Box */}
              <div
                className="row"
                style={{
                  background: "#F4F7FC",
                  height: "162px",
                  borderRadius: "5px",
                }}
              >
                <div
                  className="col-md-3"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignSelf: "center",
                  }}
                >
                  <div
                    style={{
                      height: "60px !important",
                      width: "100%",
                      marginBottom: "auto",
                    }}
                  >
                    <img
                      style={{ width: "90%" }}
                      alt=""
                      src={
                        selectedProduct.image ? selectedProduct.image : blank
                      }
                    />
                  </div>
                </div>
                <div
                  className="col-md-9"
                  style={{
                    alignSelf: "center",
                  }}
                >
                  <h3
                    style={{
                      fontSize: "22px",
                      color: theme.palette.gray.extraDark,
                    }}
                  >
                    {selectedProduct.name}
                  </h3>
                  <div>
                    <p>
                      Brand:{" "}
                      <span style={{ color: theme.palette.gray.extraDark }}>
                        {selectedProduct?.brandProduct
                          ? selectedProduct?.brandProduct
                          : "N/A"}
                      </span>
                    </p>
                  </div>
                  <div>
                    <p>
                      Categories:{" "}
                      <span style={{ color: theme.palette.gray.extraDark }}>
                        {selectedProduct?.categoryProduct
                          ? selectedProduct?.categoryProduct
                          : "0%"}
                      </span>
                    </p>
                  </div>
                  <div
                    style={{ display: "flex", justifyContent: "flex-start" }}
                  >
                    <div
                      style={{
                        marginRight: "20px",
                        display: "flex",
                        justifyContent: "space-between",
                        width: "130px",
                      }}
                    >
                      <img
                        style={{ height: "15px", marginTop: "10px" }}
                        src={Up}
                      />

                      <p
                        style={{
                          color: theme.palette.gray.extraDark,
                          fontWeight: "600",
                          fontSize: "23px",
                        }}
                      >
                        {selectedProduct?.thc
                          ? selectedProduct?.thc + "%"
                          : "0%"}
                      </p>
                      <p
                        style={{
                          color: theme.palette.gray.extraDark,
                          fontWeight: "600",
                          fontSize: "23px",
                        }}
                      >
                        TBH
                      </p>
                    </div>
                    <div
                      style={{
                        marginRight: "20px",
                        display: "flex",
                        justifyContent: "space-between",
                        width: "130px",
                      }}
                    >
                      <img
                        style={{ height: "15px", marginTop: "10px" }}
                        src={Down}
                      />

                      <p
                        style={{
                          color: theme.palette.gray.extraDark,
                          fontWeight: "600",
                          fontSize: "23px",
                        }}
                      >
                        {selectedProduct?.cbd
                          ? selectedProduct?.cbd + "%"
                          : "0%"}
                      </p>
                      <p
                        style={{
                          color: theme.palette.gray.extraDark,
                          fontWeight: "600",
                          fontSize: "23px",
                        }}
                      >
                        CBD{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* Description and everything is below here */}
              <div>
                <h2
                  style={{
                    color: theme.palette.gray.extraDark,
                    fontSize: "20px",
                    paddingTop: "25px",
                  }}
                >
                  Short Description
                </h2>
                <p>
                  {selectedProduct?.shortDescription
                    ? selectedProduct?.shortDescription
                    : "none yet"}
                </p>
              </div>
              <div>
                <h2
                  style={{
                    color: theme.palette.gray.extraDark,
                    fontSize: "20px",
                    paddingTop: "25px",
                  }}
                >
                  Long Description
                </h2>
                <p>
                  {selectedProduct?.longDescription
                    ? selectedProduct?.longDescription
                    : "none yet"}
                </p>
              </div>
              <div>
                <h2
                  style={{
                    color: theme.palette.gray.extraDark,
                    fontSize: "20px",
                    paddingTop: "25px",
                  }}
                >
                  Packaging Details
                </h2>
                <div style={detailStyles}>
                  <InputPrimary
                    label={"Height"}
                    value={selectedProduct.height ?? 0}
                    pattern="[0-9]*"
                    onInput={(e) => {
                      if (isNaN(e.target.value)) {
                        e.target.value = "";
                      } else {
                        e.target.value = Math.abs(e.target.value);
                      }
                    }}
                  />
                  <InputPrimary
                    label={"Width"}
                    value={selectedProduct.width ?? 0}
                    pattern="[0-9]*"
                    onInput={(e) => {
                      if (isNaN(e.target.value)) {
                        e.target.value = "";
                      } else {
                        e.target.value = Math.abs(e.target.value);
                      }
                    }}
                  />
                  <InputPrimary
                    label={"Weight"}
                    value={selectedProduct.weight ?? 0}
                    pattern="[0-9]*"
                    onInput={(e) => {
                      if (isNaN(e.target.value)) {
                        e.target.value = "";
                      } else {
                        e.target.value = Math.abs(e.target.value);
                      }
                    }}
                  />
                </div>
              </div>
              <div>
                <h2
                  style={{
                    color: theme.palette.gray.extraDark,
                    fontSize: "20px",
                    paddingTop: "15px",
                  }}
                >
                  Effects
                </h2>
                <div style={effectStyle}>
                  {selectedProduct.effects.length !== 0 ? (
                    selectedProduct.effects.map((i) => {
                      return (
                        <>
                          {" "}
                          <button
                            style={{
                              borderColor: theme.palette.primary.main,
                              backgroundColor: "white",
                              color: theme.palette.primary.main,
                              marginRight: "10px",
                            }}
                            className="btn btn-transparent"
                          >
                            {i}
                          </button>
                        </>
                      );
                    })
                  ) : (
                    <p>No Effects yet</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
