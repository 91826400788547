import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import BasicInfoPage from "./sub-pages/BasicInfoPage";
import NotificationSettingsPage from "./sub-pages/NotificationSettingsPage";
// import MediasPage from "./sub-pages/MediaPage";
import MediasPageTajwar from "./sub-pages/MediaPageTajwar";
// import SocialLinkPage from "./sub-pages/SocialLinkPage";
import SocialLinkPageTajwar from "./sub-pages/SocialLinkPageTajwar";
import InvokeLayoutComponent from "../../components/common/InvokeLogout";
import LoyaltySetting from "./sub-pages/Loyalty-Setting";
import { Grid, Button } from "@mui/material";

function GeneralSettingsPage() {
  const tabs = [
    {
      title: "Basic Info",
      id: "basic-info",
    },
    {
      title: "Media",
      id: "media",
    },
    {
      title: "Social Link",
      id: "social-link",
    },
    {
      title: "Notification Settings",
      id: "notification-settings",
    },
    {
      title: "Loyalty Settings",
      id: "Loyalty-settings",
    },
  ];

  const { search } = useLocation();
  const [currentTab, setCurrentTab] = useState(tabs[0].id);
  useEffect(
    () => {
      const query = new URLSearchParams(search);
      setCurrentTab(query.get("tab") ?? currentTab);
    },
    //eslint-disable-next-line
    [search]
  );
  const [shouldLogout, setShouldLogout] = useState(false);
  /**
   * Set the value as
   * {
   *    text: "Button text",
   *    onClick: onclick function
   * }
   */
  const [actionButtonFunctions, setActionButtonFunctions] = useState(null);
  const invokeLogout = () => setShouldLogout(true);
  return (
    <>
      <div>
        {/* Navigations */}
        <div
          className="row"
          style={{
            borderBottom: "2px solid #F1F5F9",
            paddingBottom: "10px",
            borderTopRightRadius: "10px",
            borderTopLeftRadius: "10px",
            borderBottomLeftRadius: "0px",
            borderBottomRightRadius: "0px",
            margin: "0",
            padding: " 2rem",
            height: "100%",
            background: "#fff",
          }}
        >
          <div
            style={{
              height: "25px",
              marginLeft: "-32px",
              backgroundColor: "#FFA382",
            }}
          >
            <span style={{ color: "#FFA382" }}>.</span>
          </div>
          <div className="col-md-3 d-flex">
            <div
              style={{ color: "#FFA382", fontSize: "18px", fontWeight: "600" }}
            >
              Settings
            </div>
          </div>
        </div>
        <div
          className="newSpace"
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "3em",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "1em 2em",
            borderBottom: "2px solid #F1F5F9",
            flexGrow: 1,
          }}
        >
          {tabs.map((obj, i) => {
            console.log(obj.id === currentTab);
            return (
              <Link
                key={i}
                to={`?tab=${obj.id}`}
                style={{ textDecoration: "none" }}
              >
                <div
                  style={{
                    backgroundColor: "white",
                    padding: "0.5em 1.5em",
                    color: obj.id === currentTab ? "#FFA382" : "#747474",
                    border: "none",
                    fontWeight: "bold",
                    borderRadius: "10px",
                  }}
                >
                  {obj.title}
                </div>
                <div
                  style={{
                    marginBottom: "-17px",
                    borderBottom:
                      obj.id === currentTab ? "6px solid #FFA382" : null,
                  }}
                >
                  <span style={{ color: "#FFA382", height: "15px" }}></span>
                </div>
              </Link>
            );
          })}
        </div>
        {/* tab-contents */}
        {currentTab === "basic-info" && (
          <BasicInfoPage invokeLogout={invokeLogout} />
        )}
        {currentTab === "media" && (
          <MediasPageTajwar invokeLogout={invokeLogout} />
        )}
        {currentTab === "social-link" && (
          <SocialLinkPageTajwar invokeLogout={invokeLogout} />
        )}
        {currentTab === "notification-settings" && (
          <NotificationSettingsPage invokeLogout={invokeLogout} />
        )}
        {currentTab === "Loyalty-settings" && (
          <LoyaltySetting invokeLogout={invokeLogout} />
        )}
        {shouldLogout && <InvokeLayoutComponent />}
      </div>
      <Grid justifyContent="flex-end">
        {actionButtonFunctions && (
          <Button variant="contained" onClick={actionButtonFunctions.onClick}>
            {actionButtonFunctions.text}
          </Button>
        )}
      </Grid>
    </>
  );
}

export default GeneralSettingsPage;
