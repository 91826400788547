import Select from "@mui/material/Select";
import styled from "@emotion/styled";
import { FormControl, MenuItem, Chip } from "@mui/material";

export const FormCustom = styled(FormControl)`
  width: 100% !important;
  height: 45px;
  margin-left: 0px !important;
  margin-right: 0px !important;
  border: 1px solid #ced4da !important;
  border-radius: 0.25rem;
`;
export const SelectCustom = styled(Select)`
  height: 45px;
  padding: 2px;
`;

export const ItemCustom = styled(MenuItem)`
  padding: 10px;
`;
export const ItemChip = styled(Chip)`
  border-radius: 10px;

  & .MuiChip-label {
    background-color: #ffa382 !important;
    padding: 10px;
    border-radius: 13px;
  }
`;
