import React, { useState, useEffect, useRef } from "react";
import url from "../../config/axios";
import { useHistory } from "react-router-dom";
import moment from "moment";
import Popup from "reactjs-popup";
import SelectPrimary from "../../atoms/SelectPrimary";
import Success from "../../assets/Success1.png";
import InputPrimary from "../../atoms/InputPrimary/Index2";
import Failure from "../../assets/failure.png";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useTheme } from "@mui/material/styles";
import CustomSelectPrimary from "../../atoms/CustomSelectPrimary/CustomSelectPrimary";
import Section from "../../atoms/Section";
import MultipleSelectPrimary from "../../atoms/MultipleSelectPrimary";
import {
  FormCustom,
  ItemChip,
  ItemCustom,
  SelectCustom,
} from "../../atoms/CustomMUISelect";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
export default function EditSpecial() {
  const history = useHistory();
  const theme = useTheme();
  const Coupon = JSON.parse(localStorage.getItem("specialcouponEdit"));
  console.log(Coupon, "Ourcoupon");
  const adminID = JSON.parse(localStorage.getItem("Admin"));
  const [activeStatus, setActiveStatus] = useState(false);
  const [allCoupon, setAllCoupon] = useState([]);
  const [couponCode, setCouponCode] = useState("");
  const [couponType, setCouponType] = useState("");
  const [eventType, setEventType] = useState("Birthday");
  const [discountValue, setDiscountValue] = useState("");
  const [specialDate, setSpecialDate] = useState();
  const [outName, setOutName] = useState([]);
  const [selOutlet, setSelOutlet] = useState([]);
  const [outlets, setOutlets] = useState([]);
  const [options, setOptions] = useState(null);
  const [selected, setSelected] = useState([]);
  const [selProduct, setSelProduct] = useState([]);
  const [appliesTo, setAppliesTo] = useState("Selected Products");
  const [products, setProducts] = useState([]);
  let year = new Date().getFullYear();
  const specialDaysArray = [
    { name: "Nurse", date: `${year}-05-12` },
    { name: "Firefighter", date: `${year}-05-05` },
    { name: "Veterans", date: `${year}-11-11` },
    { name: "Teachers", date: `${year}-05-15` },
  ];
  const [notificationText, setNotificationText] = useState("");
  const [workType, setWorkType] = useState("");
  const [message, setMessage] = useState({
    display: false,
    status: false,
    text: "",
  });

  const ref = useRef();
  const openTooltip = () => ref.current.open();
  const closeTooltip = () => {
    if (message.text === "Success") {
      history.goBack();
    } else {
      ref.current.close();
    }
  };
  useEffect(() => {
    setCouponCode(Coupon.couponCode);
    setCouponType(Coupon.couponType);
    setDiscountValue(Coupon.discountValue + "");
    setEventType(Coupon.eventType);
    setWorkType(Coupon?.workType ?? "");
    setSpecialDate(Coupon.eventDate);
    setNotificationText(Coupon?.notificationText ?? "");
    setActiveStatus(Coupon.status);
    setSelOutlet(Coupon.selectedOutlets);
    setSelProduct(Coupon.selectedProducts);
    if (!!Coupon?.selectedOutlets.length) {
      let objToPush = [];
      setOutName(
        Coupon?.selectedOutlets?.map((obj) => {
          if (!!obj) {
            objToPush = [
              ...objToPush,
              { outletChainID: obj.outletChainID, name: obj.name },
            ];
          }
          return !obj.outletChainID
            ? null
            : obj.outletChainID + "split" + obj.name;
        })
      );
      setSelOutlet(objToPush);
    }
    if (!!Coupon?.selectedProducts?.length) {
      let objToPush = [];

      Coupon?.selectedProducts
        ?.map((obj) => {
          if (!!obj.productID) {
            objToPush = [
              ...objToPush,
              { productID: obj.productID, name: obj.name },
            ];
          }
          return !obj.productID ? null : obj.productID + "split" + obj.name;
        })
        .filter((el) => el !== null);

      setSelProduct(objToPush);
      setSelected(
        objToPush.map((obj) => ({
          value: obj.productID,
          label: obj.name,
        }))
      );
    }
    if (!!Coupon?.entireCart) {
      setAppliesTo("Entire Cart");
    } else {
      setAppliesTo("Selected Products");
    }
    GetAllOutlets();
    GetAllCoupon();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const GetAllCoupon = async () => {
    try {
      const res = await url.get(
        `/specialEvents/all/?consumerId=${adminID[0].consumerId}`
      );
      if (res.data.Message === "Success") {
        let filtered = res.data.data.filter((i) => {
          return i.couponId !== Coupon.couponId;
        });
        setAllCoupon(filtered);
      }
    } catch (e) {
      console.log(e, "Error");
    }
  };
  const GetAllOutlets = () => {
    url
      .get(`/oc/?consumerId=${adminID[0].consumerId}`)
      .then(async (res) => {
        if (res.data.Message === "Success") {
          setOutlets(res.data.data);
        }
      })
      .catch((e) => console.log(e));
  };
  const filteredArray = (I) => {
    try {
      let result = allCoupon.find((obj) => obj.eventType === I);
      if (result === undefined) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      console.log(e, "Error");
    }
  };
  const filteredWorkArray = (I) => {
    try {
      let result = allCoupon.find((obj) => obj.workType === I);
      if (result === undefined) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      console.log(e, "Error");
    }
  };
  const handleChange = async (e) => {
    if (e !== "") {
      let find = specialDaysArray.find((i) => {
        return i.name === e;
      });
      setWorkType(find.name);
      setSpecialDate(moment(find.date));
    } else {
      setWorkType("");
      setSpecialDate("");
    }
  };
  const selectedOutlets = async (outl) => {
    console.log(outl, "Outiisufbiud");
    setOutName(
      // On autofill we get a the stringified value.
      typeof value === "string" ? outl.split("split") : outl
    );
    let obj = [];
    for (let i = 0; i < outl.length; i++) {
      var splitted = outl[i].split("split", 3);
      obj.push({ outletChainID: splitted[0], name: splitted[1] });
      setSelOutlet(obj);
    }
  };
  const GetAllProducts = async () => {
    if (!adminID[0].isSubOutlet) {
      url
        .get(`/inventory/?outletChainID=${selOutlet[0]?.outletChainID}`)
        .then(async (res) => {
          // console.log("Products", res.data.data);
          if (res.data.Message === "Success") {
            setProducts(res.data.data.slice(0, 100));
          } else {
            // alert(res.data.data);
            setProducts([]);
          }
        });
    } else {
      url
        .get(`/inventory/?outletChainID=${adminID[0].outletChainID}`)
        .then(async (res) => {
          // console.log("Products", res.data.data);
          if (res.data.Message === "Success") {
            setProducts(res.data.data.slice(0, 100));
          } else {
            // alert(res.data.data);
            setProducts([]);
          }
        });
    }
  };
  useEffect(() => {
    GetAllProducts();
  }, [selOutlet]);
  useEffect(() => {
    if (products.length > 0) {
      setOptions(
        products.map((item) => ({
          value: item.product.productID,
          label: item.product.name,
        }))
      );
    }
  }, [products]);
  // For there products
  useEffect(() => {
    if (selected.length > 0) {
      setSelProduct(
        selected.map((item) => ({
          productID: item.value,
          name: item.label,
        }))
      );
    }
  }, [selected]);

  let triedBefore = false;
  const [inProgress, setInProgres] = useState(false);
  const [formValidationErrors, setFormValidationErrors] = useState({});
  const validateForm = () => {
    let data = { ...formValidationErrors };
    //validate deal name: dealError
    if (notificationText.trim().length === 0) {
      data = {
        ...data,
        notificationTextError: "Notification text is required",
      };
    } else {
      let { notificationTextError, ...others } = data;
      data = { ...others };
    }
    // Validate couponCode: couponCode
    if (couponCode.trim().length === 0) {
      data = { ...data, couponCodeError: "Coupon Code is required" };
    } else {
      let { couponCodeError, ...others } = data;
      data = { ...others };
    }
    //Validate EventType: Event type
    if (eventType.trim().length === 0) {
      data = { ...data, eventTypeError: "Event type is required" };
    } else {
      let { eventTypeError, ...others } = data;
      data = { ...others };
    }
    //Validate Work Type: Work type
    if (eventType === "workAnniversary") {
      if (workType.trim().length === 0) {
        data = { ...data, workTypeError: "Work type is required" };
      } else {
        let { workTypeError, ...others } = data;
        data = { ...others };
      }
    } else {
      let { workTypeError, ...others } = data;
      data = { ...others };
    }
    //validate discount value: discountError
    if (couponType === "Percentage") {
      if (discountValue.trim().length === 0) {
        data = { ...data, discountError: "Discount value is required" };
      } else if (discountValue <= 0 || discountValue >= 100) {
        data = {
          ...data,
          discountError: "Please a choose a postive value within 100",
        };
      } else {
        let { discountError, ...others } = data;
        data = { ...others };
      }
    } else if (couponType === "Amount") {
      if (discountValue.trim().length === 0) {
        data = { ...data, discountError: "Discount value is required" };
      } else if (discountValue <= 0) {
        data = {
          ...data,
          discountError: "Positive discount value is required",
        };
      } else {
        let { discountError, ...others } = data;
        data = { ...others };
      }
    } else {
      let { discountError, ...others } = data;
      data = { ...others };
    }

    setFormValidationErrors({ ...data });
    return data;
  };

  useEffect(() => {
    if (triedBefore) {
      validateForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [discountValue, couponType, couponCode, triedBefore]);
  const editCoupon = () => {
    if (!adminID[0].isSubOutlet) {
      let formErrors = validateForm();
      if (Object.keys(formErrors).length === 0) {
        setInProgres(true);
        const admin = JSON.parse(localStorage.getItem("Admin"));
        const selectedOne = adminID[0].isSubOutlet
          ? [
              {
                outletChainID: adminID[0].outletChainID,
                name: adminID[0].outletName,
              },
            ]
          : selOutlet;
        const body = {
          consumerId: admin[0].consumerId,
          couponCode: couponCode,
          couponType: couponType,
          eventType: eventType,
          selectedOutlets: selectedOne,
          selectedProducts: appliesTo === "Selected Products" ? selProduct : [],
          eventDate: specialDate ?? new Date(),
          discountValue: Number.isNaN(+discountValue) ? "0" : discountValue,
          applyToAllOutlets: true,
          entireCart: appliesTo === "Entire Cart" ? true : false,
          workType: workType,
          notificationText,
          status: activeStatus,
          strict: true,
        };
        url
          .put(`/specialEvents/update/?couponId=${Coupon.couponId}`, body)
          .then(async (res) => {
            setInProgres(false);
            console.log(res, "Coupon Updated:");
            if (res.data.Message === "Success") {
              setMessage({
                text: "Success",
                status: true,
                display: true,
              });
              openTooltip();
            }
          })
          .catch((e) => {
            setMessage({
              text: "Failed",
              status: false,
              display: true,
            });
            openTooltip();
            setInProgres(false);
          });
      }
    } else {
      let formErrors = validateForm();
      const selectedOne = adminID[0].isSubOutlet
        ? [
            {
              outletChainID: adminID[0].outletChainID,
              name: adminID[0].outletName,
            },
          ]
        : selOutlet;
      if (Object.keys(formErrors).length === 0) {
        setInProgres(true);
        const admin = JSON.parse(localStorage.getItem("Admin"));
        const body = {
          consumerId: admin[0].consumerId,
          couponCode: couponCode,
          couponType: couponType,
          selectedOutlets: selectedOne,
          eventType: eventType,
          eventDate: specialDate ?? new Date(),
          selectedProducts: appliesTo === "Selected Products" ? selProduct : [],
          discountValue: Number.isNaN(+discountValue) ? "0" : discountValue,
          applyToAllOutlets: true,
          entireCart: appliesTo === "Entire Cart" ? true : false,
          workType: workType,
          notificationText,
          status: activeStatus,
          strict: true,
        };

        url
          .put(`/specialEvents/update?couponId=${Coupon.couponId}`, body)
          .then(async (res) => {
            setInProgres(false);
            if (res.data.Message === "Success") {
              setMessage({
                text: "Success",
                status: true,
                display: true,
              });
              openTooltip();
            } else {
              setMessage({
                text: "Failed",
                status: true,
                display: true,
              });
              openTooltip();
            }
          });
      }
    }
  };

  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <div className="card" style={{ padding: "0px !important" }}>
            <div>
              {/* <div ></div> */}
              <div
                className="row"
                style={{ borderBottom: "1px solid rgba(241, 245, 249, 1)" }}
              >
                <div
                  style={{
                    height: "25px",
                    marginLeft: "-18px",
                    marginTop: "6px",
                    backgroundColor: "#FFA382",
                  }}
                >
                  <span className="orangeColor">.</span>
                </div>
                <div className="col-md-3">
                  <h5 className="orangeColor">Special days</h5>
                </div>
                <div className="col-md-7"> </div>
                <div className="col-md-1 filters-bar d-flex">
                  <button
                    className="btn btn-primary border-0 "
                    style={{
                      backgroundColor: "#FFA382",
                      marginBottom: "5px",
                      marginLeft: "90px",
                    }}
                    onClick={() => {
                      history.push("/Special-Event");
                    }}
                  >
                    <i className="fas fa-arrow-left"></i>
                    Back
                  </button>
                </div>
              </div>
              {!adminID[0].isSubOutlet && (
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-12  mt-3">
                      <p
                        className="m-0"
                        style={{
                          color: theme.palette.gray.dark,
                          fontWeight: "600",
                        }}
                      >
                        Select Store
                      </p>
                      <FormCustom>
                        <SelectCustom
                          labelId="demo-multiple-chip-label"
                          id="demo-multiple-chip"
                          multiple
                          value={outName}
                          onChange={(e) => selectedOutlets(e.target.value)}
                          input={
                            <OutlinedInput
                              id="select-multiple-chip"
                              style={{ color: "black" }}
                              label="Select Outlet(s)"
                            />
                          }
                          renderValue={(selected) => (
                            <Box
                              sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: 0.5,
                              }}
                            >
                              {selected.map((value) => (
                                <ItemChip
                                  key={value}
                                  label={`${value.split("split")[1]}`}
                                />
                              ))}
                            </Box>
                          )}
                          IconComponent={() => <KeyboardArrowDownIcon />}
                          MenuProps={MenuProps}
                        >
                          {outlets.map((outlet, i) => (
                            <ItemCustom
                              key={i}
                              value={
                                outlet.outletChainID +
                                "split" +
                                outlet.outletName
                              }
                              style={getStyles(outlet, outName, theme)}
                            >
                              {outlet.outletName}
                            </ItemCustom>
                          ))}
                        </SelectCustom>
                      </FormCustom>
                    </div>
                  </div>
                </div>
              )}
              <div
                className="mt-4 orangeColor"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <h5 style={{ color: "#FFA382" }}>Basic information</h5>
              </div>

              <form>
                <div className="row">
                  <div className="col-md-12">
                    <div className="group-comb">
                      <div>
                        <InputPrimary
                          label="Coupon Code"
                          type="text"
                          value={couponCode}
                          disabled
                        />
                        {formValidationErrors.couponCodeError && (
                          <span className="emsg">
                            {formValidationErrors.couponCodeError}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div
                    className={
                      eventType === "workAnniversary" ? "col-md-6" : "col-md-12"
                    }
                  >
                    <div className="group-comb">
                      <label style={{ paddingLeft: "0.4em" }}>Event Type</label>
                      <div>
                        <SelectPrimary
                          value={eventType}
                          onChange={(e) => setEventType(e.target.value)}
                          className="newInputSelect"
                          id="exampleFormControlSelect1"
                        >
                          <option value="">Select Event type</option>

                          {filteredArray("Birthday") ? (
                            <option value="Birthday">Birthday</option>
                          ) : null}
                          {filteredArray("platformAnniversary") ? (
                            <option value="platformAnniversary">
                              Platform Anniversary
                            </option>
                          ) : null}
                          <option value="workAnniversary">Special Day</option>
                        </SelectPrimary>
                        {formValidationErrors.eventTypeError && (
                          <span className="emsg">
                            {formValidationErrors.eventTypeError}
                          </span>
                        )}
                        {/* <input type="text" value={couponType} onChange={(e) => setCouponType(e.target.value)} className="form-control" id="exampleFormControlInput1" placeholder="discount value" /> */}
                      </div>
                    </div>
                  </div>
                  {eventType === "workAnniversary" && (
                    <div className="col-md-6">
                      <div className="group-comb">
                        <label style={{ paddingLeft: "0.4em" }}>
                          Special Day
                        </label>
                        <div>
                          <SelectPrimary
                            value={workType}
                            onChange={(e) => {
                              handleChange(e.target.value);
                            }}
                            className="newInputSelect"
                            id="exampleFormControlSelect1"
                          >
                            <option value="">Select special day</option>
                            {specialDaysArray.map((i, key) => {
                              return filteredWorkArray(i.name) ? (
                                <option key={key} value={i.name}>
                                  {i.name} Day
                                </option>
                              ) : null;
                            })}
                          </SelectPrimary>

                          {formValidationErrors.workTypeError && (
                            <span className="emsg">
                              {formValidationErrors.workTypeError}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  {eventType === "workAnniversary" && workType !== "" && (
                    <div className="col-md-12">
                      <div className="alert alert-info alertBox">
                        <span className="orangeColor">
                          * This voucher will be valid for '{" "}
                          {moment(specialDate).format("DD MMMM ")}' only
                        </span>
                      </div>
                    </div>
                  )}

                  <div className="col-md-6">
                    <div className="group-comb">
                      <label style={{ paddingLeft: "0.4em" }}>
                        Coupon Type
                      </label>
                      <div>
                        <SelectPrimary
                          onChange={(e) => setCouponType(e.target.value)}
                          className=" newInputSelect"
                          id="exampleFormControlSelect1"
                        >
                          <option value="Percentage">Percentage</option>
                          <option value="Amount">Amount</option>
                        </SelectPrimary>
                      </div>
                    </div>
                  </div>
                  {couponType !== "Free Shiping" && (
                    <div className="col-md-6">
                      <div className="group-comb">
                        <label>
                          {couponType === "Percentage"
                            ? "Discount value %"
                            : "Discount value"}
                        </label>
                        <div>
                          <input
                            type="number"
                            value={discountValue}
                            onKeyPress={(e) => {
                              var charCode =
                                typeof e.which == "undefined"
                                  ? e.keyCode
                                  : e.which;
                              var charStr = String.fromCharCode(charCode);

                              if (!charStr.match(/^[0-9]+$/))
                                e.preventDefault();
                            }}
                            onChange={(e) => {
                              e.preventDefault();
                              if (e.target.value.match(/^[0-9]*$/)) {
                                if (couponType === "Percentage") {
                                  if (Math.abs(e.target.value) <= 99) {
                                    setDiscountValue(
                                      parseInt(Math.abs(e.target.value)) + ""
                                    );
                                  } else {
                                    setDiscountValue("");
                                  }
                                } else {
                                  setDiscountValue(
                                    Math.abs(e.target.value) + ""
                                  );
                                }
                              }
                            }}
                            className="form-control"
                            id="exampleFormControlInpu1"
                            placeholder={
                              couponType === "Percentage" ? "20 %" : "$ 1000"
                            }
                            style={
                              couponType !== "Percentage" &&
                              discountValue !== ""
                                ? {
                                    paddingLeft: "10px",
                                    marginTop: "9px",
                                    height: "6.9vh",
                                  }
                                : { marginTop: "8.3px", height: "6.9vh" }
                            }
                            min={0}
                            max={couponType === "Percentage" ? 99 : 999999999}
                          />
                          {discountValue !== "" ? (
                            couponType === "Percentage" ? (
                              <span
                                style={{
                                  position: "absolute",
                                  top: "54px",
                                  left: `${
                                    Math.pow((discountValue + "").length, 3) +
                                    40
                                  }px`,
                                }}
                              >
                                %
                              </span>
                            ) : (
                              <span
                                style={{
                                  position: "absolute",
                                  top: "57px",
                                  left: "16px",
                                }}
                              >
                                $
                              </span>
                            )
                          ) : (
                            ""
                          )}
                          {formValidationErrors.discountError && (
                            <span className="emsg">
                              {formValidationErrors.discountError}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="col-md-12">
                    <div class="alert alert-info alertBox" role="alert">
                      <span className="orangeColor">
                        * Coupon will be applicable for <b>Entire cart</b>
                      </span>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <Section label="Applies To">
                      <CustomSelectPrimary
                        label="Applies To"
                        options={[
                          {
                            label: "Selected Products",
                            value: "Selected Products",
                          },
                          { label: "Entire Cart", value: "Entire Cart" },
                        ]}
                        value={appliesTo}
                        setValue={setAppliesTo}
                      />
                      <div>
                        {appliesTo === "Selected Products" && (
                          <MultipleSelectPrimary
                            label="Pick Products"
                            inputLabel="Select Products"
                            options={options}
                            selected={selected}
                            setSelected={setSelected}
                          />
                        )}
                        {formValidationErrors.selectError && (
                          <span className="emsg">
                            {formValidationErrors.selectError}
                          </span>
                        )}
                      </div>
                    </Section>
                  </div>
                  <div
                    className="col-md-12"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <h5 className="orangeColor">Notification Text</h5>
                    <div>
                      <input
                        type="text"
                        value={notificationText}
                        onChange={(e) => setNotificationText(e.target.value)}
                        className="form-control"
                        // style={{ height: "20vh", borderRadius: "25px" }}
                      />
                      {formValidationErrors.notificationTextError && (
                        <span className="emsg">
                          {formValidationErrors.notificationTextError}
                        </span>
                      )}
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-12">
                        <h5 style={{ color: "#FFA382" }}>Status</h5>
                      </div>

                      <div className="col-md-6 switch-col">
                        <label className="switch switch-demo">
                          <input
                            type="checkbox"
                            checked={activeStatus}
                            onChange={() => setActiveStatus(!activeStatus)}
                          />
                          <span className="slider round"></span>
                          <span
                            className="slider-text"
                            style={
                              activeStatus
                                ? { paddingLeft: "81px", color: "#FFA382" }
                                : { paddingLeft: "81px", color: "red" }
                            }
                          >
                            {activeStatus ? "Enabled" : "Disabled"}
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="d-flex justify-content-end border-0">
            <button
              type="button"
              onClick={() => editCoupon()}
              className="btn btn-cannaby"
              style={{ backgroundColor: "#FFA382" }}
              disabled={inProgress}
            >
              {inProgress ? "Updating..." : "Update Coupon"}
            </button>
          </div>
        </div>
      </div>
      <Popup ref={ref} position="center">
        {message.status ? (
          <div style={{ textAlign: "center" }}>
            <div>
              <img height="60" alt="" src={Success} />
            </div>

            <p className="popup-heading" style={{ marginTop: "20px" }}>
              Updated successfully <br />
              <span
                style={{
                  color: "#747474",
                  fontSize: "10px",
                  marginTop: "-10px !important",
                }}
              >
                Coupon is updated{" "}
              </span>
            </p>
            <button
              className="btn btn-cannaby"
              style={{
                backgroundColor: "#FFA382",
                marginTop: "0",
                width: "20vh",
                alignSelf: "center",
              }}
              onClick={() => {
                closeTooltip();
              }}
            >
              OK
            </button>
          </div>
        ) : (
          <div style={{ textAlign: "center" }}>
            <div>
              <img alt="" src={Failure} />
            </div>
            <p style={{ marginTop: "20px" }}>Something Went Wrong</p>
            <button
              type="button"
              onClick={() => {
                closeTooltip();
              }}
              className="btn btn-primary border-0"
              style={{
                backgroundColor: "#FFA382",
                marginTop: "0",
                width: "20vh",
                alignSelf: "center",
              }}
            >
              ok
            </button>
          </div>
        )}
      </Popup>
    </div>
  );
}
