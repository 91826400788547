/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
// eslint-disable-next-line react-hooks/exhaustive-deps
// import crown from "../../assets/crown.png";
// import dollar from "../../assets/dollar.png";
import phone from "../../assets/phone.svg";
import mail from "../../assets/mail.svg";

import url from "../../config/axios";
import blank from "../../assets/defaultImage.jpg";
import { useHistory } from "react-router-dom";
import abandon from "../../assets/abandoncart.svg";
import dollar from "../../assets/dollar.png";
import chatbutton from "../../assets/chatbutton.svg";
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// import dollar from "../../assets/dollar.png";
import { useState, useEffect, useMemo } from "react";
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from "moment";
import { useDispatch } from "react-redux";
import { setActiveSessionThunk } from "../../redux/slices/chatSessionSlice";
import "./users.css";

export function checkColor(value) {
  let result;
  switch (value) {
    case "Pending":
      result = "red";
      break;
    case "New":
      result = "blue";
      break;
    case "Cancelled":
      result = "brown";
      break;
    case "Delivered":
      result = "purple";
      break;
    case "Completed":
      result = "#0CD600";
      break;
    default:
      result = "";
      break;
  }
  return result;
}
export function backgroundColor(value) {
  let result;
  switch (value) {
    case "Pending":
      result = "rgba(255, 109, 109, 0.08)";
      break;
    case "New":
      result = "rgba(108, 149, 255, 0.1)";
      break;
    case "Cancelled":
      result = "rgba(255, 109, 109, 0.08)";
      break;
    case "Delivered":
      result = "color-purple";
      break;
    case "Completed":
      result = "rgba(12, 214, 0, 0.1)";
      break;
    default:
      result = "";
      break;
  }
  return result;
}

export default function UserDetails() {
  const [cart, setCart] = useState([]);
  const history = useHistory();

  const [orders, setOrders] = useState([]);
  const [loyalty, setLoyalty] = useState([]);
  const user = JSON.parse(localStorage.getItem("singleUser"));
  const admin = JSON.parse(localStorage.getItem("Admin"));
  const [pointsToPrice, setPointsToPrice] = useState();
  const [editMed, setEditMed] = useState(true);
  const [editLic, setEditLic] = useState(true);
  const [med, setMed] = useState("");
  const [lic, setLic] = useState("");
  const [lastActivity, setLastActivity] = useState("");
  const [messages, setMessage] = useState("");
  const [mlexpiry, setMlexpiry] = useState("");
  const [age, setAge] = useState("");
  const [image, setImage] = useState("");
  const [session, setSession] = useState("");
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const [formValidation, setFormValidation] = useState({});

  const initialPaginationData = {
    skip: 0,
    limit: 100,
    userID: user.userID,
  };
  const [queryData, setQueryData] = useState({
    ...initialPaginationData,
  });
  console.log(setQueryData);
  useEffect(() => {
    getAge();
    getUserDetails();
    getOrderUser();
    GetLoyaltyStandards();
    GetLoyaltyPoints();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  function getAge() {
    var today = new Date();
    var birthDate = new Date(user.dob);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    console.log(age, "age");
    setAge(age);
  }
  const getUserDetails = async () => {
    try {
      url.get(`/v2/users/user-details/?userID=${user.userID}`).then((res) => {
        console.log(res.data.data, "Response");
        setLastActivity(res.data.data.lastActivityDate);
        setMessage(
          res.data.data?.messageSessionInfo?.messages[0]?.contentGroup[0]
            ?.content
        );
        setSession(res.data.data?.messageSessionInfo?._id);
        setImage(res.data.data?.userInfo?.image);
        setMed(res.data.data?.userInfo.medicalLicense);
        setLic(res.data.data?.userInfo.drivingLicense);
        setMlexpiry(res.data.data?.userInfo.mlExpiry);
        setCart(res.data.data.cartProducts);
      });
    } catch (e) {
      console.log(e);
    }
  };
  const updateLicense = async () => {
    let data = { ...formValidation };
    console.log(med, "license value");
    if (med === "" && lic === "") {
      data = {
        ...data,
        LicError: "Medical Licence cannot be empty",
        DrivError: "Driver Licence cannot be empty",
      };
      setFormValidation(data);
    } else if (med === "") {
      const { DrivError, ...others } = data;
      data = { ...others, LicError: "Medical Licence cannot be empty" };
      setFormValidation(data);
    } else if (lic === "") {
      const { LicError, ...others } = data;
      data = { ...others, DrivError: "Driver Licence cannot be empty" };
      setFormValidation(data);
    } else {
      const { LicError, DrivError, ...others } = data;
      data = { ...others };
      setFormValidation(data);
      let body = {
        medicalLicense: med,
        drivingLicense: lic,
        mlExpiry: moment(mlexpiry).format(),
      };
      try {
        url
          .put(`/v1/auth/update-license-info/?userID=${user.userID}`, body)
          .then((res) => {
            getUserDetails();
          });
      } catch (e) {
        console.log(e);
      }
    }
  };

  const getOrderUser = async () => {
    try {
      let queryParams = { ...queryData };
      url
        .get(`/v2/users/orders-by-user`, {
          params: {
            ...queryParams,
          },
        })
        .then((res) => {
          if (res.data.Message === "Success") {
            setOrders(res.data.data.orders);
          } else {
            console.log(res.data.data);
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
  const GetLoyaltyPoints = async () => {
    await url
      .get(
        `/loyaltyPoints/id?userID=${user.userID}&outletID=${admin[0].adminID}`
      )
      .then(async (res) => {
        console.log("response", res);
        if (res.data.Message === "Success") {
          setLoyalty(res.data.data);
        } else {
          url
            .post(`/loyaltyPoints?outletID=${admin[0].outletChainID}`, {
              userID: user.userID,
              points: 0,
            })
            .then(async (res) => {
              if (res.data.Message === "Success") {
                GetLoyaltyPoints();
              }
            })
            .catch((e) => console.log(e));
        }
      })
      .catch((e) => console.log(e));
  };

  const GetLoyaltyStandards = async () => {
    await url
      .get(`/loyalty?consumerId=${admin[0].consumerId}`)
      .then(async (res) => {
        if (res.data.Message === "Success") {
          setPointsToPrice(res.data.data.pointsToPrice);
          setLoading(!loading);
        }
      })
      .catch((e) => console.log(e));
  };

  var num = 0;
  const color = ["#03b7a5", "#fc3986", "#ab48d5", "#2bb72b", "#bdd72a"];

  function assignColor() {
    var random = Math.floor(Math.random() * color.length);
    while (num === random) {
      random = Math.floor(Math.random() * color.length);
    }
    num = random;
    var value = color[random];
    return value;
  }
  const userImage = useMemo(() => {
    return (
      <>
        {image ? (
          <img
            style={{
              width: "50px",
              height: "50px",
              borderRadius: "25px",
              marginLeft: "auto",
              marginRight: "auto",
            }}
            src={image ? image : blank}
          />
        ) : (
          <div
            className="image-thumbnail-div"
            style={{
              backgroundColor: assignColor(),
              width: "40px",
              height: "40px",
              borderRadius: "20px",
              marginLeft: "auto",
              marginRight: "auto",
            }}>
            <span>{user?.name?.substring(0, 1)}</span>
          </div>
        )}
      </>
    );
  }, [image]);
  return (
    <div className="row">
      <>
        <div className="col-md-4">
          <div
            id="userDetailDiv"
            className="card-new usr-m-b"
            style={{ height: "97%", padding: "0px !important" }}>
            <div className="card-history-user">
              <div className="row">
                <div className="col-md-12 cust-user-img d-flex justify-content-around">
                  <div>{userImage}</div>
                  <div className="pl-2">
                    <div className="cust-user-namenew">
                      {user.name} {`(${age ? age : ""})`}
                    </div>
                    <div className="cust-user-activity">
                      <p style={{ textAlign: "left", fontSize: "10px" }}>
                        Last active on{" "}
                        {moment(lastActivity).format("MMM.Do, YYYY h:mm A")}
                      </p>
                    </div>
                  </div>
                </div>
                {/* Adding membership box from here */}
                <div className="col-md-12 cust-user-member">
                  <div className="chatDiv">
                    <img
                      onClick={() => {
                        if (session) {
                          dispatch(setActiveSessionThunk(session));
                          history.push("/chat");
                        }
                      }}
                      style={{ height: "35px", marginLeft: "10px" }}
                      src={chatbutton}
                    />
                    <span className="newMsg">
                      {messages
                        ? messages.substring(0, 30)
                        : `No new messages from ${user.name.substring(0, 6)}..`}
                    </span>
                  </div>
                  <div
                    className="cust-user-member-parent"
                    style={{ backgroundColor: "rgba(255, 199, 0, 0.11)" }}>
                    <div className="row cust-user-member-child">
                      <div className="col-md-12 mem-header">Membership</div>
                      {/* <div className="col-md-1 mem-icon">
                        <img src={crown} alt="crown" />
                      </div> */}
                      {/* <div className="col-md-11 mem-text">
                        Premium Member (Level 2)
                      </div> */}

                      <div className="col-md-1 mem-icon">
                        <img src={dollar} alt="dollar" />
                      </div>
                      <div className="col-md-11 mem-text">
                        {loyalty.points} Loyalty Points
                        <span style={{ color: "#192746", fontWeight: "300" }}>
                          ( $
                          {loyalty.points
                            ? loyalty.points * pointsToPrice
                            : "0"}
                          )
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Medicla licesnse number div */}
                <div className="col-md-12 mt-2">
                  <div
                    className="rounded h-100"
                    style={{ border: "1px solid rgba(207, 207, 207, 0.25)" }}>
                    <div className="d-flex pt-2 pl-2 pr-2 justify-content-between">
                      <p style={{ color: "#FFA382", fontSize: "13px" }}>
                        Medical License Numbers:
                      </p>
                    </div>

                    <div className="d-flex pl-2 pr-0 justify-content-between">
                      <div className="d-flex flex-column w-100">
                        <input
                          disabled={editMed}
                          className={editMed ? "border-0" : "border-1"}
                          style={{
                            backgroundColor: "rgba(255, 255, 255, 0.7)",
                            color: "#192746",
                            width: "100%",
                            fontSize: "12px",
                          }}
                          onKeyPress={(event) => {
                            if (event.key === " ") {
                              event.preventDefault();
                            }
                          }}
                          maxLength={24}
                          defaultValue={med}
                          value={med}
                          onChange={(e) => setMed(e.target.value)}
                        />
                        {editMed ? (
                          <span style={{ color: "#747474", fontSize: "10px" }}>
                            Expiry: {moment(mlexpiry).format("MM YYYY")}
                          </span>
                        ) : (
                          <input
                            style={{
                              backgroundColor: "rgba(255, 255, 255, 0.7)",
                              color: "#192746",
                              width: "60%",
                              fontSize: "12px",
                              marginTop: "3px",
                            }}
                            defaultValue={mlexpiry}
                            value={moment(mlexpiry).format("YYYY-MM-DD")}
                            onChange={(e) => setMlexpiry(e.target.value)}
                            type="date"
                          />
                        )}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "0.3em",
                          justifyContent: "flex-end",
                        }}
                        onClick={() => setEditMed(!editMed)}>
                        <i
                          style={{
                            paddingTop: "2px",
                            color: "#FFA382",
                            paddingLeft: "3px",
                            fontSize: "10px",
                          }}
                          className="fas fa-pen"></i>
                        <p style={{ color: "#FFA382", fontSize: "12px" }}>
                          {" "}
                          Edit{" "}
                        </p>
                      </div>
                    </div>
                    {formValidation.LicError && (
                      <p className="emsg">Medical license cannot be empty</p>
                    )}
                    {!editMed && (
                      <div className="d-flex justify-content-end">
                        <button
                          className="border-0 "
                          onClick={() => {
                            setEditMed(!editMed);
                            updateLicense();
                          }}
                          style={{
                            backgroundColor: "#FFA382",
                            color: "white",
                            fontSize: "12px",
                            borderRadius: "5px",
                          }}>
                          Update
                        </button>
                      </div>
                    )}
                    <hr className=" p-1" style={{ width: "90%" }} />
                    <div className="d-flex pl-2 pr-2  justify-content-between">
                      <p style={{ color: "#FFA382", fontSize: "13px" }}>
                        Drivers License Numbers:
                      </p>
                    </div>
                    <div className="d-flex  pl-2 pr-0 justify-content-between">
                      <div className="d-flex flex-column w-100">
                        <input
                          disabled={editLic}
                          className={editLic ? "border-0" : "border-1"}
                          style={{
                            backgroundColor: "rgba(255, 255, 255, 0.7)",
                            color: "#192746",
                            width: "100%",
                            fontSize: "12px",
                          }}
                          onKeyPress={(event) => {
                            if (event.key === " ") {
                              event.preventDefault();
                            }
                          }}
                          maxLength={24}
                          defaultValue={lic}
                          value={lic}
                          onChange={(e) => setLic(e.target.value)}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "0.3em",
                        }}
                        onClick={() => setEditLic(!editLic)}>
                        <i
                          style={{
                            paddingTop: "2px",
                            color: "#FFA382",
                            paddingLeft: "3px",
                            fontSize: "10px",
                          }}
                          className="fas fa-pen"></i>
                        <p style={{ color: "#FFA382", fontSize: "12px" }}>
                          {" "}
                          Edit{" "}
                        </p>
                      </div>
                    </div>
                    {formValidation.DrivError && (
                      <p className="emsg">Driver license cannot be empty</p>
                    )}
                    {!editLic && (
                      <div className="d-flex justify-content-end">
                        <button
                          className="border-0 "
                          onClick={() => {
                            setEditLic(!editLic);
                            updateLicense();
                          }}
                          style={{
                            backgroundColor: "#FFA382",
                            color: "white",
                            fontSize: "12px",
                            borderRadius: "5px",
                          }}>
                          Update
                        </button>
                      </div>
                    )}
                    <hr className=" p-1" style={{ width: "90%" }} />
                  </div>
                </div>
                {/*  */}
                {/* Personal details */}
                <div className="col-md-12 mt-2">
                  <div
                    className="rounded h-100 mb-3"
                    style={{ border: "1px solid rgba(207, 207, 207, 0.25)" }}>
                    <div className="d-flex pt-2 pl-2 pr-2  justify-content-between">
                      <p style={{ color: "#FFA382", fontSize: "14px" }}>
                        Personal details
                      </p>
                    </div>
                    <div className="d-flex pl-2 pr-2 justify-content-between">
                      <p style={{ color: "#747474" }}>Gender:</p>
                      <p style={{ color: "#192746" }}>{user.gender}</p>
                    </div>
                    <div className="d-flex pl-2 pr-2 justify-content-between">
                      <p style={{ color: "#747474" }}>DOB:</p>
                      <p style={{ color: "#192746" }}>
                        {moment(user.dob).format("DD-MM-YYYY")}
                      </p>
                    </div>
                    <div className="d-flex pl-2 pr-2 justify-content-between">
                      <p style={{ color: "#747474" }}>Profession:</p>
                      <p style={{ color: "#192746" }}>{user.profession}</p>
                    </div>
                    <hr className=" p-1" style={{ width: "90%" }} />
                    <div className="d-flex pl-2 pr-2  justify-content-between">
                      <p style={{ color: "#FFA382", fontSize: "14px" }}>
                        Contact
                      </p>
                    </div>
                    <div className="col-md-12 d-flex justify-content-start">
                      <div className="cIcon mr-3">
                        <img src={phone} alt="crown" />
                      </div>
                      <div className="cText pr-1" style={{ fontSize: "13px" }}>
                        {user.mobileNo ?? "+92301231"}
                      </div>
                    </div>
                    <div className="col-md-12 d-flex justify-content-start">
                      <div className="cIcon mr-3">
                        <img src={mail} alt="crown" />
                      </div>
                      <div className="cText pr-1" style={{ fontSize: "13px" }}>
                        {user.email}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-8">
          <div className="row" style={{ height: "100%" }}>
            <div
              className="col-md-12"
              style={{ position: "relative", height: "400px" }}>
              <div className="card-new" style={{ height: "100%" }}>
                <div className="card-history">
                  <div className="row">
                    <div
                      className="col-md-12 card-title"
                      style={{ borderBottom: "1px solid #F1F5F9" }}>
                      <div
                        style={{
                          height: "40px",
                          marginLeft: "-15px",
                          marginRight: "15px",
                          backgroundColor: "#FFA382",
                        }}>
                        <span style={{ color: "#FFA382" }}>.</span>
                      </div>
                      <span style={{ color: "#FFA382" }}>Order History</span>
                    </div>
                    <div className="col-md-12 table-mod">
                      <div
                        className="row-cust"
                        style={{ backgroundColor: "#F8FAFC" }}>
                        <div
                          className="cust-row-id th"
                          style={{ color: "747474 !important" }}>
                          Order ID
                        </div>
                        <div
                          className="cust-row-price th"
                          style={{ justifyContent: "center", color: "747474" }}>
                          Price
                        </div>
                        <div
                          className="cust-row-date th"
                          style={{ color: "747474" }}>
                          Purchased On
                        </div>
                        <div
                          className="cust-row-status th"
                          style={{ color: "747474" }}>
                          Status
                        </div>
                        <div
                          className="cust-row-btn th"
                          style={{ color: "747474" }}>
                          {" "}
                          Action
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 table-mod table-mod-data custom-scrollbar">
                      {orders &&
                        orders.map((order, i) => {
                          return (
                            <div className="row-cust r-c-d" key={i}>
                              <div className="cust-row-id ttd tdi ">
                                <span
                                  style={{
                                    color: "#192746",
                                    fontWeight: "bold",
                                  }}>
                                  #{order.orderID}
                                </span>
                              </div>
                              <div
                                className=" cust-row-price ttd tdp"
                                style={{ justifyContent: "center" }}>
                                ${order.totalPrice}
                              </div>
                              <div className="cust-row-date ttd tdd">
                                {" "}
                                {moment(order.createdAt).format(
                                  "MMM.Do, YYYY h:mm A"
                                )}
                              </div>
                              <div className="cust-row-status ttd tds">
                                <div
                                  className="newClass"
                                  id="userStatus"
                                  style={{
                                    backgroundColor: `${backgroundColor(
                                      order.status
                                    )}`,
                                  }}>
                                  <p
                                    style={{
                                      fontWeight: "400",
                                      verticalAlign: "middle",
                                      textAlign: "center",
                                      fontSize: "13px",
                                      paddingTop: "4px",
                                      color: `${checkColor(order.status)}`,
                                    }}>
                                    {order.status}
                                  </p>
                                </div>
                              </div>
                              <div className=" cust-row-btn">
                                <button
                                  className="btn newViewDetail"
                                  onClick={() => {
                                    localStorage.setItem(
                                      "singleOrder",
                                      JSON.stringify(order)
                                    );
                                    window.oID = order?.orderID;
                                    history.push(
                                      `/order-detail?_id=${order?._id}`
                                    );
                                  }}>
                                  <i className="fas fa-eye"></i>
                                  <u>View Details</u>
                                </button>
                              </div>
                            </div>
                          );
                        })}
                      {orders.length === 0 ? (
                        <div className="mt-4" style={{ textAlign: "center" }}>
                          <img src={abandon} />
                          <p>No Order has been placed yet</p>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  {/* {orders.length === 0 || orders === undefined ? (
                    <h5
                      style={{
                        opacity: "30%",
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%,50%)",
                      }}>
                      No order has been placed yet
                    </h5>
                  ) : (
                    ""
                  )} */}
                </div>
              </div>
            </div>
            <div className="col-md-12 " style={{ marginTop: "-9vh" }}>
              <div className="card-new">
                <div className="card-history">
                  <div className="row">
                    <div className="col-md-12 card-title ">
                      <div
                        style={{
                          height: "40px",
                          marginLeft: "-15px",
                          backgroundColor: "#FFA382",
                          marginRight: "15px",
                        }}>
                        <span style={{ color: "#FFA382" }}>.</span>
                      </div>
                      <span style={{ color: "#FFA382" }}>Abandoned Cart</span>
                    </div>
                    <div className="col-md-12 table-ac table-mod table-mod-data-ac custom-scrollbar">
                      {cart &&
                        cart?.map((item, i) => {
                          console.log(item, "Items");
                          return (
                            <div
                              className="row-cust-ac"
                              style={{ borderBottom: "1px solid #EDEDED" }}>
                              <div className="cust-row-ac-img">
                                <img
                                  className="productImage"
                                  alt=""
                                  src={
                                    item?.product?.image
                                      ? item?.product?.image
                                      : item?.image
                                      ? item?.image
                                      : blank
                                  }
                                />
                              </div>
                              <div className="cust-row-ac-body">
                                {item?.product?.name
                                  ? item?.product?.name
                                  : item?.name
                                  ? item?.name
                                  : null}
                              </div>
                              <div className="cust-row-ac-desc">
                                <span>
                                  Brand :
                                  <span className="ac-green">
                                    {item?.product?.brandName
                                      ? item?.product?.brandName
                                      : item?.brandName
                                      ? item?.brandName
                                      : " N/A"}
                                  </span>
                                </span>
                              </div>
                              <div className="cust-row-ac-price">
                                {/* {item.quantity} x {item.variant.price} */}
                                {item?.product?.purchaseQuantity} x $
                                {item?.variant?.price ?? item?.product?.price}
                              </div>
                            </div>
                          );
                        })}
                      {cart.length === 0 ? (
                        <div className="mt-4" style={{ textAlign: "center" }}>
                          <img src={abandon} />
                          <p>No product has been added to cart</p>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              {/* {cart.length === 0 ||
                cart === undefined ? (
                <h5
                  style={{
                    opacity: "30%",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%,50%)",
                  }}
                >
                  aja
                </h5>
              ) : (
                ""
              )} */}
            </div>
          </div>
          {/* card 2 */}
        </div>
      </>
    </div>
  );
}
