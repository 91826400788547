import react, { useEffect, Fragment, useState } from "react"
import { useHistory } from "react-router-dom";
import LoginPageContainer from "../../components/LoginPage/LoginPageContainer";
import * as crypt from "crypto-js";
import { CircularProgress } from "@mui/material";

const LoginPage = () => {
    const history = useHistory();
    const [initalDataParsed, setInitialDataParsed] = useState(false);
    const [rememberMeState, setRememberMeState] = useState(false);
    const SECRET = "0b448dea-0ea2-4d12-821c-af1559b604a3";

    const [formData, setFormData] = useState({
        email: "",
        password: "",
        loading: false,
        success: false,
        message: "",
    });
    const parseInitialData = async () => {
        //check if previously logged in or not
        try {
            const adminID = await JSON.parse(localStorage.getItem("Admin"));
            if (adminID && Array.isArray(adminID)) {
                history.push("/dashboard");
            }
        } catch (e) {
            //do nothing
        }

        //handle remember me
        try {
            const tempRemeberMeState = await JSON.parse(
                localStorage.getItem("remeberMeState") ?? "{}"
            );
            // console.log(`Got cached : `, tempRemeberMeState);
            if (tempRemeberMeState) {
                let pass = "";
                if (tempRemeberMeState.password) {
                    try {
                        pass = crypt.AES.decrypt(
                            tempRemeberMeState.password,
                            SECRET
                        ).toString(crypt.enc.Utf8);
                    } catch (e) {
                        console.error(e);
                    }
                }
                setFormData({
                    ...formData,
                    email: tempRemeberMeState?.email ?? "",
                    password: pass,
                });
                if (tempRemeberMeState?.email) {
                    setRememberMeState(true);
                }
            } else {
                setRememberMeState(false);
            }
        } catch (e) {
            //do nothing
        }
        setInitialDataParsed(true);
    };
    useEffect(
        () => {
            parseInitialData();
        },
        //eslint-disable-next-line
        []
    );
    return (
        <>
            {
                initalDataParsed ?
                    <Fragment>

                        < LoginPageContainer rememberMeState={rememberMeState} />

                    </Fragment >
                    :
                    <div className="d-flex justify-content-center w-100 p-5">
                        <CircularProgress color="inherit" />
                    </div>
            }
        </>
    )

}
export default LoginPage