import { useState, useEffect, Fragment } from "react";
import { useHistory } from "react-router-dom";
import TimePicker from "@mui/lab/TimePicker";
import TextField from "@mui/material/TextField";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import moment from "moment";
import CircularProgress from "@mui/material/CircularProgress";
import url from "../../../config/axios";
import { State } from "country-state-city";
import { countries } from "../../../helpers/countriesList";
import { ResetPasswordComponent } from "../../../components/BasicInfoPage/ResetPassword-Updated";
import { timeZoneData } from "../../../helpers/timeZones";
import SelectPrimary from "../../../atoms/SelectPrimary";
import PhoneNumberInput from "../../../atoms/PhoneNumberInput/PhoneNumberInput";
import "./Sub.css";
function BasicInfoPage({ invokeLogout }) {
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [statesList, setStatesList] = useState([]);
  const [resetPasswordWindowOpen, setResetPasswordWindowOpen] = useState(false);
  // const [citiesList, setCitiesList] = useState([]);
  const [formData, setFormData] = useState({
    siteTitle: "",
    supportNumber: "",
    supportEmail: "",
    address: "",
    state: "",
    country: "",
    longitude: "",
    latitude: "",
    mainHeading: "",
    headerLink: "",
    copyright: "",
    regularTimingFrom: new Date(new Date().setHours(8)),
    regularTimingTo: new Date(new Date().setHours(10)),
    assessmentFee: 200,
    timezone: "US/Central",
  });
  // const [phoneNumber, setPhoneNumber] = useState({
  //   countryCode: null,
  //   number: null
  // })
  // useEffect(() => {
  //   setFormData({
  //     ...formData,
  //     supportNumber: phoneNumber.countryCode + phoneNumber.number
  //   })
  // }, [phoneNumber])
  const setCountryAndGetState = (country) => {
    console.log(State.getStatesOfCountry(country.split(",")[1]));
    setCountry(country.split(",")[0]);
    setStatesList(State.getStatesOfCountry(country.split(",")[1]));
    setState(State.getStatesOfCountry(country.split(",")[1])[0].name); // setStatesList(State.getStatesOfCountry('US'));
  };

  const [inProgress, setInProgress] = useState(true);
  const handleChangeFromEvent = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const [formValidationErrors, setFormvalidationErrors] = useState({});
  const validateForm = () => {
    // console.log("from", formData.regularTimingFrom);
    // console.log("to", formData.regularTimingTo);
    let data = { ...formValidationErrors };
    //manipualte starting from time
    if (new Date(formData.regularTimingFrom).toString() === "Invalid Date") {
      data = { ...data, rtfError: "Invalid time" };
    } else if (
      new Date(formData.regularTimingFrom) > new Date(formData.regularTimingTo)
    ) {
      data = { ...data, rtfError: "Invalid timeslot" };
    } else {
      const { rtfError, ...others } = data;
      data = { ...others };
    }
    //manipulate ending to time
    if (new Date(formData.regularTimingTo).toString() === "Invalid Date") {
      data = { ...data, rttError: "Invalid time" };
    } else if (
      new Date(formData.regularTimingFrom) > new Date(formData.regularTimingTo)
    ) {
      data = {
        ...data,
        rttError: "Invalid time slot",
      };
    } else {
      let { rttError, ...others } = data;
      data = { ...others };
    }
    //manipulate longitude
    if ((formData.longitude + "").trim().length === 0) {
      data = { ...data, longError: "longitude is required" };
    } else {
      let { longError, ...others } = data;
      data = { ...others };
    }

    //manipulate latitude
    if ((formData.latitude + "").trim().length === 0) {
      data = { ...data, latError: "latitude is required" };
    } else {
      let { latError, ...others } = data;
      data = { ...others };
    }

    //manipulate copyright
    if ((formData.headerLink + "").trim().length !== 0) {
      try {
        new URL(formData.headerLink);
        let { hlError, ...others } = data;
        data = { ...others };
      } catch (e) {
        data = { ...data, hlError: "Enter a valid URL" };
      }
    } else {
      let { hlError, ...others } = data;
      data = { ...others };
    }

    //manipulate country
    if (formData.country.trim().length === 0) {
      data = { ...data, countryError: "Country is required" };
    } else {
      let { countryError, ...others } = data;
      data = { ...others };
    }

    //manipulate state
    if (formData.state.trim().length === 0) {
      data = { ...data, stateError: "State is required" };
    } else {
      let { stateError, ...others } = data;
      data = { ...others };
    }

    //manipulate address
    if (formData.address.trim().length === 0) {
      data = { ...data, addressError: "Address is required" };
    } else {
      let { addressError, ...others } = data;
      data = { ...others };
    }

    //manipulate email
    if (
      !formData.supportEmail
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    ) {
      data = { ...data, semailError: "Enter a valid email" };
    } else {
      let { semailError, ...others } = data;
      data = { ...others };
    }

    //manipulate support number
    // if (formData.supportNumber.trim().length === 0) {
    //   data = { ...data, spNumberError: "Support Number is required" };
    // } else {
    //   let { spNumberError, ...others } = data;
    //   data = { ...others };
    // }

    if (formData.siteTitle.trim().length === 0) {
      data = { ...data, stError: "Site title is required" };
    } else {
      let { stError, ...others } = data;
      data = { ...others };
    }

    setFormvalidationErrors({ ...data });
    return data;
  };
  const [triedBefore, setTriedBefore] = useState(false);
  const updateBasicInfo = () => {
    setTriedBefore(true);
    let errors = validateForm();
    if (!Object.keys(errors).length) {
      setInProgress(true);
      const body = {
        outletName: formData.siteTitle,
        opensAt: new Date(formData.regularTimingFrom),
        closesAt: new Date(formData.regularTimingTo),
        latitude: formData.latitude,
        longitude: formData.longitude,
        copyrightText: formData.copyright,
        assessmentFee: formData.assessmentFee,
        helpEmail: formData.supportEmail,
        helpContact: formData.supportNumber,
        country: formData.country,
        state: formData.state,
        address: formData.address,
        mainHeading: formData.mainHeading,
        headerLink: formData.headerLink,
        timezone: formData.timezone,
      };
      // console.log("Body is: ", JSON.stringify(body));
      url
        .patch(`/v1/general-settings/basic-info`, body)
        .then((res) => {
          if (res?.data?.Message === "Success") {
            updateLocalStorage(res.data?.data);
          }
          setInProgress(false);
        })
        .catch((e) => {
          setInProgress(false);
          if (+e?.response?.status === 401) {
            invokeLogout();
          }
        });
    }
  };
  useEffect(
    () => {
      if (triedBefore) {
        validateForm();
      }
    },
    //eslint-disable-next-line
    [formData, triedBefore]
  );

  // useEffect(() => {
  //   country !== "" ? setCountryAndGetState(country) : null;
  // }, []);

  //fetch previous data
  useEffect(() => {
    const adminID = JSON.parse(localStorage.getItem("Admin"));
    const outletChainID = adminID[0]?.outletChainID ?? null;
    // console.log("Outlet chain id is: ", outletChainID);
    url
      .get(`/v1/general-settings/basic-info?outletChainID=${outletChainID}`)
      .then((res) => {
        const response = res.data.data;
        setFormData({
          siteTitle: response?.outletName ?? "",
          supportNumber: response?.helpContact ?? "",
          supportEmail: response?.helpEmail ?? "",
          address: response?.address ?? "",
          state: response?.state ?? "",
          country: response?.country ?? "",
          longitude: response?.location?.coordinates[0] ?? 0,
          latitude: response?.location?.coordinates[1] ?? 0,
          copyright: response?.copyrightText ?? "",
          regularTimingFrom:
            response?.opensAt ?? new Date(new Date().setHours(8)),
          regularTimingTo:
            response?.closesAt ?? new Date(new Date().setHours(10)),
          assessmentFee: response?.assessmentFee ?? 0,
          mainHeading: response?.mainHeading ?? "",
          headerLink: response?.headerLink ?? "",
          timezone: response?.timezone ?? "US/Central",
        });
        setState(response?.state ?? "");
        setInProgress(false);
        /* eslint-disable */
        if (response?.country ?? "" !== "") {
          setCountryAndGetState(response?.country ?? "");
        }
      })
      .catch((e) => {
        setInProgress(false);
        console.error(e);
      });
  }, []);

  // console.log(formData.country);

  const history = useHistory();

  const handleChange = (name, value) => {
    setFormData({ ...formData, [name]: value });
  };

  /**
   *
   * @param {object} data basic info
   */
  const updateLocalStorage = async (data) => {
    try {
      // console.log("Here...");
      const prevData = await JSON.parse(localStorage.getItem("Admin"));
      if (Array.isArray(prevData)) {
        let { media } = prevData[0];
        prevData[0] = { ...data, media };
        // console.log("Prev data is: ", prevData);
        await localStorage.setItem("Admin", JSON.stringify(prevData));
        window.location.reload();
      }
    } catch (e) {}
  };
  if (resetPasswordWindowOpen) {
    return (
      <ResetPasswordComponent
        onCancel={() => setResetPasswordWindowOpen(false)}
      />
    );
  }
  return (
    <div className="newSpace">
      {inProgress ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <Fragment>
          <div className="row">
            <div className="col-md-6">
              <div>
                <div className="group-comb">
                  <label className="labelStyle">Site Title</label>

                  <input
                    type="text"
                    name="siteTitle"
                    value={formData.siteTitle}
                    onChange={handleChangeFromEvent}
                    className="form-control"
                    placeholder="Site Title"
                  />

                  {formValidationErrors.stError ? (
                    <span className="validation-help">
                      {formValidationErrors.stError}
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div>
                <div className="group-comb">
                  <label className="labelStyle">Suppport Number</label>
                  {/* <PhoneNumberInput 
                    value={phoneNumber}
                    setValue={setPhoneNumber}
                    placeholder="Support Number"
                    onKeyPress={(e) => {
                      var charCode =
                        typeof e.which == "undefined" ? e.keyCode : e.which;
                      var charStr = String.fromCharCode(charCode);

                      if (!charStr.match(/^[+.() 0-9]+$/)) e.preventDefault();
                    }}
                  /> */}
                  <input
                    type="text"
                    name="supportNumber"
                    value={formData.supportNumber}
                    onKeyPress={(e) => {
                      var charCode =
                        typeof e.which == "undefined" ? e.keyCode : e.which;
                      var charStr = String.fromCharCode(charCode);

                      if (!charStr.match(/^[+.() 0-9]+$/)) e.preventDefault();
                    }}
                    onChange={(e) =>
                      handleChange("supportNumber", e.target.value)
                    }
                    className="form-control"
                    placeholder="Support Number"
                  />
                  {formValidationErrors.spNumberError ? (
                    <span className="validation-help">
                      {formValidationErrors.spNumberError}
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="row" style={{ justifyContent: "space-between" }}>
            <div className="col-md-6">
              <div>
                <div className="group-comb">
                  <label className="labelStyle">Support Email</label>
                  <input
                    type="email"
                    name="supportEmail"
                    value={formData.supportEmail}
                    onChange={handleChangeFromEvent}
                    className="form-control"
                    placeholder="Support email"
                  />
                  {formValidationErrors.semailError ? (
                    <span className="validation-help">
                      {formValidationErrors.semailError}
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div>
                <div className="group-comb">
                  <label className="labelStyle">Address</label>
                  <input
                    type="text"
                    name="address"
                    value={formData.address}
                    onChange={handleChangeFromEvent}
                    className="form-control"
                    placeholder="Address"
                  />
                </div>
                {formValidationErrors.addressError ? (
                  <span className="validation-help">
                    {formValidationErrors.addressError}
                  </span>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>

          <div className="row" style={{ justifyContent: "space-between" }}>
            <div className="col-md-6">
              <div>
                <div className="group-comb">
                  <SelectPrimary
                    label="Country"
                    value={formData.country}
                    name="country"
                    onChange={(e) => {
                      // console.log(e.target.value, e.target.name);
                      setCountryAndGetState(e.target.value);
                      handleChangeFromEvent(e);
                    }}
                  >
                    {countries.map((ctr, i) => {
                      // console.log(ctr, 'ctr');
                      return (
                        <option key={i} value={`${ctr.name},${ctr.isoCode}`}>
                          {ctr.name}
                        </option>
                      );
                    })}
                  </SelectPrimary>

                  {/* <input type="text" value={country} onChange={(e) => setCountry(e.target.value)} className="form-control" id="exampleFormControlInput1" placeholder="Input Country" /> */}
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="group-comb">
                <SelectPrimary
                  label="State"
                  name="state"
                  onChange={(e) => {
                    // setStateAndGetCity(e.target.value);
                    handleChangeFromEvent(e);
                  }}
                  value={formData.state}
                >
                  {state !== "" && <option>{state !== "" ? state : ""}</option>}
                  {state === "" && (
                    <option>
                      {statesList.length === 0
                        ? "Select Country First"
                        : "Select State"}
                    </option>
                  )}
                  {statesList.map((str, i) => {
                    // console.log(str, 'ctr');
                    return (
                      <option
                        key={i}
                        value={`${str.name},${str.isoCode},${str.countryCode}`}
                      >
                        {str.name}
                      </option>
                    );
                  })}
                </SelectPrimary>
              </div>
            </div>
          </div>

          <div className="row" style={{ justifyContent: "space-between" }}>
            <div className="col-md-6">
              <div>
                <div className="group-comb">
                  <label className="labelStyle">Longitude</label>

                  <input
                    type="text"
                    name="longitude"
                    value={formData.longitude}
                    onChange={(e) =>
                      handleChange("longitude", e.target.value + "")
                    }
                    className="form-control"
                    placeholder="Longitude"
                  />
                  {formValidationErrors.longError ? (
                    <span className="validation-help">
                      {formValidationErrors.longError}
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div>
                <div className="group-comb">
                  <label className="labelStyle">Latitude</label>
                  <input
                    type="number"
                    value={formData.latitude}
                    name="latitude"
                    onChange={(e) =>
                      handleChange("latitude", e.target.value + "")
                    }
                    className="form-control"
                    placeholder="Latitude"
                  />
                </div>
                {formValidationErrors.latError ? (
                  <span className="validation-help">
                    {formValidationErrors.latError}
                  </span>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className="group-comb">
                <label className="labelStyle">Main Heading</label>
                <input
                  type="text"
                  value={formData.mainHeading}
                  name="mainHeading"
                  onChange={handleChangeFromEvent}
                  className="form-control"
                  placeholder="Heading Text"
                />
              </div>
            </div>

            <div className="col-md-6">
              <div className="group-comb">
                <label className="labelStyle">Header Link</label>
                <input
                  type="text"
                  value={formData.headerLink}
                  name="headerLink"
                  onChange={handleChangeFromEvent}
                  className="form-control"
                  placeholder="Header Link"
                />
              </div>
              {formValidationErrors.hlError ? (
                <span className="validation-help">
                  {formValidationErrors.hlError}
                </span>
              ) : (
                ""
              )}
            </div>
          </div>
          {/* time zone */}
          <div className="row" style={{ justifyContent: "space-between" }}>
            <div className="col-md-6">
              <div>
                <div className="group-comb">
                  <SelectPrimary
                    label="Time Zone"
                    value={formData.timezone}
                    name="timezone"
                    onChange={(e) => {
                      handleChangeFromEvent(e);
                    }}
                  >
                    {Object.keys(timeZoneData).map((key, i) => {
                      const obj = timeZoneData[key];
                      return (
                        <option key={i} value={obj.timezone}>
                          {obj.name}
                        </option>
                      );
                    })}
                  </SelectPrimary>
                </div>
              </div>
            </div>
          </div>
          <div>
            <hr style={{ color: "rgba(241, 245, 249, 1)" }} />
          </div>
          <div className="row hidden">
            <div className="col-md-12">
              <div className="group-comb">
                <label className="labelStyle">Copyright</label>
                <div className="form-group">
                  <input
                    type="text"
                    value={formData.copyright}
                    name="copyright"
                    onChange={handleChangeFromEvent}
                    className="form-control"
                    placeholder="Copyright"
                  />
                </div>
                {formValidationErrors.cpError ? (
                  <span className="validation-help">
                    {formValidationErrors.cpError}
                  </span>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>

          <div
            className="row"
            style={{
              alignItems: "flex-end",
              flexWrap: "wrap",
            }}
          >
            <div
              className="col-md-4"
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "0.5em",
              }}
            >
              <span className="labelStyle">Password</span>
              <div className="group-comb">
                <input
                  type="password"
                  disabled
                  value={"Password"}
                  className="form-control"
                  style={{ marginLeft: "0.5em" }}
                />
              </div>
            </div>
            <div
              className="col-md-8"
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "1.5em",
                marginLeft: "auto",
                justifyContent: "center",
              }}
            >
              {/* <span className="labelStyle" >
                Outlet Timings
              </span> */}
              <div className="row">
                <div className="col-md-6">
                  <div className="group-comb">
                    <label
                      style={{
                        color: "#747474",
                        fontSize: "14px",
                        lineHeight: "1rem",
                        fontWeight: "500",
                      }}
                    >
                      Outlet Timings From
                    </label>
                    <div
                      className="form-group"
                      style={{ marginTop: "0px", border: "1px solid #E2E8F0" }}
                    >
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <TimePicker
                          onChangeRaw={(e) => e.preventDefault()}
                          style={{ width: "100%" }}
                          value={formData.regularTimingFrom}
                          onChange={(value) => {
                            handleChange("regularTimingFrom", value);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              value={formData.regularTimingFrom}
                              name="regularTimingFrom"
                              onChange={(e) => {
                                let value =
                                  moment(new Date()).format("ddd DD-MMM-YYYY") +
                                  "," +
                                  e.target.value.toUpperCase();
                                // console.log(value);
                                handleChange(
                                  "regularTimingFrom",
                                  new Date(value)
                                );
                              }}
                              // error={!!formValidationErrors.rtfError}
                              // helperText={formValidationErrors.rtfError}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                    <span className="validation-help">
                      {formValidationErrors.rtfError
                        ? formValidationErrors.rtfError
                        : ""}
                    </span>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="group-comb">
                    <label
                      style={{
                        color: "#747474",
                        fontSize: "14px",
                        lineHeight: "1rem",
                        fontWeight: "500",
                      }}
                    >
                      To
                    </label>

                    <div
                      className="form-group"
                      style={{ marginTop: "0px", border: "1px solid #E2E8F0" }}
                    >
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <TimePicker
                          onChangeRaw={(e) => e.preventDefault()}
                          style={{ width: "100%" }}
                          value={formData.regularTimingTo}
                          onChange={(value) => {
                            handleChange("regularTimingTo", value);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              value={formData.regularTimingTo}
                              name="regularTimingTo"
                              onChange={(e) => {
                                let value =
                                  moment(new Date()).format("ddd DD-MMM-YYYY") +
                                  "," +
                                  e.target.value.toUpperCase();
                                // console.log(value);
                                handleChange(
                                  "regularTimingTo",
                                  new Date(value)
                                );
                              }}
                              // error={!!formValidationErrors.rttError}
                              // helperText={formValidationErrors.rttError}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                    <span className="validation-help">
                      {formValidationErrors.rttError
                        ? formValidationErrors.rttError
                        : ""}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex row">
            <div
              className="col-md-4"
              style={{
                display: "flex",
                flexDirection: "row-reverse",
              }}
            >
              <span
                onClick={() => setResetPasswordWindowOpen(true)}
                style={{
                  fontWeight: "bold",
                  textDecoration: "underline",
                  color: "#FFA382",
                  marginTop: "0.1em",
                  cursor: "pointer",
                }}
              >
                Reset Password
              </span>
            </div>
          </div>

          <div
            className="row"
            style={{
              justifyContent: "end",
              alignItems: "center",
              gap: "2em",
              marginTop: "3em",
            }}
          >
            <button
              onClick={updateBasicInfo}
              className="btn btn-cannaby"
              style={{
                margin: "0",
                padding: "0.5em 3em",
                borderRadius: "5px",
                // fontWeight: "bold",
                // color: "white",
                backgroundColor: "#FFA382",
                border: "none",
                cursor: "pointer",
              }}
            >
              Update
            </button>
            {/* <button
              onClick={() => history.goBack()}
              className="btn btn-rnav"
              style={{
                padding: "0.5em 3em",
                // border: "1px solid blue",
                borderRadius: "5px",
                // fontWeight: "bold",
                cursor: "pointer",
              }}>
              Cancel
            </button> */}
          </div>
        </Fragment>
      )}
    </div>
  );
}

export default BasicInfoPage;
