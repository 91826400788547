import React from "react";
import Addbannerpopup from "./BannerPopup/AddBannerpopup";
import Editbannerpopup from "./BannerPopup/EditBannerpopup";
import url from "../../config/axios";
import { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Success from "../../assets/Success1.png";
import Failure from "../../assets/failure.png";
import Popup from "reactjs-popup";
import "./banner.css";
import { serializeQueryStringsFromObject } from "../../helpers/serializeToQueryStrings";
import Pagination from "@mui/material/Pagination";
const useStyles = makeStyles({
  root: {
    "& .Mui-selected": {
      backgroundColor: "#FFA382",
    },
    "& .MuiPaginationItem-root": {
      borderRadius: "5px !important",
    },
  },
});
export default function Index() {
  const classes = useStyles();

  const history = useHistory();
  const [banners, setBanners] = useState([]);
  const [totalPage, setTotalPage] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [outlets, setOutlets] = useState([]);
  const [bannerId, setBannerId] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [unfiltered, setUnfiltered] = useState([]);
  const [outletID, setOutletID] = useState("");

  var perPage = 10;
  const initialPaginationData = {
    page: 1,
    limit: 30,
    latestFirst: 1,
    search: "",
  };
  const [queryData, setQueryData] = useState({
    ...initialPaginationData,
  });
  const [paginationData, setPaginationData] = useState({
    currentPage: 1,
    perPage: 30,
    totalPages: 0,
    total: 0,
  });
  const [currentEditingIndex, setCurrentEditingIndex] = useState(null);
  const [addBannerOpen, setAddBannerOpen] = useState(false);
  const [editBannerOpen, setEditBannerOpen] = useState(false);
  // const [success, setSuccess] = useState(false);
  const newRef = useRef();
  const openNewTooltip = () => newRef.current.open();
  const closeNewTooltip = () => {
    newRef.current.close();
  };
  const updtRef = useRef();
  const openUpdtTooltip = () => updtRef.current.open();
  const closeUpdtTooltip = () => {
    updtRef.current.close();
  };
  const admin = JSON.parse(localStorage.getItem("Admin"));
  const [message, setMessage] = useState({
    display: false,
    status: false,
    text: "",
  });
  const [Updtmessage, setUpdtMessage] = useState({
    display: false,
    status: false,
    text: "",
  });
  const ref = useRef();
  const openTooltip = () => ref.current.open();
  const closeTooltip = () => ref.current.close();
  const notFoundRef = useRef();
  const openNotFoundPopUp = () => notFoundRef.current.open();
  const closeNotFoundPopUp = () => {
    notFoundRef.current.close();
    setSearchTerm("");
  };
  console.log(admin[0].isSubOutlet, "??");
  // adding tool tip ref
  useEffect(() => {
    if (message.text !== "") {
      openNewTooltip();
    }
  }, [message]);
  useEffect(() => {
    if (Updtmessage.text !== "") {
      openUpdtTooltip();
    }
  }, [Updtmessage]);
  useEffect(() => {
    if (admin[0].isSubOutlet) {
      GetAllBanners();
    } else {
      GetAllOutlets();
    }
  }, [editBannerOpen, addBannerOpen]);

  //search
  useEffect(() => {
    if (searchTerm.trim().length !== 0) {
      let filtered = unfiltered.filter((el) =>
        (el.title + "").toLowerCase().includes(searchTerm.toLowerCase())
      );
      setBanners(filtered);
      setTotalPage(Math.ceil(filtered.length / perPage));
      setCurrentPage(1);
      if (!filtered.length) {
        openNotFoundPopUp();
      }
    } else {
      setBanners(unfiltered);
      setTotalPage(Math.ceil(unfiltered.length / perPage));
      setCurrentPage(1);
    }
  }, [searchTerm]);
  const handleChange = (propName, propValue, flush = false) => {
    if (currentEditingIndex === null) {
      let query = {};
      if (flush) {
        query = {
          ...initialPaginationData,
          [propName]: propValue,
        };
      } else {
        query = { ...queryData, [propName]: propValue };
      }
      const queryString = serializeQueryStringsFromObject(query);
      history.push(`/banner${queryString}`);
      setQueryData({ ...query });
    }
  };
  const GetAllBanners = () => {
    let queryParams = { ...queryData };
    //sanity
    Object.keys(queryParams).forEach((key) => {
      if (!queryParams[key]) {
        delete queryParams[key];
      }
    });
    url
      .get(`/v1/banners`, {
        params: {
          ...queryParams,
        },
      })
      .then(async (res) => {
        console.log("respO", res);
        if (res.data.Message === "Success") {
          if (res.data.data.banners.length === 0) {
            openNotFoundPopUp();
          }
          setUnfiltered(res.data.data);
          setBanners(res.data.data.banners);
          setTotalPage(Math.ceil(res.data.data.length / perPage));
          // setminPro(page * 5 - 5 + 1);
          setPaginationData({
            ...paginationData,
            ...res?.data?.data?.paginationData,
          });
        } else {
          openNotFoundPopUp();
        }
      })
      .catch((e) => openNotFoundPopUp());
  };

  const deleteBanner = (bannerId) => {
    url
      .delete(`/banner/?bannerId=${bannerId}`)
      .then(async (res) => {
        console.log("res", res);
        if (res.data.Message === "Success") {
          GetAllBanners();
          closeTooltip();
        } else {
          console.log(res.data.data);
        }
      })
      .catch((e) => console.log(e));
  };

  const GetBannersByOutlet = (id) => {
    setOutletID(id);
    url
      .get(`/banner/?outletChainID=${id}&isAdmin=1`)
      .then(async (res) => {
        console.log("resi", res);
        if (res.data.Message === "Success") {
          if (res.data.data.length === 0) {
            // alert("No banner found");
            setBanners([]);
            openNotFoundPopUp();
          } else {
            setUnfiltered(res.data.data);
            setBanners(res.data.data);
            setTotalPage(Math.ceil(res.data.data.length / perPage));
          }
        } else {
          alert(res.data.data);
        }
      })
      .catch((e) => console.log(e));
  };

  const GetAllOutlets = () => {
    url
      .get(`/oc/?consumerId=${admin[0].consumerId}`)
      .then(async (res) => {
        // console.log('res', res)
        if (res.data.Message === "Success") {
          setOutlets(res.data.data);
        } else {
          alert(res.data.data);
        }
      })
      .catch((e) => console.log(e));
  };

  const editBanner = (data) => {
    localStorage.setItem("editBanner", JSON.stringify(data));
    setEditBannerOpen(!editBannerOpen);
  };

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="card ">
          <div className="row wtBGBR">
            <div className="col-md-12">
              <div className="row">
                <div
                  style={{
                    height: "25px",
                    marginLeft: "-32px",
                    backgroundColor: "#FFA382",
                    marginTop: "6px",
                  }}
                >
                  <span style={{ color: "#FFA382" }}>.</span>
                </div>
                <div className="col-md-3 d-flex">
                  <div
                    style={{
                      color: "#FFA382",
                      fontSize: "20px",
                      fontWeight: "500",
                    }}
                  >
                    Banners
                  </div>
                </div>
                <div className="col-md-1 filters-bar">
                  <div className="dropdown"></div>
                  <div className="dropdown">
                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuLink"
                    >
                      <a className="dropdown-item" href="/">
                        Action
                      </a>
                      <a className="dropdown-item" href="/">
                        Another action
                      </a>
                      <a className="dropdown-item" href="/">
                        Something else here
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="input-group">
                    <input
                      type="text"
                      style={{ borderRight: "none" }}
                      className="form-control newInput"
                      placeholder="Search..."
                      aria-label="Input group example"
                      aria-describedby="btnGroupAddon"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <div className="input-group-prepend">
                      <div
                        className="input-group-text newSearchIcon"
                        id="btnGroupAddon"
                      >
                        <i className="fas fa-search"></i>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-3">
                  {!admin[0].isSubOutlet && (
                    <div className="input-group">
                      <select
                        className=" newInputSelect"
                        onChange={(e) => GetBannersByOutlet(e.target.value)}
                        id="exampleFormControlSelect1"
                      >
                        <option value="">Select Outlet</option>
                        {outlets.map((outlet, i) => {
                          return (
                            <option value={outlet.outletChainID} key={i}>
                              {outlet.outletName}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  )}
                  {outletID === "" && !admin[0].isSubOutlet && (
                    <p className="pl-0" style={{ color: "red" }}>
                      Please select outlet
                    </p>
                  )}
                </div>
                <div className="col-md-2">
                  <button
                    className="btn btn-primary border-0"
                    style={{ backgroundColor: "#FFA382" }}
                    onClick={() => {
                      setAddBannerOpen(!addBannerOpen);
                      // history.push("/add-banner");
                    }}
                  >
                    <i className="fas fa-plus-circle"></i>
                    Add Banner
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <table className="table table-striped-cust">
                <thead>
                  <tr>
                    <th scope="col">Banner Image</th>

                    <th scope="col"></th>
                    <th scope="col"></th>
                    {/* <th scope="col">Link</th> */}
                    <th scope="col">Title</th>
                    <th scope="col" style={{ textAlign: "center" }}>
                      Status
                    </th>
                    <th scope="col"></th>
                    <th scope="col" style={{ textAlign: "center" }}>
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {banners && banners?.length === 0 ? (
                    <>
                      <tr>
                        <td colSpan="12" style={{ textAlign: "center" }}>
                          No Banners found
                        </td>
                      </tr>
                    </>
                  ) : (
                    banners
                      .slice((currentPage - 1) * perPage, currentPage * perPage)
                      .map((banner, i) => {
                        return (
                          <tr key={i}>
                            <td colSpan="3">
                              {!!banner.link ? (
                                <a href={`${banner.link}`} target="blank">
                                  <img
                                    src={banner.image}
                                    style={{ width: "120px", height: "90px" }}
                                    alt="banner"
                                  />
                                </a>
                              ) : (
                                <img
                                  src={banner.image}
                                  style={{ width: "120px", height: "50px" }}
                                  alt="banner"
                                />
                              )}
                            </td>
                            {/* <td>
                                {!!banner.link
                                  ? `${(banner.link + "").substring(0, 41)}${(banner.link + "").length > 40 ? "..." : ""
                                  }`
                                  : "none"}
                              </td> */}
                            <td style={{ color: "#1E293B" }}>
                              {`${(banner.title + "").substring(0, 41)}${
                                (banner.title + "").length > 40 ? "..." : ""
                              }`}
                              <br />
                            </td>
                            <td
                              className={
                                banner.status ? "color-blue" : "color-red"
                              }
                            >
                              <span>
                                {banner.status ? (
                                  <div className="newClass" id="deals">
                                    <div id="enablebutton">
                                      <p
                                        style={{
                                          fontWeight: "400",
                                          textAlign: "center",
                                          fontSize: "13px",
                                          paddingTop: "4px",
                                        }}
                                      >
                                        <span style={{ color: "#0CD600" }}>
                                          Enabled
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                ) : (
                                  <div className="newClass" id="deals">
                                    <div id="disablebutton">
                                      {" "}
                                      <p
                                        style={{
                                          fontWeight: "400",
                                          textAlign: "center",
                                          fontSize: "13px",
                                          paddingTop: "4px",
                                        }}
                                      >
                                        <span style={{ color: "red" }}>
                                          Disabled
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                )}{" "}
                              </span>
                            </td>
                            <td></td>

                            <td>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  gap: "2em",
                                  flexWrap: "wrap",
                                  justifyContent: "center",
                                }}
                              >
                                <i
                                  className="fa fa-pen"
                                  style={{ color: "#FFA382" }}
                                  onClick={() => {
                                    editBanner(banner);
                                  }}
                                ></i>
                                <i
                                  className="fa fa-trash-alt"
                                  style={{
                                    color: "red",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    setBannerId(banner.bannerId);
                                    openTooltip();
                                  }}
                                ></i>
                              </div>
                            </td>
                          </tr>
                        );
                      })
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div
          className="d-flex col-md-12"
          style={{
            justifyContent: "flex-end",
            marginTop: "5px",
            marginLeft: "13px",
          }}
        >
          <div
            style={{
              backgroundColor: "white",
              borderRadius: "5px",
              padding: "6px",
            }}
          >
            <Pagination
              shape="square"
              className={classes.root}
              onChange={(e, value) => {
                handleChange("page", value);
              }}
              page={paginationData.currentPage}
              count={paginationData.totalPages ?? 0}
            />
          </div>
        </div>
      </div>
      <Popup ref={ref} position="center">
        <div style={{ textAlign: "center" }}>
          <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
          <p className="popup-heading">Do you want to delete this banner ?</p>
          <button
            className="btn btn-cannaby"
            onClick={() => {
              deleteBanner(bannerId);
            }}
          >
            Yes
          </button>
          <button
            style={{ marginLeft: "20px", marginTop: "30px" }}
            type="button"
            className="btn popup-cancel-button"
            onClick={closeTooltip}
          >
            No
          </button>
        </div>
      </Popup>
      <Popup ref={notFoundRef} position="center">
        <div style={{ textAlign: "center" }}>
          <i
            style={{
              color: "red",
              fontSize: "70px",
            }}
            // style={{
            //   color: "green",
            //   fontSize: "60px",
            //   border: "10px solid green",
            //   borderRadius: "50px",
            //   padding: "10px",
            // }}
            class="fa fa-ban"
          ></i>
          <h2 className="popup-heading" style={{ marginTop: "20px" }}>
            Failed
          </h2>
          <p>Banners not found</p>
          <button
            className="btn btn-cannaby"
            style={{ marginTop: "0" }}
            onClick={closeNotFoundPopUp}
          >
            OK
          </button>
        </div>
      </Popup>

      <Popup ref={newRef} position="center">
        {message.text === "Success" ? (
          <div style={{ textAlign: "center" }}>
            {" "}
            <div>
              <img height="60" alt="" src={Success} />
            </div>
            <p className="popup-heading" style={{ marginTop: "20px" }}>
              Created successfully <br />
              <span
                style={{
                  color: "#747474",
                  fontSize: "10px",
                  marginTop: "-10px !important",
                }}
              >
                Banner is created{" "}
              </span>
            </p>
            <button
              className="btn btn-cannaby"
              style={{
                backgroundColor: "#FFA382",
                marginTop: "0",
                width: "20vh",
                alignSelf: "center",
              }}
              onClick={() => {
                closeNewTooltip();
              }}
            >
              OK
            </button>
          </div>
        ) : (
          <div style={{ textAlign: "center" }}>
            <div>
              <img alt="" src={Failure} />
            </div>
            <p style={{ marginTop: "20px" }}>Something Went Wrong</p>
            <button
              type="button"
              onClick={() => {
                closeNewTooltip();
              }}
              className="btn btn-primary border-0"
              style={{
                backgroundColor: "#FFA382",
                marginTop: "0",
                width: "20vh",
                alignSelf: "center",
              }}
            >
              ok
            </button>
          </div>
        )}
      </Popup>
      <Popup ref={updtRef} position="center">
        {Updtmessage.text === "Success" ? (
          <div style={{ textAlign: "center" }}>
            {" "}
            <div>
              <img height="60" alt="" src={Success} />
            </div>
            <p className="popup-heading" style={{ marginTop: "20px" }}>
              Updated successfully <br />
              <span
                style={{
                  color: "#747474",
                  fontSize: "10px",
                  marginTop: "-10px !important",
                }}
              >
                Banner is updated{" "}
              </span>
            </p>
            <button
              className="btn btn-cannaby"
              style={{
                backgroundColor: "#FFA382",
                marginTop: "0",
                width: "20vh",
                alignSelf: "center",
              }}
              onClick={() => {
                closeUpdtTooltip();
              }}
            >
              OK
            </button>
          </div>
        ) : (
          <div style={{ textAlign: "center" }}>
            <div>
              <img alt="" src={Failure} />
            </div>
            <p style={{ marginTop: "20px" }}>Something Went Wrong</p>
            <button
              type="button"
              onClick={() => {
                closeNewTooltip();
              }}
              className="btn btn-primary border-0"
              style={{
                backgroundColor: "#FFA382",
                marginTop: "0",
                width: "20vh",
                alignSelf: "center",
              }}
            >
              ok
            </button>
          </div>
        )}
      </Popup>
      {addBannerOpen && (
        <Addbannerpopup
          onClose={setAddBannerOpen}
          response={setMessage}
          outletIdCat={!admin[0].isSubOutlet ? outletID : null}
        />
      )}
      {editBannerOpen && (
        <Editbannerpopup
          onClose={setEditBannerOpen}
          response={setUpdtMessage}
          outletIdCat={!admin[0].isSubOutlet ? outletID : null}
        />
      )}
    </div>
  );
}
