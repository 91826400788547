import { Cached, Telegram } from "@mui/icons-material";
import { Box, IconButton, Paper } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCurrentSendBoxContent,
  selectSendBoxMeta,
  setMessageAction,
  sendMessageThunk,
} from "../../redux/slices/sendBoxSlice";
import { selectCurrentSessionInfo } from "../../redux/slices/chatSessionSlice";
const SendMessageComponent = () => {
  const { _id: sessionID, isActive } = useSelector(selectCurrentSessionInfo);
  const content = useSelector(selectCurrentSendBoxContent(sessionID)) ?? "";
  const dispatch = useDispatch();
  //to do: error handling
  const { loading } = useSelector(selectSendBoxMeta);

  return (
    <div
      style={{
        display: "flex",
        height: "auto",
        padding: "0.5em",
      }}>
      <Box
        component={Paper}
        elevation={5}
        sx={{
          width: "100%",
          padding: "0.5em",
          display: "flex",
          flexDirection: "row-reverse",
          overflow: "hidden",
        }}
        borderRadius={10}>
        <IconButton
          color="primary"
          onClick={() => dispatch(sendMessageThunk())}
          disabled={(content + "").trim().length === 0 || loading}>
          {!loading ? <Telegram sx={{ color: "#ffa382" }} /> : <Cached />}
        </IconButton>
        <input
          placeholder="Reply..."
          disabled={loading || !isActive}
          style={{
            outline: "none",
            flexGrow: 1,
            border: "none",
          }}
          onKeyUp={(e) => {
            if (e.key === "Enter") {
              dispatch(sendMessageThunk());
            }
          }}
          value={content ?? ""}
          onChange={(e) => {
            dispatch(
              setMessageAction({
                sessionID,
                content: e.target.value + "",
              })
            );
          }}></input>
      </Box>
    </div>
  );
};

export default SendMessageComponent;
